import { CustomTitleCasePipe } from './pipes/custom-titlecase.pipe';
import { ReleaseNotesService } from './services/release-notes-service';
import { ComparisonToolService } from './services/comparison-tool.service';
import { KPITooltipComponent } from './components/kpi-tooltip/kpi-tooltip.component';
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UniqueColumnValueDirective } from '../core/directives/unique-column-value.directive';
import { AddRowOnTabDirective } from './directives/add-row-on-tab.directive';
import { FocusFirstCellDirective } from './directives/focus-first-cell.directive';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { PerformanceIndicatorComponent } from './components/performance-indicator/performance-indicator.component';
import { FocusNextInputDirective } from './directives/focus-next-input.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressRingComponent } from './components/progress-ring/progress-ring.component';
import {
  DxChartModule, DxDropDownBoxModule, DxDataGridModule, DxValidatorModule,
  DxCheckBoxModule, DxTooltipModule, DxTextBoxModule, DxDateBoxModule, DxPopupModule, DxColorBoxModule, DxTagBoxModule
} from 'devextreme-angular';
import { GraphComponent } from './components/graph/graph.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { TabsComponent } from './components/tabs/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { ShortNumberFormatterPipe } from './pipes/shortNumberFormatter.pipe';
import { DropdownBoxComponent } from './components/drop-down-box/drop-down-box.component';
import { PeriodSelectorService } from './services/period-selector.service';
import { PerformanceIndicatorArrowComponent } from './components/performance-indicator-arrow/performance-indicator-arrow.component';
import { UserSettingsService } from '../user-settings/services/user-settings.service';
import { NavigateToFirstPageOnSortDirective } from './directives/navigate-to-first-page-on-sort.directive';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { FilterInputDirective } from './directives/filter-input.directive';
import { PermissionService } from './services/permission.service';
import { EntityGuard } from './services/entity-guard.service';
import { NotOnTargetMarkComponent } from './components/not-on-target-mark/not-on-target-mark.component';
import { ValueInputComponent } from './components/value-input/value-input.component';
import { GPSInputComponent } from './components/gps-input/gps-input.component';
import { DecimalFormattingPipe } from './pipes/decimal-formatting.pipe';
import { IconTooltipComponent } from './components/icon-tooltip/icon-tooltip.component';
import { AuditEventService } from './services/auditEvent.service';
import { NumericTextBoxComponent } from './components/numeric-text-box/numeric-text-box.component';
import { RouterModule } from '@angular/router';
import { OrganizationService } from './services/organization.service';
import { OrganizationLicenseService } from './services/organization-license.service';
import { OrganizationLicenseGuard } from './services/organization-license-guard.service';
import { NewPeriodSelectorComponent } from './components/new-period-selector/new-period-selector.component';
import { CropCycleService } from './services/crop-cycle.service';
import { PagerComponent } from './components/pager/pager.component';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { EntityService } from './services/entity.service';
import { ScatterPlotGraphComponent } from './components/scatter-plot-graph/scatter-plot-graph.component';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { AlertEventsService } from './services/alert-events.service';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { SwitchComponent } from './components/switch/switch.component';
import { GridPageSelectorComponent } from './components/grid-page-selector/grid-page-selector.component';
import { LandingPageService } from '../shell/services/landing-page.service';
import { HotTableModule } from '@handsontable/angular';
import { ExcelInputComponent } from './components/excel-input/excel-input.component';
import { SiteService } from './services/site.service';
import { ExcelService } from './services/excel.service';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { DividerComponent } from './components/divider/divider.component';
import { CropCycleHeaderComponent } from './components/crop-cycle-header/crop-cycle-header.component';
import { ItemHeaderComponent } from './components/item-header/item-header.component';
import { VerticalMenuItemComponent } from './components/vertical-menu-item/vertical-menu-item.component';
import { TasksService } from '../tasks/services/tasks.service';
import { CultivarsService } from './services/cultivars.service';
import { HideGridFilterPopupOnScrollingDirective } from './directives/hide-grid-filter-popup-on-scrolling.directive';
import { CropCyclesInformationHeaderComponent } from './components/crop-cycles-information-header/crop-cycles-information-header.component';
import { ComparisonGraphComponent } from './components/comparison-graph/comparison-graph.component';
import { ComparisonLabelHelper } from './helpers/comparison-label.helper';
import { ComparisonPeriodHelper } from './helpers/comparison-period.helper';
import { GraphStatisticsComponent } from './components/graph-statistics/graph-statistics.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { TagBoxComponent } from './components/tag-box/tag-box.component';
import { ScatterPlotStatisticsComponent } from './components/scatter-plot-graph/scatter-plot-statistics/scatter-plot-statistics.component';
import { ComparisonPeriodFormatterHelper } from './helpers/comparison-period-formatter.helper';

@NgModule({
  declarations: [
    LoadingIndicatorComponent,
    UniqueColumnValueDirective,
    AddRowOnTabDirective,
    FocusFirstCellDirective,
    FocusNextInputDirective,
    NavigateToFirstPageOnSortDirective,
    FilterInputDirective,
    DropdownBoxComponent,
    PerformanceIndicatorComponent,
    PerformanceIndicatorArrowComponent,
    SearchBoxComponent,
    SwitchComponent,
    TabsComponent,
    TabComponent,
    ProgressRingComponent,
    GraphComponent,
    NotificationsListComponent,
    ShortNumberFormatterPipe,
    NotOnTargetMarkComponent,
    ValueInputComponent,
    GPSInputComponent,
    DecimalFormattingPipe,
    IconTooltipComponent,
    KPITooltipComponent,
    NumericTextBoxComponent,
    NewPeriodSelectorComponent,
    PagerComponent,
    ArrayFilterPipe,
    ScatterPlotGraphComponent,
    ScatterPlotStatisticsComponent,
    MomentDatePipe,
    CustomTitleCasePipe,
    GridPageSelectorComponent,
    ExcelInputComponent,
    SiteHeaderComponent,
    DividerComponent,
    CropCycleHeaderComponent,
    ItemHeaderComponent,
    VerticalMenuItemComponent,
    HideGridFilterPopupOnScrollingDirective,
    CropCyclesInformationHeaderComponent,
    ComparisonGraphComponent,
    GraphStatisticsComponent,
    CheckBoxComponent,
    TagBoxComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DxChartModule,
    DxDropDownBoxModule,
    DxDataGridModule,
    DxValidatorModule,
    DxCheckBoxModule,
    DxTooltipModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxPopupModule,
    DxColorBoxModule,
    DxTagBoxModule,
    HotTableModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    LoadingIndicatorComponent,
    UniqueColumnValueDirective,
    AddRowOnTabDirective,
    FocusFirstCellDirective,
    FocusNextInputDirective,
    FilterInputDirective,
    NavigateToFirstPageOnSortDirective,
    DropdownBoxComponent,
    PerformanceIndicatorComponent,
    PerformanceIndicatorArrowComponent,
    SearchBoxComponent,
    SwitchComponent,
    TabsComponent,
    TabComponent,
    ProgressRingComponent,
    GraphComponent,
    TranslatePipe,
    NotificationsListComponent,
    TranslateModule,
    NotOnTargetMarkComponent,
    ValueInputComponent,
    GPSInputComponent,
    DecimalFormattingPipe,
    IconTooltipComponent,
    KPITooltipComponent,
    NumericTextBoxComponent,
    NewPeriodSelectorComponent,
    PagerComponent,
    ArrayFilterPipe,
    ScatterPlotGraphComponent,
    ScatterPlotStatisticsComponent,
    MomentDatePipe,
    CustomTitleCasePipe,
    GridPageSelectorComponent,
    ExcelInputComponent,
    SiteHeaderComponent,
    DividerComponent,
    CropCycleHeaderComponent,
    ItemHeaderComponent,
    VerticalMenuItemComponent,
    HideGridFilterPopupOnScrollingDirective,
    CropCyclesInformationHeaderComponent,
    ComparisonGraphComponent,
    GraphStatisticsComponent,
    CheckBoxComponent,
    TagBoxComponent
  ],
  providers: [
    PeriodSelectorService,
    DatePipe,
    UserSettingsService,
    PermissionService,
    EntityGuard,
    DecimalFormattingPipe,
    AuditEventService,
    OrganizationService,
    OrganizationLicenseService,
    OrganizationLicenseGuard,
    CropCycleService,
    EntityService,
    CanDeactivateGuard,
    AlertEventsService,
    LandingPageService,
    SiteService,
    ComparisonToolService,
    ExcelService,
    TasksService,
    CultivarsService,
    ReleaseNotesService,
    ComparisonPeriodHelper,
    ComparisonLabelHelper,
    ComparisonPeriodFormatterHelper],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule
    };
  }
}
