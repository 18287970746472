import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { EntitiesComparisonGraphsQueryApiModel } from '../models/entitiesComparisonGraphsQueryApiModel';
import { ComparisonResourceApiModel } from '../models/comparisonResourceApiModel';
import { RelativeCropCyclesComparisonGraphsQueryApiModel } from '../models/relativeCropCyclesComparisonGraphsQueryApiModel';
import { CropCycleApiModel } from '../models/cropCycles/cropCycleApiModel';
import { ZoneApiModel } from '../models/zoneApiModel';
import { Site } from '../models/site';
import { EntityComparisonResultApiModel } from '../models/entityComparisonResultApiModel';

@Injectable()
export class ComparisonToolService {
  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  getAbsoluteComparisonGraphs(query: EntitiesComparisonGraphsQueryApiModel): Observable<EntityComparisonResultApiModel> {
    const url = `${this.configuration.comparisonToolApiBaseUrl}/comparisonTool/GetAbsoluteComparisonGraphs`;
    return this.httpClient.post<EntityComparisonResultApiModel>(url, query);
  }

  getRelativeCropCyclesComparisonGraphs(query: RelativeCropCyclesComparisonGraphsQueryApiModel): Observable<EntityComparisonResultApiModel>  {
      const url = `${this.configuration.comparisonToolApiBaseUrl}/comparisonTool/GetRelativeCropCyclesComparisonGraphs`;
      return this.httpClient.post<EntityComparisonResultApiModel>(url, query);
  }

  getComparisonToolSiteResources(organizationId: number): Observable<ComparisonResourceApiModel[]> {
    const url = `${this.configuration.comparisonToolApiBaseUrl}/comparisonTool/GetComparisonToolSiteResources/${organizationId}`;
    return this.httpClient.get<ComparisonResourceApiModel[]>(url);
  }

  getUserOrganizationCropCycles(organizationId: number): Observable<CropCycleApiModel[]> {
    const url = `${this.configuration.comparisonToolApiBaseUrl}/comparisonTool/GetUserOrganizationCropCycles/${organizationId}`;
    return this.httpClient.get<CropCycleApiModel[]>(url);
  }

  getUserOrganizationZones(organizationId: number): Observable<ZoneApiModel[]> {
    const url = `${this.configuration.comparisonToolApiBaseUrl}/comparisonTool/GetUserOrganizationZones/${organizationId}`;
    return this.httpClient.get<ZoneApiModel[]>(url);
  }

  getUserOrganizationSites(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.comparisonToolApiBaseUrl}/comparisonTool/GetUserOrganizationSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }
}

