import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { Observable } from 'rxjs';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { AlertEventApiModel } from '../../alerts/models/alertEventApiModel';

@Injectable({
    providedIn: 'root'
})
export class AlertEventsService {
    private readonly configuration: ClientConfiguration;

    constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
        this.configuration = configurationService.configuration;
    }

    getAlertEventsByOrganizationId(organizationId: number): Observable<AlertEventApiModel[]> {
        const url = `${this.configuration.organizationsApiBaseUrl}/AlertEvents/GetAlertEvents/${organizationId}`;
        return this.httpClient.get<AlertEventApiModel[]>(url);
    }

    updateAlertEvents(alertEvents: AlertEventApiModel[], organizationId: number) {
        const url = `${this.configuration.organizationsApiBaseUrl}/AlertEvents/UpdateAlertEvents/${organizationId}`;
        return this.httpClient.put(url, alertEvents);
    }

    getOrganizationPendingAlertEventsCounter(organizationId: number): Observable<number> {
        const url = `${this.configuration.organizationsApiBaseUrl}/AlertEvents/GetOrganizationPendingAlertEventsCounter/${organizationId}`;
        return this.httpClient.get<number>(url);
    }
}