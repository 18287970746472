import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { UserProfileSettingsApiModel } from '../models/userProfileSettingsApiModel';
import { EnumApiModel } from 'src/app/core/models/enumApiModel';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { UserOnboardingSettingsApiModel } from '../models/userOnboardingSettingsApiModel';

@Injectable()
export class UserSettingsService {
  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  getCurrencies(): Observable<EnumApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/currencies`;
    return this.httpClient.get<EnumApiModel[]>(url);
  }

  getUnitSystems(): Observable<EnumApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/unitSystems`;
    return this.httpClient.get<EnumApiModel[]>(url);
  }

  getDateFormats(): Observable<EnumApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/dateFormats`;
    return this.httpClient.get<EnumApiModel[]>(url);
  }

  getSettings(): Observable<UserProfileSettingsApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings`;
    return this.httpClient.get<UserProfileSettingsApiModel>(url);
  }

  getOnboardingSettings(): Observable<UserOnboardingSettingsApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/getUserOnboardingSettings`;
    return this.httpClient.get<UserOnboardingSettingsApiModel>(url);
  }

  getUserLanguage(): Observable<object> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/getUserLanguage`;
    return this.httpClient.get<object>(url);
  }

  getUserAreaUnit(): Observable<object> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/getUserAreaUnit`;
    return this.httpClient.get<object>(url);
  }

  saveSettings(settings: UserProfileSettingsApiModel): Observable<UserProfileSettingsApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings`;
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post<UserProfileSettingsApiModel>(url, settings, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  saveOnboardingSettings(settings: UserOnboardingSettingsApiModel): Observable<UserOnboardingSettingsApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/updateUserOnboardingSettings`;
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post<UserOnboardingSettingsApiModel>(url, settings, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
}
