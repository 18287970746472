import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PlatformAdministrationService } from '../../platform-administration/services/platform-administration.service'
import { map } from 'rxjs/operators';

@Injectable()
export class PlatformAdminAuthGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly platformAdministrationService: PlatformAdministrationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.platformAdministrationService.isUserPlatformAdministrator()
      .pipe(
        map(isAdministrator => {
          if (!isAdministrator) {
            sessionStorage.setItem('navigationFailed','platform');
            this.router.navigate(['organizations']);
            return false;
          }

          return true;

        }));
  }
}
