import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    logError(message: string | any) {
        if(message instanceof String){
            console.log(`Error: ${message}`);
        }
        else {
            console.log(`Error`, message);
        }
    }
}
