import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClientConfiguration } from "src/app/configuration/models/client-configuration.model";
import { ConfigurationService } from "src/app/configuration/services/configuration.service";
import { ReleaseNotesInfoApiModel } from "../models/releaseNotesInfoApiModel";

@Injectable()
export class ReleaseNotesService {
  private readonly configuration: ClientConfiguration;

  constructor(
    private readonly httpClient: HttpClient,
    configurationService: ConfigurationService
  ) {
    this.configuration = configurationService.configuration;
  }

  getReleaseNotesInfo(): Observable<ReleaseNotesInfoApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/release-notes/GetReleaseNotesInfo`;
    return this.httpClient.get<ReleaseNotesInfoApiModel[]>(url);
  }

  getReleaseNotes(title: string): any {
    const url = `${this.configuration.organizationsApiBaseUrl}/release-notes/GetReleaseNotes/${title}`;
    const httpOptions = {
      responseType: "blob" as "json",
    };
    return this.httpClient.get<any>(url, httpOptions);
  }

  areNewReleaseNotesAvailable(): Observable<boolean> {
    const url = `${this.configuration.organizationsApiBaseUrl}/release-notes/AreNewReleaseNotesAvailable`;
    return this.httpClient.get<boolean>(url);
  }

  updateUserInteraction() {
    const url = `${this.configuration.organizationsApiBaseUrl}/release-notes/UpdateUserInteraction`;
    return this.httpClient.post(url, null);
  }
}
