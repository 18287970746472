import { Constants } from 'src/app/core/constants';
import { PeriodHelper } from './../helpers/period.helper';
import { Injectable } from '@angular/core';
import { PeriodFilter } from '../models/periodFilter';
import { PeriodSelectorEnum } from 'src/app/core/enums/periodSelectorEnum';
import * as moment from 'moment';

@Injectable()
export class PeriodSelectorService {
  constructor() {}

  public getPeriodFilterBasedOnPeriodType(selectedPeriodType: number) {
    let startDate: Date;
    let endDate: Date;
    const currentDate = moment().startOf('day').toDate();
    endDate = currentDate;

    switch (selectedPeriodType) {
      case PeriodSelectorEnum.Week: {
        const firstDayOfTheWeek = moment(endDate).startOf('isoWeek').toDate();
        startDate = moment(firstDayOfTheWeek).startOf('day').toDate();
        break;
      }
      case PeriodSelectorEnum.Month: {
        const firstDayOfTheMonth = moment(endDate).startOf('month').toDate();
        startDate = moment(firstDayOfTheMonth).startOf('day').toDate();
        break;
      }
      case PeriodSelectorEnum.Quarter: {
        const quarter = Math.floor(currentDate.getMonth() / 3);
        startDate = new Date(currentDate.getFullYear(), quarter * 3, 1);
        break;
      }
      case PeriodSelectorEnum.Year: {
        const firstDayOfTheYear = moment(endDate).startOf('year').toDate();
        startDate = moment(firstDayOfTheYear).startOf('day').toDate();
        break;
      }
      case PeriodSelectorEnum.FourWeek: {
        const weekNumberAndYear = PeriodHelper.getWeekNumberAndYear(endDate);
        let ratio = weekNumberAndYear.weekNumber / 4;
        ratio = Math.trunc(ratio);
        let remainder = weekNumberAndYear.weekNumber % 4;
        remainder = Math.trunc(remainder);
        let firstWeekOfThePeriod;
        if (remainder === 0) {
          firstWeekOfThePeriod = 4 * (ratio - 1) + 1;
        } else {
          firstWeekOfThePeriod = 4 * ratio + 1;
        }
        const firstDayOfTheWeek = PeriodHelper.getDateOfISOWeek(
          firstWeekOfThePeriod,
          weekNumberAndYear.yearNumber
        );
        startDate = moment(firstDayOfTheWeek).startOf('day').toDate();
        break;
      }
    }

    endDate = moment(endDate).endOf('day').toDate();
    const periodFilter = new PeriodFilter();
    periodFilter.startDate = startDate;
    periodFilter.endDate = endDate;
    periodFilter.periodSelectorType = selectedPeriodType;

    return periodFilter;
  }

  getCustomPeriodFilter(startDate: Date, endDate: Date) {
    endDate.setHours(23, 59, 59, 999);
    const periodFilter = new PeriodFilter();
    periodFilter.startDate = startDate;
    periodFilter.endDate = endDate;
    periodFilter.periodSelectorType = PeriodSelectorEnum.Custom;

    return periodFilter;
  }

  getPreviousPeriodFilter(selectedPeriodFilter: PeriodFilter) {
    let newEndDate = new Date();
    let newStartDate = new Date();

    if (selectedPeriodFilter.periodSelectorType === PeriodSelectorEnum.Custom) {
      ({ newEndDate, newStartDate } = this.getPreviousCustomPeriodDates(
        selectedPeriodFilter
      ));

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodSelectorType: PeriodSelectorEnum.Custom,
        formattedPeriod: '',
      };
    } else {
      newEndDate = moment(selectedPeriodFilter.startDate)
        .add(-1, 'day')
        .toDate();

      switch (selectedPeriodFilter.periodSelectorType) {
        case PeriodSelectorEnum.Month:
          newStartDate = moment(newEndDate).startOf('month').toDate();
          newEndDate = moment(newEndDate).endOf('month').toDate();
          break;
        case PeriodSelectorEnum.Year:
          newStartDate = moment(newEndDate).startOf('year').toDate();
          newEndDate = moment(newEndDate).endOf('year').toDate();
          break;
        case PeriodSelectorEnum.Week:
          newStartDate = moment(newEndDate)
            .add(-6, 'day')
            .startOf('day')
            .toDate();
          break;
        case PeriodSelectorEnum.Quarter:
          ({ newStartDate, newEndDate } = this.getPreviousQuarterDates(
            selectedPeriodFilter
          ));
          break;
        case PeriodSelectorEnum.FourWeek:
          const lastWeekOfThePeriod = PeriodHelper.getWeekNumberAndYear(
            newEndDate
          );
          let firstDayOfFirstWeekOfThePeriod = PeriodHelper.getDateOfISOWeek(
            lastWeekOfThePeriod.weekNumber - 3,
            lastWeekOfThePeriod.yearNumber
          );
          if(PeriodHelper.IsLastWeekOfTheYear(lastWeekOfThePeriod.weekNumber)){
            firstDayOfFirstWeekOfThePeriod = PeriodHelper.getDateOfISOWeek(
              Constants.LAST_4WEEKS_PERIOD_OF_THE_YEAR_START_WEEK,
              lastWeekOfThePeriod.yearNumber
            );
          }
          newStartDate = moment(firstDayOfFirstWeekOfThePeriod)
            .startOf('day')
            .toDate();
          break;
        default:
          break;
      }

      newEndDate = moment(newEndDate).endOf('day').toDate();

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodSelectorType: selectedPeriodFilter.periodSelectorType,
        formattedPeriod: '',
      };
    }
  }

  private getPreviousCustomPeriodDates(selectedPeriodFilter: PeriodFilter) {
    let newEndDate;
    const previousStart = moment(selectedPeriodFilter.startDate);
    const previousEnd = moment(selectedPeriodFilter.endDate);
    const dayDiff = previousEnd.diff(previousStart, 'days');
    newEndDate = selectedPeriodFilter.startDate;
    newEndDate = moment(newEndDate).add(-1, 'day').endOf('day').toDate();

    const newStartDate = moment(newEndDate)
      .add(-dayDiff, 'day')
      .startOf('day')
      .toDate();

    return { newEndDate, newStartDate };
  }

  private getPreviousQuarterDates(selectedPeriodFilter: PeriodFilter) {
    let newStartDate = new Date();
    let newEndDate = new Date();

    let firstMonthOfTheCurrentQuarter = selectedPeriodFilter.startDate.getMonth();

    if (firstMonthOfTheCurrentQuarter === 12) {
      firstMonthOfTheCurrentQuarter = 0;
    }

    switch (firstMonthOfTheCurrentQuarter) {
      case 0: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear() - 1,
          9,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear() - 1,
          11,
          31,
          0
        );
        break;
      }
      case 3: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          0,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          2,
          31,
          0
        );
        break;
      }
      case 6: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          3,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          5,
          30,
          0
        );
        break;
      }
      case 9: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          6,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          8,
          30,
          0
        );
        break;
      }
      default:
        break;
    }

    return { newStartDate, newEndDate };
  }

  getNextPeriodFilter(periodFilter: PeriodFilter) {
    const previousStart = moment(periodFilter.startDate);
    const previousEnd = moment(periodFilter.endDate);
    const dayDiff = previousEnd.diff(previousStart, 'days');

    let newStartDate = moment(previousEnd).startOf('day').toDate();
    newStartDate = moment(newStartDate).add(1, 'day').startOf('day').toDate();
    let newEndDate = moment(newStartDate).endOf('day').toDate();

    if (periodFilter.endDate < moment().startOf('day').toDate()) {
      if (periodFilter.periodSelectorType === PeriodSelectorEnum.Custom) {
        newEndDate = moment(newStartDate)
          .add(dayDiff, 'day')
          .endOf('day')
          .toDate();
      } else {
        switch (periodFilter.periodSelectorType) {
          case PeriodSelectorEnum.Quarter:
            ({ newStartDate, newEndDate } = this.getNextQuarterDates(
              periodFilter
            ));
            break;
          case PeriodSelectorEnum.Month:
            newEndDate = moment(newStartDate).endOf('month').toDate();
            break;
          case PeriodSelectorEnum.Year:
            newEndDate = moment(newStartDate).endOf('year').toDate();
            break;
          case PeriodSelectorEnum.Week:
            newEndDate = moment(newStartDate)
              .add(6, 'day')
              .endOf('day')
              .toDate();
            break;
          case PeriodSelectorEnum.FourWeek:
            const lastWeekOfThePeriod = PeriodHelper.getWeekNumberAndYear(
              newStartDate
            );
            if (lastWeekOfThePeriod.weekNumber === Constants.LAST_4WEEKS_PERIOD_OF_THE_YEAR_START_WEEK) {
              const lastWeekNumberOfYear = PeriodHelper.getLastWeekNumberOfYear(lastWeekOfThePeriod.yearNumber);
              lastWeekOfThePeriod.weekNumber = lastWeekNumberOfYear.weekNumber;
              lastWeekOfThePeriod.yearNumber = lastWeekNumberOfYear.yearNumber;
            } else {
              lastWeekOfThePeriod.weekNumber = lastWeekOfThePeriod.weekNumber + 3;
            }

            const firstDayOfFirstWeekOfThePeriod = PeriodHelper.getDateOfISOWeek(
              lastWeekOfThePeriod.weekNumber,
              lastWeekOfThePeriod.yearNumber
            );
            newEndDate = moment(firstDayOfFirstWeekOfThePeriod)
              .endOf('isoWeek')
              .toDate();
            newEndDate = moment(newEndDate)
            .endOf('day')
            .toDate();
            break;
          default:
            break;
        }
      }

      if (moment(newEndDate).endOf('day') > moment().endOf('day')) {
        newEndDate = moment().endOf('day').toDate();
      }

      newEndDate = moment(newEndDate).endOf('day').toDate();
      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodSelectorType: periodFilter.periodSelectorType,
        formattedPeriod: '',
      };
    }

    if (moment(newEndDate).endOf('day') > moment().endOf('day')) {
      newEndDate = moment().endOf('day').toDate();
    }

    return {
      startDate: newStartDate,
      endDate: newEndDate,
      periodSelectorType: periodFilter.periodSelectorType,
      formattedPeriod: '',
    };
  }

  private getNextQuarterDates(previouslySelectedPeriodFilter: PeriodFilter) {
    let newStartDate = new Date();
    let newEndDate = new Date();
    let firstMonthOfTheCurrentQuarter = previouslySelectedPeriodFilter.startDate.getMonth();

    if (firstMonthOfTheCurrentQuarter === 12) {
      firstMonthOfTheCurrentQuarter = 0;
    }
    switch (firstMonthOfTheCurrentQuarter) {
      case 0: {
        newStartDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear(),
          3,
          1,
          0
        );
        newEndDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear(),
          5,
          30,
          0
        );
        break;
      }
      case 3: {
        newStartDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear(),
          6,
          1,
          0
        );
        newEndDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear(),
          8,
          30,
          0
        );
        break;
      }
      case 6: {
        newStartDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear(),
          9,
          1,
          0
        );
        newEndDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear(),
          11,
          31,
          0
        );
        break;
      }
      case 9: {
        newStartDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear() + 1,
          0,
          1,
          0
        );
        newEndDate = new Date(
          previouslySelectedPeriodFilter.startDate.getFullYear() + 1,
          2,
          31,
          0
        );
        break;
      }
      default:
        break;
    }

    return { newStartDate, newEndDate };
  }
}
