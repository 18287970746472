import { OrganizationOverview } from './../models/organizationOverview';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlatformUser } from '../models/platformUser';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { RegisterOrganization } from '../models/registerOrganization';
import { EnumApiModel } from 'src/app/core/models/enumApiModel';
import { Constants } from 'src/app/core/constants';
import { PlatformRole } from 'src/app/core/enums/platformRoleEnum';
import { MaintenanceEmailApiModel } from '../models/maintenanceEmailApiModel';
import { OrganizationSettingApiModel } from 'src/app/organizations-settings/models/organizationSettingApiModel';

@Injectable()
export class PlatformAdministrationService {
  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  getUsers(): Observable<PlatformUser[]> {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/getuserlist`;
    return this.httpClient.get<PlatformUser[]>(url);
  }

  togglePlatformRole(userId: number, role: PlatformRole) {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/togglePlatformRole/${userId}/${role}`;
    return this.httpClient.put(url, {
      headers: new HttpHeaders(`${Constants.API_REQUEST_CONTENT_TYPE}`)
    });
  }

  isUserPlatformAdministrator(): Observable<boolean> {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/IsUserPlatformAdministrator`;
    return this.httpClient.get<boolean>(url);
  }

  getOrganizationsOverview(): Observable<OrganizationOverview[]> {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/getorganizationsoverview`;
    return this.httpClient.get<OrganizationOverview[]>(url);
  }

  getOrganizations(): Observable<OrganizationOverview[]> {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/getorganizations`;
    return this.httpClient.get<OrganizationOverview[]>(url);
  }

  registerOrganization(organization: RegisterOrganization): Observable<number> {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/registerOrganization`;
    return this.httpClient.post<number>(url, organization, {
      headers: new HttpHeaders(`${Constants.API_REQUEST_CONTENT_TYPE}`)
    });
  }

  getPlatformRoles() {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/getPlatformRoles`;
    return this.httpClient.get<EnumApiModel[]>(url);
  }

  getMaintenanceEmailGreeting(): Observable<string> {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/getMaintenanceEmailGreeting`;
    return this.httpClient.get(url,{responseType:'text'});
  }

  sendMaintenanceEmail(content: MaintenanceEmailApiModel) {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/sendMaintenanceEmail`;
    return this.httpClient.post(url, content, {
      headers: new HttpHeaders(`${Constants.API_REQUEST_CONTENT_TYPE}`)
    });
  }

  updateOrganizationSettings(organizationSettings: OrganizationSettingApiModel[]) {
    const url = `${this.configuration.platformAdministrationApiBaseUrl}/platformadministration/updateOrganizationSettings`;
    return this.httpClient.post(url, organizationSettings);
  }
}
