import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ClientConfiguration} from '../models/client-configuration.model';

@Injectable()
export class ConfigurationService {
  private _configuration: ClientConfiguration;

  public get configuration(): ClientConfiguration {
    return this._configuration;
  }

  constructor(private readonly httpClient: HttpClient) {
  }

  public load(): Promise<any> {
    this._configuration = null;

    return this.httpClient.get<ClientConfiguration>('/configuration/client')
      .toPromise()
      .then((data: ClientConfiguration) => {
        this._configuration = data;
      })
      .catch((error: any) => {
        console.error('error while retrieving configuration', error);
        this._configuration = environment.clientConfiguration;
        return Promise.resolve();
      });
  }
}
