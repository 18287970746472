
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NewPeriodSelectorConfigurationType } from 'src/app/core/enums/newPeriodSelectorConfigurationType';
import { NewPeriodSelectorInterval } from 'src/app/core/enums/newPeriodSelectorInterval';
import { CropCycleApiModel } from 'src/app/core/models/cropCycles/cropCycleApiModel';
import { CropCycleIntervalApiModel } from 'src/app/core/models/cropCycles/cropCycleIntervalApiModel';
import { NewCalendarPeriodFilter } from 'src/app/core/models/period-selector/newCalendarPeriodFilter';
import { NewCropCyclePeriodFilter } from 'src/app/core/models/period-selector/newCropCyclePeriodFilter';
import { NewPeriodSelectorService } from 'src/app/core/services/new-period-selector.service';
import { SavedViewTimeFrame } from '../enums/savedViewTimeFrame';
import { SavedViewApiModel } from '../models/savedViewApiModel';
import { CropCyclesPeriodHelper } from './cropCyclesPeriodHelper';

@Injectable()
export class ComparisonPeriodHelper {
  constructor(
    private readonly newPeriodSelectorService: NewPeriodSelectorService
  ) { }

  private getPeriodSelectorIntializationCropCycles(cropCycles: CropCycleApiModel[], selectedCropCycles: number[]): CropCycleIntervalApiModel[] {
    const resultingCropCycles = [];
    if (cropCycles.length) {
      selectedCropCycles.forEach((cc) => {
        const cropCycle = cropCycles.find((c) => c.id === cc);
        resultingCropCycles.push({
          id: cropCycle.id,
          startDate: cropCycle.startDate,
          endDate: cropCycle.endDate,
        });
      });
    }
    return resultingCropCycles;
  }

  loadPeriodSelectorUsingSavedView(savedView: SavedViewApiModel, availableCropCycles: CropCycleApiModel[], changedTimeFrame: boolean = false) {
    if (!this.isValidPeriodSelector(savedView) || changedTimeFrame) {
      this.initializePeriodSelectorForSavedView(savedView, availableCropCycles);
    }
    else {
      this.configurePeriodSelector(savedView, availableCropCycles);
    }
  }

  private isValidPeriodSelector(savedView: SavedViewApiModel): boolean {
    switch (savedView.periodSelectorType) {
      case NewPeriodSelectorInterval.CropToDate:
      case NewPeriodSelectorInterval.Full: {
        return true;
      }
      case NewPeriodSelectorInterval.ToDate:
      case NewPeriodSelectorInterval.CropWeek: {
        return !!savedView.periodIntervalLength;
      }
      case NewPeriodSelectorInterval.Day:
      case NewPeriodSelectorInterval.Week:
      case NewPeriodSelectorInterval.FourWeek:
      case NewPeriodSelectorInterval.Month:
      case NewPeriodSelectorInterval.Quarter:
      case NewPeriodSelectorInterval.Year:
      case NewPeriodSelectorInterval.Custom: {
        return !!savedView.startDate && !!savedView.endDate;
      }
      default: {
        return false;
      }
    };
  }

  public configurePeriodSelector(savedView: SavedViewApiModel, availableCropCycles: CropCycleApiModel[]) {
    const selectedLineChartCropCycles = savedView.savedViewLineSerieConfigurations.filter(c => !!c.cropCycleId).map(c => c.cropCycleId);
    const selectedScatterPlotCropCycles = savedView.savedViewScatterPlotSerieConfigurations.filter(c => !!c.cropCycleId).map(c => c.cropCycleId);
    const selectedCropCycles = Array.from(new Set(selectedLineChartCropCycles.concat(selectedScatterPlotCropCycles)));

    switch (savedView.periodSelectorType) {
      case NewPeriodSelectorInterval.ToDate: {
        const periodFilter = new NewCropCyclePeriodFilter();
        periodFilter.periodInterval = NewPeriodSelectorInterval.ToDate;
        periodFilter.intervalLength = savedView.periodIntervalLength;
        const cropCycles = this.getPeriodSelectorIntializationCropCycles(availableCropCycles, selectedCropCycles);
        this.newPeriodSelectorService.configurePeriodSelectorAndSelectPeriod(
          NewPeriodSelectorConfigurationType.CompareCropCycle,
          periodFilter,
          cropCycles
        );
        break;
      }
      case NewPeriodSelectorInterval.CropToDate: {
        const periodFilter = new NewCropCyclePeriodFilter();
        periodFilter.periodInterval = NewPeriodSelectorInterval.CropToDate;
        const cropCycles = this.getPeriodSelectorIntializationCropCycles(availableCropCycles, selectedCropCycles);
        this.newPeriodSelectorService.configurePeriodSelectorAndSelectPeriod(
          NewPeriodSelectorConfigurationType.CompareCropCycle,
          periodFilter,
          cropCycles
        );
        break;
      }
      case NewPeriodSelectorInterval.Full: {
        const periodFilter = new NewCropCyclePeriodFilter();
        periodFilter.periodInterval = NewPeriodSelectorInterval.Full;
        const cropCycles = this.getPeriodSelectorIntializationCropCycles(availableCropCycles, selectedCropCycles);
        this.newPeriodSelectorService.configurePeriodSelectorAndSelectPeriod(
          NewPeriodSelectorConfigurationType.CompareCropCycle,
          periodFilter,
          cropCycles
        );
        break;
      }
      case NewPeriodSelectorInterval.CropWeek: {
        const periodFilter = new NewCropCyclePeriodFilter();
        periodFilter.periodInterval = NewPeriodSelectorInterval.CropWeek;
        periodFilter.intervalLength = savedView.periodIntervalLength;
        const cropCycles = this.getPeriodSelectorIntializationCropCycles(availableCropCycles, selectedCropCycles);
        this.newPeriodSelectorService.configurePeriodSelectorAndSelectPeriod(
          NewPeriodSelectorConfigurationType.CompareCropCycle,
          periodFilter,
          cropCycles
        );
        break;
      }
      case NewPeriodSelectorInterval.Day:
      case NewPeriodSelectorInterval.Week:
      case NewPeriodSelectorInterval.FourWeek:
      case NewPeriodSelectorInterval.Month:
      case NewPeriodSelectorInterval.Quarter:
      case NewPeriodSelectorInterval.Year:
      case NewPeriodSelectorInterval.Custom: {
        const periodFilter = new NewCalendarPeriodFilter();
        periodFilter.periodInterval = savedView.periodSelectorType;
        periodFilter.startDate = moment(savedView.startDate).toDate();
        periodFilter.endDate = moment(savedView.endDate).toDate();
        this.newPeriodSelectorService.configurePeriodSelectorAndSelectPeriod(
          NewPeriodSelectorConfigurationType.OldPeriodSelector,
          periodFilter
        );
        break;
      }
    }
  }
  private initializePeriodSelectorForSavedView(savedView: SavedViewApiModel, availableCropCycles: CropCycleApiModel[]) {
    const selectedLineChartCropCycles = savedView.savedViewLineSerieConfigurations.filter(c => !!c.cropCycleId).map(c => c.cropCycleId);
    const selectedScatterPlotCropCycles = savedView.savedViewScatterPlotSerieConfigurations.filter(c => !!c.cropCycleId).map(c => c.cropCycleId);
    const selectedCropCycles = Array.from(new Set(selectedLineChartCropCycles.concat(selectedScatterPlotCropCycles)));

    const cropCycles = this.getPeriodSelectorIntializationCropCycles(availableCropCycles, selectedCropCycles);
    if (savedView.savedViewTimeFrame === SavedViewTimeFrame.Relative) {
      const periodFilter = this.getRelativeComparisonDefaultFilter(cropCycles);

      this.newPeriodSelectorService.configurePeriodSelectorAndSelectPeriod(
        NewPeriodSelectorConfigurationType.CompareCropCycle,
        periodFilter,
        cropCycles
      );
    } else {
      const periodFilter = this.getAbsoluteComparisonDefaultFilter(cropCycles);
      this.newPeriodSelectorService.configurePeriodSelectorAndSelectPeriod(
        NewPeriodSelectorConfigurationType.OldPeriodSelector,
        periodFilter
      );
    }
  }

  public getAbsoluteComparisonDefaultFilter(cropCycles: CropCycleIntervalApiModel[]) {
    const { minCropCyclesStartDate, maxCropCyclesEndDate } = CropCyclesPeriodHelper.getCropCyclesMinStartDateAndMaxEndDate(cropCycles);
    const defaultPeriodSelectorEndDate = moment.min(moment(), moment(maxCropCyclesEndDate)).toDate();
    const defaultPeriodSelectorStartDate = moment.max(moment(defaultPeriodSelectorEndDate).add('-1', 'years'), moment(minCropCyclesStartDate)).toDate();

    const periodFilter = new NewCalendarPeriodFilter();
    periodFilter.periodInterval = NewPeriodSelectorInterval.Custom;
    periodFilter.startDate = defaultPeriodSelectorStartDate;
    periodFilter.endDate = defaultPeriodSelectorEndDate;

    return periodFilter;
  }

  public getRelativeComparisonDefaultFilter(cropCycles: CropCycleIntervalApiModel[]) {
    const intervalLength = CropCyclesPeriodHelper.getMaxWeekCountForCropCycles(cropCycles);
    const periodFilter = new NewCropCyclePeriodFilter();
    periodFilter.periodInterval = NewPeriodSelectorInterval.Full;
    periodFilter.intervalLength = !!intervalLength ? intervalLength : 1;
    return periodFilter;
  }
}
