import { TranslateService } from "@ngx-translate/core";
import { PermissionService } from "src/app/core/services/permission.service";
import { PeriodHelper } from "src/app/core/helpers/period.helper";
import { Injectable } from "@angular/core";
import { Subject, ReplaySubject } from "rxjs";
import { PeriodFilter } from "../models/periodFilter";
import { EntityFilter } from "../models/entityFilter";
import { PeriodSelectorEnum } from "src/app/core/enums/periodSelectorEnum";
import { PageLayoutSettings } from "../models/pageLayoutSettings";
import { PeriodSelectorService } from "./period-selector.service";
import { FormattingService } from "./formatting.service";
import { CropCyclesPeriodSelectorEnum } from "src/app/core/enums/cropCyclePeriodSelectorEnum";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { OrganizationService } from "./organization.service";
import { PermissionType } from "../models/permissionType";
import { NotificationService } from "./notification.service";
import { PermissionLevel } from "../models/permissionLevel";
import { CropCycleFilter } from "../models/cropCycles/cropCycleFilter";
import { CropCyclesPeriodFilter } from "../models/cropCycles/cropCyclesPeriodFilter";
import { AlertEventsService } from "./alert-events.service";
import { TasksService } from "src/app/tasks/services/tasks.service";

@Injectable({
  providedIn: "root",
})
export class AppSettingsService {
  public organizationChanged$ = new ReplaySubject<EntityFilter>(1);
  public siteChanged$ = new Subject<{ site: EntityFilter }>();
  public zoneChanged$ = new Subject<{ zone: EntityFilter }>();
  public cropCycleChanged$ = new Subject<CropCycleFilter>();
  public periodChanged$ = new Subject<{ periodFilter: PeriodFilter }>();
  public pageLayoutSettingsChanged$ = new Subject<{
    pageLayoutSettings: PageLayoutSettings;
  }>();
  public chatWindowVisibilityChanged$ = new Subject<boolean>();
  public cropCyclesPeriodChanged$ = new Subject<{
    cropCyclesPeriodFilter: CropCyclesPeriodFilter;
  }>();
  public pendingAlertEventsUpdated$ = new Subject();
  public permissionsUpdated$ = new Subject();
  public growerPermissionsUpdated$ = new Subject<{
    hasGrowerZonePermissions: boolean;
  }>();
  public managerPermissionsUpdated$ = new Subject<{
    hasManagerZonePermissions: boolean;
  }>();
  public yieldPredictionsPermissionsUpdated$ = new Subject<{
    hasYieldPredictionsZonePermissions: boolean;
  }>();
  public cO2OptimizerZonePermissionsUpdated$ = new Subject<{
    hasCO2OptimizerZonePermissions: boolean;
  }>();
  public menuItemChanged$ = new Subject();
  private taskCount$ = new Subject<number>();
  private alertCount$ = new Subject<number>();

  periodFilter: PeriodFilter;
  organizationFilter: EntityFilter;
  siteFilter: EntityFilter;
  zoneFilter: EntityFilter;
  cropCycleFilter: CropCycleFilter;
  isChatWindowVisible: boolean;
  selectedCropCyclesToCompare: number[];
  private cropCyclesPeriodFilter: CropCyclesPeriodFilter;
  hasGrowerZonePermissions: boolean;
  hasManagerZonePermissions: boolean;
  hasYieldPredictionsZonePermissions: boolean;
  hasCO2OptimizerZonePermissions: boolean;

  constructor(
    private readonly periodSelectorService: PeriodSelectorService,
    private readonly formattingService: FormattingService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly datePipe: DatePipe,
    private readonly router: Router,
    private readonly organizationService: OrganizationService,
    private readonly permissonsService: PermissionService,
    private readonly notificationsService: NotificationService,
    private readonly translationsService: TranslateService,
    private readonly taskService: TasksService,
    private readonly alertService: AlertEventsService
  ) {}

  public getPeriodFilter() {
    if (!this.periodFilter) {
      const queryPeriodSelectorType =
        this.activatedRoute.snapshot.queryParams["periodSelectorType"];
      const queryStartDate =
        this.activatedRoute.snapshot.queryParams["startDate"];
      const queryEndDate = this.activatedRoute.snapshot.queryParams["endDate"];

      if (!queryPeriodSelectorType) {
        const lastPeriodSelectionType = localStorage.getItem(
          "lastPeriodSelectionType"
        );

        if (!lastPeriodSelectionType) {
          // try to get the recent new period selected info
          let newPeriodFilterPeriodInterval = +localStorage.getItem(
            "lastPeriodSelectorInterval"
          );
          const startDate = localStorage.getItem("lastPeriodSelectorStartDate");
          const endDate = localStorage.getItem("lastPeriodSelectorEndDate");
          if (newPeriodFilterPeriodInterval && startDate && endDate) {
            this.convertNewPeriodSelectionToOldPeriodSelection(
              newPeriodFilterPeriodInterval,
              startDate,
              endDate
            );
          } else {
            this.applyDefaultSelection();
          }
        } else {
          const lastPeriodSelectionTypeValue = +lastPeriodSelectionType;

          if (lastPeriodSelectionTypeValue !== PeriodSelectorEnum.Custom) {
            this.periodFilter =
              this.periodSelectorService.getPeriodFilterBasedOnPeriodType(
                lastPeriodSelectionTypeValue
              );
          } else {
            const startDate = new Date(
              localStorage.getItem("customPeriodStartDate")
            );
            const endDate = new Date(
              localStorage.getItem("customPeriodEndDate")
            );
            this.periodFilter =
              this.periodSelectorService.getCustomPeriodFilter(
                startDate,
                endDate
              );
          }
        }
      } else {
        const periodFilter = new PeriodFilter();
        periodFilter.startDate = new Date(queryStartDate);
        periodFilter.endDate = new Date(queryEndDate);
        periodFilter.periodSelectorType = +queryPeriodSelectorType;
        this.periodFilter = this.periodSelectorService.getCustomPeriodFilter(
          periodFilter.startDate,
          periodFilter.endDate
        );
        this.periodFilter.periodSelectorType = periodFilter.periodSelectorType;
      }
    }

    this.setFormattedPeriod(this.periodFilter);
    return this.periodFilter;
  }

  applyDefaultSelection() {
    this.periodFilter =
      this.periodSelectorService.getPeriodFilterBasedOnPeriodType(
        PeriodSelectorEnum.Month
      );
    localStorage.setItem(
      "lastPeriodSelectionType",
      PeriodSelectorEnum.Month.toString()
    );
  }

  convertNewPeriodSelectionToOldPeriodSelection(
    newPeriodFilterPeriodInterval: number,
    startDate: string,
    endDate: string
  ) {
    const periodSelectorType =
      PeriodHelper.GetOldPeriodSelectionTypeBasedOnNewPeriodInterval(
        newPeriodFilterPeriodInterval
      );
    if (periodSelectorType !== PeriodSelectorEnum.Custom) {
      this.periodFilter =
        this.periodSelectorService.getPeriodFilterBasedOnPeriodType(
          periodSelectorType
        );
      this.periodFilter.startDate = new Date(startDate);
      this.periodFilter.endDate = new Date(endDate);
    } else {
      const customStartDate = new Date(startDate);
      const customEndDate = new Date(endDate);
      this.periodFilter = this.periodSelectorService.getCustomPeriodFilter(
        customStartDate,
        customEndDate
      );
    }
  }

  public setPeriodFilter(periodFilter: PeriodFilter) {
    this.periodFilter = periodFilter;

    this.setFormattedPeriod(this.periodFilter);

    this.periodChanged$.next({
      periodFilter: {
        periodSelectorType: this.periodFilter.periodSelectorType,
        startDate: this.periodFilter.startDate,
        endDate: this.periodFilter.endDate,
        formattedPeriod: periodFilter.formattedPeriod,
      },
    });
    this.updatePeriodSelectorUrl();
  }

  resetPeriodSelector() {
    if (this.periodFilter) {
      localStorage.removeItem("lastPeriodSelectionType");
      const emptyPeriodFilter = null;
      this.periodFilter = emptyPeriodFilter;
      this.periodChanged$.next({
        periodFilter: emptyPeriodFilter,
      });
    }
  }

  setFormattedPeriod(periodFilter: PeriodFilter) {
    this.periodFilter.formattedPeriod =
      this.formattingService.getFormatedPeriod(periodFilter);
  }

  public getOrganizationFilter() {
    return this.organizationChanged$.asObservable();
  }

  public setOrganizationFilter(organizationId) {
    if (
      !this.organizationFilter ||
      this.organizationFilter.id !== organizationId
    ) {
      localStorage.setItem("userOrganizationId", organizationId.toString());
      this.updatePermissions(organizationId);
      this.organizationFilter = new EntityFilter();
      this.organizationFilter.id = organizationId;
      this.organizationService
        .getOrganization(organizationId)
        .subscribe((org) => {
          this.organizationFilter = {
            id: org.id,
            name: org.name,
          };
          this.organizationChanged$.next(this.organizationFilter);
        });

      this.taskService
        .retrieveOpenAssignmentCount(organizationId)
        .subscribe((count) => {
          this.setTaskCount(count);
        });

      this.alertService
        .getOrganizationPendingAlertEventsCounter(organizationId)
        .subscribe((count) => {
          this.setAlertCount(count);
        });
    }
  }


  public getSiteFilter() {
    return this.siteFilter;
  }

  public setSiteFilter(siteFilter: EntityFilter) {
    this.siteFilter = siteFilter;
    this.siteChanged$.next({ site: this.siteFilter });
  }

  public updatePermissions(organizationId: number) {
    this.permissonsService
      .getUserOrganizationPermissionTypes(organizationId)
      .subscribe((organizationPermissions) => {
        this.permissionsUpdated$.next();
        const managerPermissions =
          organizationPermissions.find(
            (r) => r.type === PermissionType.ViewStrategicDashboard
          ).level === PermissionLevel.Allow;
        const growerPermissions =
          organizationPermissions.find((r) => r.type === PermissionType.ViewGrowerDashboard)
            .level === PermissionLevel.Allow;
        const yieldPredictionsPermissions =
          organizationPermissions.find(
            (r) => r.type === PermissionType.ViewYieldPredictionsDashboard
          ).level === PermissionLevel.Allow;

          const co2OptimizerPermissions =
          organizationPermissions.find(
            (r) => r.type === PermissionType.ViewCO2OptimizerDashboard
          ).level === PermissionLevel.Allow;

        if (managerPermissions !== this.hasManagerZonePermissions) {
          this.hasManagerZonePermissions = managerPermissions;
          this.managerPermissionsUpdated$.next({
            hasManagerZonePermissions: this.hasManagerZonePermissions,
          });
        }
        if (growerPermissions !== this.hasGrowerZonePermissions) {
          this.hasGrowerZonePermissions = growerPermissions;
          this.growerPermissionsUpdated$.next({
            hasGrowerZonePermissions: this.hasGrowerZonePermissions,
          });
        }

        if (
          yieldPredictionsPermissions !==
          this.hasYieldPredictionsZonePermissions
        ) {
          this.hasYieldPredictionsZonePermissions =
            yieldPredictionsPermissions;
          this.yieldPredictionsPermissionsUpdated$.next({
            hasYieldPredictionsZonePermissions:
              this.hasYieldPredictionsZonePermissions,
          });
        }

        if (
          co2OptimizerPermissions !==
          this.hasCO2OptimizerZonePermissions
        ) {
          this.hasCO2OptimizerZonePermissions =
          co2OptimizerPermissions;
          this.cO2OptimizerZonePermissionsUpdated$.next({
            hasCO2OptimizerZonePermissions:
              this.hasCO2OptimizerZonePermissions,
          });
        }
      });
  }

  public handleManagerZonePermissions() {
    if (
      !this.hasManagerZonePermissions &&
      this.hasManagerZonePermissions !== undefined
    ) {
      this.notificationsService.showError(
        this.translationsService.instant("Shell.generic.noZonePermissionsInModule")
      );
    }
  }

  public handleGrowerZonePermissions() {
    if (
      !this.hasGrowerZonePermissions &&
      this.hasGrowerZonePermissions !== undefined
    ) {
      this.notificationsService.showError(
        this.translationsService.instant("Shell.generic.noZonePermissionsInModule")
      );
    }
  }

  public handleYieldPredictionsZonePermissions() {
    if (
      !this.hasYieldPredictionsZonePermissions &&
      this.hasYieldPredictionsZonePermissions !== undefined
    ) {
      this.notificationsService.showError(
        this.translationsService.instant("Shell.generic.noZonePermissionsInModule")
      );
    }
  }

  public handleCO2OptimizerZonePermissions() {
    if (
      !this.hasCO2OptimizerZonePermissions &&
      this.hasCO2OptimizerZonePermissions !== undefined
    ) {
      this.notificationsService.showError(
        this.translationsService.instant("Shell.generic.noZonePermissionsInModule")
      );
    }
  }

  public getZoneFilter() {
    return this.zoneFilter;
  }

  public setZoneFilter(zoneFilter: EntityFilter) {
    this.zoneFilter = zoneFilter;
    this.zoneChanged$.next({ zone: this.zoneFilter });
  }

  public getCropCycleFilter() {
    return this.cropCycleChanged$.asObservable();
  }

  public setCropCycleFilter(cropCycleFilter: CropCycleFilter) {
    this.cropCycleFilter = cropCycleFilter;
    this.cropCycleChanged$.next(this.cropCycleFilter);
  }

  public setPageLayoutSettings(pageLayoutSettings: PageLayoutSettings) {
    this.pageLayoutSettingsChanged$.next({ pageLayoutSettings });
  }

  public getChatWindowVisibility() {
    if (this.isChatWindowVisible === undefined) {
      this.isChatWindowVisible = false;
    }
    return this.isChatWindowVisible;
  }

  public setChatWindowVisibility(showChatWindow: boolean) {
    this.isChatWindowVisible = showChatWindow;
    this.chatWindowVisibilityChanged$.next(this.isChatWindowVisible);
  }

  public getCropCyclesPeriodFilter() {
    if (!this.cropCyclesPeriodFilter) {
      const queryPeriodSelectorType =
        this.activatedRoute.snapshot.queryParams["periodSelectorType"];
      const queryWeekNumber =
        this.activatedRoute.snapshot.queryParams["weekNumber"];

      this.cropCyclesPeriodFilter = new CropCyclesPeriodFilter();
      this.cropCyclesPeriodFilter.periodSelectorType = queryPeriodSelectorType;
      if (queryWeekNumber) {
        this.cropCyclesPeriodFilter.weekNumber = queryWeekNumber;
      }
    }

    this.setFormattedCropCyclePeriod(this.cropCyclesPeriodFilter);
    return this.cropCyclesPeriodFilter;
  }

  public setCropCyclesPeriodFilter(periodFilter: CropCyclesPeriodFilter) {
    this.cropCyclesPeriodFilter = new CropCyclesPeriodFilter();
    this.cropCyclesPeriodFilter.periodSelectorType =
      periodFilter.periodSelectorType;
    if (
      periodFilter.periodSelectorType ===
        CropCyclesPeriodSelectorEnum.CropWeek ||
      periodFilter.periodSelectorType === CropCyclesPeriodSelectorEnum.ToDate
    ) {
      this.cropCyclesPeriodFilter.weekNumber = periodFilter.weekNumber;
    }

    this.setFormattedCropCyclePeriod(this.cropCyclesPeriodFilter);
    this.cropCyclesPeriodChanged$.next({
      cropCyclesPeriodFilter: this.cropCyclesPeriodFilter,
    });
    this.updateCropCyclePeriodSelectorUrl();
  }

  setFormattedCropCyclePeriod(periodFilter: CropCyclesPeriodFilter) {
    this.cropCyclesPeriodFilter.formattedPeriod =
      this.formattingService.getCropCyclesFormatedPeriod(periodFilter);
  }

  updatePeriodSelectorUrl() {
    const period = this.getPeriodFilter();
    const urlTree = this.router.createUrlTree([], {
      queryParams: {
        periodSelectorType: period.periodSelectorType,
        startDate: this.datePipe.transform(period.startDate, "shortDate"),
        endDate: this.datePipe.transform(period.endDate, "shortDate"),
      },
      queryParamsHandling: "merge",
      preserveFragment: true,
    });
    this.router.navigateByUrl(urlTree.toString(), {
      replaceUrl: true,
    });
  }

  updateCropCyclePeriodSelectorUrl() {
    const period = this.getCropCyclesPeriodFilter();
    const urlTree = this.router.createUrlTree([], {
      queryParams: {
        periodSelectorType: period.periodSelectorType,
        weekNumber:
          period.periodSelectorType !== CropCyclesPeriodSelectorEnum.Full
            ? period.weekNumber
            : null,
      },
      queryParamsHandling: "merge",
      preserveFragment: true,
    });
    this.router.navigateByUrl(urlTree.toString(), {
      replaceUrl: true,
    });
  }

  get menuItemChanged() {
    return this.menuItemChanged$.asObservable();
  }

  setMenuItemChanged() {
    this.menuItemChanged$.next();
  }

  get taskCount() {
    return this.taskCount$.asObservable();
  }

  setTaskCount(tasks: number) {
    this.taskCount$.next(tasks);
  }

  get alertCount() {
    return this.alertCount$.asObservable();
  }

  setAlertCount(alerts: number) {
    this.alertCount$.next(alerts);
  }
}
