export class SessionStorageManagementHelper {
  public static resetComparisonToolSettings(): void {
    sessionStorage.removeItem('savedViewTimeFrame');
    sessionStorage.removeItem('savedViewPeriodSelectorType');
    sessionStorage.removeItem('savedViewStartDate');
    sessionStorage.removeItem('savedViewEndDate');
    sessionStorage.removeItem('savedViewPeriodIntervalLength');
    sessionStorage.removeItem('graphRepresentationType');
    sessionStorage.removeItem('viewType');
    sessionStorage.removeItem('savedViewLineSerieConfigurations');
    sessionStorage.removeItem('savedViewScatterPlotConfigurations');
    sessionStorage.removeItem('selectedLineChartComparisonLevel');
    sessionStorage.removeItem('selectedScatterPlotComparisonLevel');
    sessionStorage.removeItem('savedViewResourcesSerieConfigurations');
  }


  public static resetFilters(): void {
    sessionStorage.removeItem('resourcesOverviewStorage');
    sessionStorage.removeItem('sitesOverviewStorage');
    sessionStorage.removeItem('usersOverviewStorage');
    sessionStorage.removeItem('invitationsOverviewStorage');
    sessionStorage.removeItem('userDetailsUserGroupsOverviewStorage');
    sessionStorage.removeItem('userGroupsOverviewStorage');
    sessionStorage.removeItem('devicesOverviewStorage');
    sessionStorage.removeItem('deviceMessagesOverviewStorage');
    sessionStorage.removeItem('deviceMappingOverviewStorage');
    sessionStorage.removeItem('deviceMissingTagValuesOverviewStorage');
    sessionStorage.removeItem('deviceMappingZonesOverviewStorage');
    sessionStorage.removeItem('deviceMappingTagsOverviewStorage');
    sessionStorage.removeItem('auditEventsOverviewStorage');
    sessionStorage.removeItem('budgetConfigurationOverviewStorage');
    sessionStorage.removeItem('userGroupUsersOverviewStorage');
    sessionStorage.removeItem('userGroupDetailsUsersOverviewStorage');
    sessionStorage.removeItem('userManagementStorage');
    sessionStorage.removeItem('cropCycleTemplatesOverviewStorage');
    sessionStorage.removeItem('organizationsOverviewStorage');
    sessionStorage.removeItem('cropCyclesOverviewStorage');
    sessionStorage.removeItem('projectionsOverviewStorage');
    sessionStorage.removeItem('cropCycleTemplatesStorage');
    sessionStorage.removeItem('savedViewsOverviewStorage');
    sessionStorage.removeItem('zonesOverviewStorage');
    sessionStorage.removeItem('selectOrganizationsOverviewStorage');
    sessionStorage.removeItem('alertEventsOverviewStorage');
    sessionStorage.removeItem('cropCycleZonesOverviewStorage');
    sessionStorage.removeItem('quickAssignmentConfiguration');
    sessionStorage.removeItem('siteDataGroupwiseMappingGrid');
    sessionStorage.removeItem('zoneDataGroupwiseMappingGrid');
    sessionStorage.removeItem('cropCycleDataGroupwiseMappingGrid');
    sessionStorage.removeItem('graphYScale');
  }

  public static resetOrganizationRelatedUserSelections(): void {
    sessionStorage.removeItem('userSiteId');
    sessionStorage.removeItem('userZoneId');
    sessionStorage.removeItem('apiKeysToken');
    this.resetOrganizationYieldRelatedUserSelections();
    this.resetOrganizationCO2OptimizerRelatedUserSelections();
  }

  public static resetOrganizationCO2OptimizerRelatedUserSelections(): void {
    sessionStorage.removeItem('selectedCO2OptimizerSiteId');
    sessionStorage.removeItem('selectedCO2OptimizerCropCycleId');
  }

  public static resetOrganizationYieldRelatedUserSelections(): void {
    sessionStorage.removeItem('selectedYieldSiteId');
    sessionStorage.removeItem('selectedYieldCropCycleId');
  }

  public static resetOrganizationPIRelatedUserSelections(): void {
    sessionStorage.removeItem('pi_details_graph_statistics_type');
    sessionStorage.removeItem('pi_details_graph_representation_type');
  }
}
