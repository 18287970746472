import { ResourceCharacteristicTypeEnum } from '../enums/resourceCharacteristicTypeEnum';

export class CurrencyHelper {

   public static isCurrency(resourceCharacteristicType: ResourceCharacteristicTypeEnum) {
      return resourceCharacteristicType === ResourceCharacteristicTypeEnum.Cost ||
         resourceCharacteristicType === ResourceCharacteristicTypeEnum.Price ||
         resourceCharacteristicType === ResourceCharacteristicTypeEnum.Revenue;
   }

   public static isCurrencyIdentifier(resourceClassificationIdentifier: string) {
      const lowerCaseIdentifier = resourceClassificationIdentifier.toLowerCase();
      return lowerCaseIdentifier.indexOf('cost') > -1 ||
         lowerCaseIdentifier.indexOf('price') > -1||
         lowerCaseIdentifier.indexOf('revenue') > -1;
   }
}
