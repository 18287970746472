import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { LoggingService } from './logging.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/core/constants';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    constructor(private readonly injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {
        const notificationService = this.injector.get(NotificationService);
        const logger = this.injector.get(LoggingService);
        const translateService = this.injector.get(TranslateService);
        const loadingService = this.injector.get(LoadingService);
        loadingService.setLoading(false);
        loadingService.setSpinning(false);
        let message = this.getErrorMessage(error);

        logger.logError(message);

        if (this.isLoginError()) {
            console.log(JSON.stringify(error));
            return;
        }

        if (this.isUnauthorizedError(error)) {
            return;
        }

        translateService.stream('Shell.generic.errors.noInternet').subscribe(() => {
            if (!navigator.onLine) {
                message = translateService.instant('Shell.generic.errors.noInternet');
                notificationService.showError(message);
            } else if (this.isForbiddenError(error)) {
                message = translateService.instant('Shell.generic.errors.forbidden');
                notificationService.showError(message);
            } else if (this.isNotFoundError(error)) {
                message = translateService.instant('Shell.generic.errors.notFound');
                notificationService.showError(message);
            } else if (this.isGatewayTimeoutError(error)) {
              message = translateService.instant('Shell.generic.errors.gatewayTimeout');
              notificationService.showError(message);
            } else if (this.isBadGatewayError(error)) {
              message = translateService.instant('Shell.generic.errors.notFound');
              notificationService.showError(message);
            } else if (this.isBackendCustomError(error)) {
                notificationService.showBackendCustomError((error as any).error);
            } else {
                notificationService.showError(translateService.instant('Shell.generic.errors.genericErrorMessage'));
            }
        });
    }

    handleXMLHttpRequestError(xhr: XMLHttpRequest) {
        const error = new Error(xhr.response);
        const httpError = new HttpErrorResponse({ error, headers: null, status: xhr.status, statusText: xhr.statusText, url: xhr.responseURL });
        this.handleError(httpError);
    }

    private isLoginError(): boolean {
        let isLoginError = false;
        let b2cError = sessionStorage.getItem(Constants.LOGIN_ERROR_KEY);
        if (!b2cError) {
            b2cError = localStorage.getItem(Constants.LOGIN_ERROR_KEY);
        }
        if (b2cError) {
            isLoginError = b2cError.indexOf(Constants.B2C_RESET_PASSWORD_ERROR) > -1 || b2cError.indexOf(Constants.B2C_CANCEL_RESET_PASSWORD_ERROR) > -1;
        }
        return isLoginError;
    }

    private isUnauthorizedError(error: Error | HttpErrorResponse): boolean {
        const status = (error as HttpErrorResponse).status;
        return status === 401;
    }

    private isForbiddenError(error: Error | HttpErrorResponse): boolean {
        const status = (error as HttpErrorResponse).status;
        return status === 403;
    }

    private isNotFoundError(error: Error | HttpErrorResponse): boolean {
        const status = (error as HttpErrorResponse).status;
        return status === 404;
    }

    private isGatewayTimeoutError(error: Error | HttpErrorResponse): boolean {
      const status = (error as HttpErrorResponse).status;
        return status === 504;
    }

    private isBadGatewayError(error: Error | HttpErrorResponse): boolean {
      const status = (error as HttpErrorResponse).status;
        return status === 502;
    }

    private getErrorMessage(response): string {
        let message = response.message;
        if (response.error && response.error.customMessage) {
            message = response.error.customMessage;
        } else if (response.error) {
            try {
                const jsonError = JSON.parse(response.error);
                if (jsonError.customMessage) {
                    message = jsonError.customMessage;
                }
            } catch {
                message = response.error;
            }
        }
        return message;
    }

    private isBackendCustomError(response): boolean {
        return response.error && response.error.customMessage;
    }
}
