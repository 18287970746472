import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { Site } from '../models/site';
import { SiteApiModel } from '../models/siteApiModel';
import { SiteInformationApiModel } from 'src/app/growR/models/siteInformationApiModel';
import { EntityPeriodSettingsQueryDto } from '../models/entityPeriodSettingsQueryDto';
import { SiteOutsideInformationApiModel } from 'src/app/growR/models/siteOutsideInformationApiModel';
import { EntityPerformanceOverviewDto } from '../models/entityPerformanceOverviewDto';

@Injectable()
export class SiteService {
  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  getSites(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }

  getManualInputSites(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationManualInputSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }

  getManagerSitesWithViewStrategicDashboardPermission(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationManageRSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }

  getSite(siteId: number): Observable<SiteApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/${siteId}`;
    return this.httpClient.get<SiteApiModel>(url);
  }

  GetGrowRSiteInformation(siteOverviewSettings: EntityPeriodSettingsQueryDto): Observable<SiteInformationApiModel> {
    const settings = JSON.stringify(siteOverviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetGrowRSiteInformation?overviewSettings=${encodedQuery}`;
    return this.httpClient.get<SiteInformationApiModel>(url);
  }

  GetYieldSiteInformation(siteId: number): Observable<SiteInformationApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetYieldSiteInformation/${siteId}`;
    return this.httpClient.get<SiteInformationApiModel>(url);
  }

  getGrowerSitesWithViewStrategicDashboardPermission(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationGrowRSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }

  getYieldPredictionsSitesWithViewDashboardPermission(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationYieldPredictionsSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }

  getCO2OtimizerSitesWithViewDashboardPermission(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationCO2OptimizerSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }

  getGrowRSitesPerformanceOverview(organizationId: number): Observable<EntityPerformanceOverviewDto> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetGrowRUserSitesPerformanceOverviewAsync/${organizationId}`;
    return this.httpClient.get<EntityPerformanceOverviewDto>(url);
  }

  getGrowRSiteOutsideInformation(siteOverviewSettings: EntityPeriodSettingsQueryDto): Observable<SiteOutsideInformationApiModel> {
    const settings = JSON.stringify(siteOverviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetGrowRSiteOutsideInformation?settings=${encodedQuery}`;
    return this.httpClient.get<SiteOutsideInformationApiModel>(url);
  }
}

