import { Injectable, Injector } from '@angular/core';
import {
    HttpEvent, HttpRequest, HttpHandler,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Constants } from 'src/app/core/constants';
import { catchError, switchMap } from 'rxjs/operators';




@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private readonly injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError(error => {
                    if (error.status === 401) {
                        const authService = this.injector.get(AuthService);
                        if (authService.isTokenExpired()) {
                            return from(authService.refreshToken())
                                .pipe(switchMap(() => {
                                    const token = localStorage.getItem(Constants.ACCESS_TOKEN_KEY);
                                    if (token) {
                                        request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                                    }
                                    return next.handle(request);
                                }));
                        }
                        return throwError(error);
                    }
                    return throwError(error);
                })
            );
    }
}
