export class Constants {
  public static ACCESS_TOKEN_KEY = 'msal.idtoken';
  public static RIDDER_EMAIL_ADDRESS_DOMAIN = 'ridder'
  public static LOGIN_ERROR_KEY = 'msal.login.error';
  public static LOGIN_RETURN_URL_KEY = 'login.return.url';
  public static B2C_RESET_PASSWORD_ERROR = 'AADB2C90118';
  public static B2C_CANCEL_RESET_PASSWORD_ERROR = 'AADB2C90091';

  public static API_REQUEST_CONTENT_TYPE = { 'Content-Type': 'application/json' };
  public static MANDATORY_FIELD_TRANSLATION_KEY = 'Shell.generic.mandatoryField';
  public static CROP_CYCLE_TEMPLATE_TRANSLATION_KEY = 'Shell.cropCycleTemplates.cropCycleTemplate';
  public static TYPE_REQUIRED_RULE = 'required';
  public static ORGANIZATIONS_SETTINGS = 'organizations-settings';
  public static MANAGER = 'manager';
  public static GROWER = 'grower';
  public static PLATFORM_ADMINISTRATION = 'platform-administration';
  public static YIELD_PREDICTIONS = 'yield';
  public static PROFILE_SETTINGS = 'profile-settings';

  public static ON_TARGET_INDEX_VALUE = 100;

  public static LAST_4WEEKS_PERIOD_OF_THE_YEAR_START_WEEK = 49;

  public static CREATE_OPERATION_TRANSLATION_KEY = 'Shell.generic.operations.create';
  public static EDIT_OPERATION_TRANSLATION_KEY = 'Shell.generic.operations.edit';

  public static NUMBER_OF_HOURS_IN_A_WEEK = 168;

  public static FULL_HD_WIDTH = 1920;
  public static OLDER_TABLETS_WIDTH = 1280;
  public static IPAD_MINI_WIDTH = 1024;

  public static dropDownButtonControlWidth = '11rem';
  public static dropDownButtonControlItemListWidth = '24rem';

  public static NEGATIVE_PERFORMANCE_CLASS = 'negative-performance';
  public static POSITIVE_PERFORMANCE_CLASS = 'positive-performance';
  public static YELLOW_PERFORMANCE_CLASS = 'yellow-performance';
  public static NEUTRAL_PERFORMANCE_CLASS = 'neutral-performance';
  public static UNDETERMINED_PERFORMANCE_CLASS = 'undetermined-performance';
  public static PI_THRESHOLD = 90;

  public static CORRECTABLE_RESOURCE_CLASSIFICATION_IDENTIFIER = 'CropGrowth-Cumulative-Daily';
  public static NUMBER_OF_DECIMALS_IN_MANUAL_INPUT = 5;

  public static FULL_SCREEN_ANIMATION_SHOW ={
      type: 'pop',
      from: { scale: 0, opacity: 0 },
      to: { scale: 1, opacity: 1 },
      duration: 200
  };

  public static FULL_SCREEN_ANIMATION_HIDE = {
      type: 'pop',
      from: { scale: 1, opacity: 1 },
      to: { scale: 0, opacity: 0 },
      duration: 200
  };
}

