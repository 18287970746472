import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from './configuration/services/configuration.service';
import config from 'devextreme/core/config'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private readonly configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    if (this.configurationService.configuration.environment.includes('Prod') && this.configurationService.configuration.googleAnalyticsMeasurementId) {
      // dynamically add analytics scripts to document head
      try {
        const url = 'https://www.googletagmanager.com/gtag/js?id=';
        const gTagScript = document.createElement('script');
        gTagScript.async = true;
        gTagScript.src = `${url}${this.configurationService.configuration.googleAnalyticsMeasurementId}`;
        document.head.appendChild(gTagScript);

        const dataLayerScript = document.createElement('script');
        dataLayerScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());`
        document.head.appendChild(dataLayerScript);
      } catch (e) {
        console.error('Error adding Google Analytics', e);
      }
    }
     
    config({ licenseKey: this.configurationService.configuration.devextremeLicenseKey });   
  }
}
