import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { EnumApiModel } from 'src/app/core/models/enumApiModel';
import { OrganizationLicenseModule } from '../models/organizationLicenseModule';
import { OrganizationLicenseModuleType } from '../enums/organizationLicenseModuleType';

@Injectable()
export class OrganizationLicenseService {
    private readonly configuration: ClientConfiguration;

    constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
        this.configuration = configurationService.configuration;
    }

    getOrganizationModuleLicenses(): Observable<OrganizationLicenseModule[]> {
        const url = `${this.configuration.platformAdministrationApiBaseUrl}/organization-license/GetOrganizationModuleLicenses`;
        return this.httpClient.get<OrganizationLicenseModule[]>(url);
    }

    getOrganizationLicenseModuleTypes(): Observable<EnumApiModel[]> {
        const url = `${this.configuration.platformAdministrationApiBaseUrl}/organization-license/GetOrganizationLicenseModuleTypes`;
        return this.httpClient.get<EnumApiModel[]>(url);
    }

    getOrganizationLicenses(organizationId: number): Observable<OrganizationLicenseModule[]> {
      const url = `${this.configuration.platformAdministrationApiBaseUrl}/organization-license/GetOrganizationModuleLicenses/${organizationId}`;
      return this.httpClient.get<OrganizationLicenseModule[]>(url);
    }

    updateOrganizationModuleLicenses(organizationModuleLicenses: OrganizationLicenseModule[]) {
        const url = `${this.configuration.platformAdministrationApiBaseUrl}/organization-license/UpdateOrganizationModuleLicenses`;
        return this.httpClient.post(url, organizationModuleLicenses);
    }

    hasLicenseForOrganizationModules(organizationId: number, organizationModules: OrganizationLicenseModuleType[]): Observable<boolean> {
        const url = `${this.configuration.platformAdministrationApiBaseUrl}/organization-license/HasLicenseForOrganizationModules/${organizationId}`;
        return this.httpClient.post<boolean>(url, organizationModules);
    }

    getOrganizationActiveLicensedModules(organizationId: number): Observable<OrganizationLicenseModule[]> {
        if (organizationId !== undefined) {
            const url = `${this.configuration.platformAdministrationApiBaseUrl}/organization-license/GetOrganizationActiveLicensedModules/${organizationId}`;
            return this.httpClient.get<OrganizationLicenseModule[]>(url);
        }

        return of<OrganizationLicenseModule[]>([]);
    }
}
