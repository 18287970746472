
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IsoCountry } from 'src/app/core/models/isoCountry';
import { COUNTRIES } from 'src/app/core/data/isoCountriesData';
import { TimeZoneApiModel } from 'src/app/core/models/timeZoneApiModel';
import { EntityPeriodSettingsQueryDto } from '../models/entityPeriodSettingsQueryDto';
import { EntityApiModel } from 'src/app/core/models/entityApiModel';
import { EntityExtendedPeriodSettingsQueryDto } from '../models/entityExtendedPeriodSettingsQueryDto';
import { EntityIndexDto } from '../models/entityIndexDto';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { Site } from '../models/site';
import { Organization } from 'src/app/manager/models/organization';
import { OrganizationUserGroupOverviewsApiModel } from '../models/organizationUserGroupOverviewsApiModel';

@Injectable()
export class OrganizationService {
  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  getCountries(): IsoCountry[] {
    return COUNTRIES;
  }

  getTimeZones(): Observable<TimeZoneApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/timezones`;
    return this.httpClient.get<TimeZoneApiModel[]>(url);
  }

  getUserOrganizations(): Observable<EntityApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/getuserorganizations`;
    return this.httpClient.get<EntityApiModel[]>(url);
  }

  getSites(organizationId: number): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationSites/${organizationId}`;
    return this.httpClient.get<Site[]>(url);
  }

  getUserAdministratedOrganizations(): Observable<EntityApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/GetAdministratedOrganizations`;
    return this.httpClient.get<EntityApiModel[]>(url);
  }

  getOrganization(organizatonId: number): Observable<EntityApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/getorganization/` + organizatonId;
    return this.httpClient.get<EntityApiModel>(url);
  }

  getOrganizations(): Observable<Organization[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/getorganizationsoverview`;
    return this.httpClient.get<Organization[]>(url);
  }

  getAverageCostPerSquareMeter(siteOverviewSettings: EntityPeriodSettingsQueryDto): Observable<any> {
    const settings = JSON.stringify(siteOverviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');

    // tslint:disable-next-line: max-line-length
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/GetOrganizationAverageCostPerSquareMeter?overviewSettings=${encodedQuery}`;
    return this.httpClient.get(url);
  }

  getOrganizationAverageCostPerArea(siteOverviewSettings: EntityPeriodSettingsQueryDto): Observable<any> {
    const settings = JSON.stringify(siteOverviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');

    // tslint:disable-next-line: max-line-length
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/GetOrganizationAverageCostPerArea?overviewSettings=${encodedQuery}`;
    return this.httpClient.get(url);
  }

  getOrganizationIndex(siteOverviewSettings: EntityExtendedPeriodSettingsQueryDto): Observable<EntityIndexDto> {
    const settings = JSON.stringify(siteOverviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');

    const url = `${this.configuration.resourceApiBaseUrl}/organizationoverview/index?overviewSettings=${encodedQuery}`;
    return this.httpClient.get<EntityIndexDto>(url);
  }

  getOrganizationCostDeviationGraph(siteOverviewSettings: EntityPeriodSettingsQueryDto): Observable<any> {
    const settings = JSON.stringify(siteOverviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');

    // tslint:disable-next-line: max-line-length
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/GetOrganizationCostDeviationGraph?overviewSettings=${encodedQuery}`;
    return this.httpClient.get(url);
  }

  isUserAdministratorOfAnyOrganization() {
    return this.httpClient.get<boolean>(`${this.configuration.organizationsApiBaseUrl}/organizations/IsUserAdministratorOfAnyOrganization`);
  }

  isUserOwnerOfOrganization(organizationId) {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/IsUserOwnerOfOrganization/` + organizationId;
    return this.httpClient.get<boolean>(url);
  }

  isUserMemberOfOrganization(organizationId) {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/IsUserMemberOfOrganization/` + organizationId;
    return this.httpClient.get<boolean>(url);
  }

  getSitesForBudgets(organizationId: number,isContainerEntity: boolean): Observable<Site[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/GetOrganizationSitesForBudgets/${organizationId}/${isContainerEntity}`;
    return this.httpClient.get<Site[]>(url);
  }

  getOrganizationUserGroups(id: number): Observable<OrganizationUserGroupOverviewsApiModel> {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/getorganizationusergroups/${id}`;
    return this.httpClient.get<OrganizationUserGroupOverviewsApiModel>(url);
  }
}

