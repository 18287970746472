import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly errorHandlerService: ErrorHandlerService) { }

  handleError(error: Error | HttpErrorResponse) {
    this.errorHandlerService.handleError(error);
  }
}
