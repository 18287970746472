import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from "@ngx-translate/core";
import { from, Observable } from 'rxjs';
import { ConfigurationService } from '../../configuration/services/configuration.service';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { switchMap } from 'rxjs/operators';

export class TranslationsLoader implements TranslateLoader {
  private readonly _httpClient: HttpClient;
  private _configuration: ClientConfiguration;

  constructor(httpClient: HttpClient,private  configurationService: ConfigurationService) {
    this._httpClient = httpClient;
    this._configuration = configurationService.configuration
  }

  public getTranslation(lang: string): Observable<string[]> {
    if (!this._configuration)
    {
      return from(this.configurationService.load()).pipe(switchMap(() => {
        this._configuration = this.configurationService.configuration;
        return this._httpClient.get<string[]>(`${this._configuration.translationsApiBaseUrl}/translations/shell/${lang}`)}
      ));
    }
    return this._httpClient.get<string[]>(`${this._configuration.translationsApiBaseUrl}/translations/shell/${lang}`);
  }
}
