export enum ResourceCharacteristicTypeEnum {
  Area = 0,
  Cost = 1,
  Duration = 2,
  InsideMeasurement = 3,
  MeterReading = 4,
  Price = 5,
  Usage = 6,
  Weight = 7,
  Production = 8,
  Revenue = 9,
  UsagePerProduction = 10,
  Amount = 11,
  Volume = 12,
  Diameter = 13,
  Size = 14,
  Length = 15,
  Distance = 16,
  OutsideMeasurement = 17,
  ElectricConductivity = 18,
  pH = 19,
  WaterContent = 20,
  Growth = 21,
  Count = 22,
  Load = 23,
  Position = 24,
  Dryout = 25,
  Completed = 26,
  Temperature = 27
}
