import { Injectable } from "@angular/core";
import { ComparisonToolGraphTimestampFormattingType } from "../enums/comparisonToolGraphTimestampFormattingType";
import { SavedViewTimeFrame } from "../enums/savedViewTimeFrame";
import { FormattingService } from "../services/formatting.service";

@Injectable()
export class ComparisonPeriodFormatterHelper {

  constructor(private readonly formattingService: FormattingService){}

  getComparisonToolGraphTimestampFormattingType(timestamp: string, savedViewTimeFrame: SavedViewTimeFrame): ComparisonToolGraphTimestampFormattingType {
    let comparisonToolLineGraphTimestampFormattingType : ComparisonToolGraphTimestampFormattingType;
    const customPeriodMinFragmentsCount = 2;

    if (savedViewTimeFrame === SavedViewTimeFrame.Absolute) {
      comparisonToolLineGraphTimestampFormattingType = ComparisonToolGraphTimestampFormattingType.Date;

      if (timestamp.includes(':')){
        comparisonToolLineGraphTimestampFormattingType = ComparisonToolGraphTimestampFormattingType.Datetime;
      } else if ((timestamp.split(" ").length) - 1 >= customPeriodMinFragmentsCount) {
        comparisonToolLineGraphTimestampFormattingType = ComparisonToolGraphTimestampFormattingType.Custom;
      }
    } else {
      comparisonToolLineGraphTimestampFormattingType = ComparisonToolGraphTimestampFormattingType.Custom;
    }

    return comparisonToolLineGraphTimestampFormattingType;
  }

  getValuesTableTimestampColumnDataType(comparisonToolLineGraphTimestampFormattingType: ComparisonToolGraphTimestampFormattingType): string {
    return comparisonToolLineGraphTimestampFormattingType === ComparisonToolGraphTimestampFormattingType.Custom ? 'string' : 'date';
  }

  getValuesTableTimestampColumnFormatType(comparisonToolLineGraphTimestampFormattingType: ComparisonToolGraphTimestampFormattingType): string {
    if (comparisonToolLineGraphTimestampFormattingType === ComparisonToolGraphTimestampFormattingType.Date) {
      return this.formattingService.selectedDateFormatChanged$.getValue();
    } else if (comparisonToolLineGraphTimestampFormattingType === ComparisonToolGraphTimestampFormattingType.Datetime) {
      return `${this.formattingService.selectedDateFormatChanged$.getValue()}  HH:mm`;
    }

    return undefined;
  }
}