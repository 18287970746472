export enum NewPeriodSelectorInterval {
  Day = 1,
  Week = 2,
  FourWeek = 3,
  Month = 4,
  Quarter = 5,
  Year = 6,
  CropWeek = 7,
  ToDate = 8,
  CropToDate = 9,
  Full = 10,
  Custom = 11,
}
