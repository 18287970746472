import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Injectable } from "@angular/core";

import { map } from "rxjs/internal/operators/map";
import { OnboardingStatusService } from "src/app/onboarding/services/onboarding-status.service";

@Injectable()
export class OrganizationsOnboardedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly onboardingStatusService: OnboardingStatusService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const organizationId = +route.params.organizationId;
    return this.onboardingStatusService
      .isOrganizationOnboardingCompleted(organizationId)
      .pipe(
        map((result) => {
          if (!result) {
            this.router.navigateByUrl(`organizations/${organizationId}/onboarding/organization-onboarding-overview`);
            return false;
          }
          return true;
        })
      );
  }
}
