import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PermissionService } from './permission.service';
import { map } from 'rxjs/operators';
import { PermissionRequiringEntity } from '../models/permissionRequiringEntity';
import { PermissionType } from '../models/permissionType';

@Injectable()
export class EntityGuard implements CanActivate {
    constructor(private readonly permissionService: PermissionService, private readonly router: Router) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const entityType = activatedRoute.data.entityType as PermissionRequiringEntity;
        const entityIdparamName = activatedRoute.data.entityIdParamName;
        const entityId = +activatedRoute.paramMap.get(entityIdparamName);
        const permissionTypes = activatedRoute.data.permissionTypes as PermissionType[];
        return this.permissionService.hasUserAnyPermissionForEntity(entityType, entityId, permissionTypes).pipe(
            map(hasPermission => {
                if (hasPermission) {
                    return true;
                }
                this.router.navigateByUrl('/');
                return false;
            })
        );
    }
}