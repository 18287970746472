import { NgModule } from '@angular/core';

import { ConfigurationService } from './services/configuration.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    ConfigurationService
  ],
})
export class ConfigurationModule {}
