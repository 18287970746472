import { KeyValuePair } from "./../models/keyValuePair";
import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class ExcelService {
  public exportAsExcelFile(
    json: any[],
    displayNames: KeyValuePair[],
    excelFileName: string,
    excelSheetName: string
  ) {
    excelSheetName = this.ensureSheetNameIsValid(excelSheetName);
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet = this.updateHeaderValues(worksheet, displayNames);

    const workbook: XLSX.WorkBook = {
      Sheets: { [excelSheetName] : worksheet },
      SheetNames: [excelSheetName],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + "_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  ensureSheetNameIsValid(excelSheetName : string): string{
    // Sheet names cannot exceed 31 chars
    if(excelSheetName.length < 31){
      return excelSheetName;
    } else {
      return excelSheetName.substring(0,30);
    }
  }

  private updateHeaderValues(
    worksheet: XLSX.WorkSheet,
    displayNames: KeyValuePair[]
  ) {
    if (displayNames.length > 0) {
      var range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (var c = range.s.c; c <= range.e.c; ++c) {
        var address = XLSX.utils.encode_col(c) + "1"; // <--column number C, first row
        if (worksheet[address]) {
          const displayName = displayNames.find(
            (d) => d.key === worksheet[address].v
          );
          if (displayName !== undefined) {
            worksheet[address].v = displayName.value;
          }
        }
      }
    }

    return worksheet;
  }
}
