import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { NotificationTypeEnum } from 'src/app/core/enums/notificationTypeEnum';
import { NotificationModel } from '../models/notificationModel';
import { GuidService } from './guid.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly showNotification$ = new ReplaySubject<NotificationModel>(1);
  private readonly closeNotifications$ = new Subject<boolean>();

  constructor() {}

  showSuccess(message: string): void {
    const success = new NotificationModel();
    success.id = GuidService.newGuid();
    success.title = 'Shell.generic.success';
    success.message = message;
    success.type = NotificationTypeEnum.Success;
    this.showNotification$.next(success);
  }

  showError(message: string): void {
    const error = new NotificationModel();
    error.id = GuidService.newGuid();
    error.title = 'Shell.generic.error';
    error.message = message;
    error.type = NotificationTypeEnum.Error;
    this.showNotification$.next(error);
  }

  showWarning(message: string): void {
    const warning = new NotificationModel();
    warning.id = GuidService.newGuid();
    warning.title = 'Shell.generic.warning';
    warning.message = message;
    warning.type = NotificationTypeEnum.Warning;
    this.showNotification$.next(warning);
  }

  showBackendCustomError(customError: any) {
    const error = new NotificationModel();
    error.id = GuidService.newGuid();
    error.title = 'Shell.generic.error';
    error.translationKey = customError.customMessage;
    error.requiresTranslation = true;
    error.translationParams = customError.messageParams;
    error.type = NotificationTypeEnum.Error;
    this.showNotification$.next(error);
  }

  getNotifications() {
    return this.showNotification$.asObservable();
  }

  closeNotifications(closeNotifications: boolean) {
    return this.closeNotifications$.next(closeNotifications);
  }

  shouldCloseNotifications() {
    return this.closeNotifications$.asObservable();
  }
}
