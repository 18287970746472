import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { OpenAssignmentQueryApiModel } from '../../tasks/models/openAssignmentQueryApiModel';
import { Assignment } from '../models/assignment.model';
import { CompleteAssignmentRequest } from '../models/completeAssignmentRequest';
import { UserGroup } from '../models/user-group.model';

@Injectable()
export class TasksService {
  private readonly _httpClient: HttpClient;
  private readonly _configuration: ClientConfiguration;

  constructor(httpClient: HttpClient, configurationService: ConfigurationService) {
    this._httpClient = httpClient;
    this._configuration = configurationService.configuration;
  }

  public retrieveUserGroups(): Observable<UserGroup[]> {
    return this._httpClient.get<UserGroup[]>(`${this._configuration.socialApiBaseUrl}/assignments/user-groups`);
  }

  public retrieveAdminUserGroups(): Observable<UserGroup[]> {
    return this._httpClient.get<UserGroup[]>(`${this._configuration.socialApiBaseUrl}/assignments/user-admins-groups`);
  }

  public retrieveOpenAssignments(queryApiModel: OpenAssignmentQueryApiModel): Observable<Assignment[]> {
    const groupIds = JSON.stringify(queryApiModel);
    let encodedQuery = encodeURI(groupIds);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this._httpClient.get<Assignment[]>(`${this._configuration.socialApiBaseUrl}/assignments/openAssignmentForUserGroups?query=${encodedQuery}`);
  }

  public retrieveUsersOpenAssignments(organizationId): Observable<Assignment[]> {
    return this._httpClient.get<Assignment[]>(`${this._configuration.socialApiBaseUrl}/assignments/RetrieveOpenAssignmentsForUser/${organizationId}`);
  }

  public retrieveOpenAssignmentCount(organizationId: number): Observable<number> {
    return this._httpClient.get<number>(`${this._configuration.socialApiBaseUrl}/assignments/RetrieveOpenAssignmentCountForUser/${organizationId}`);
  }

  public retrieveOpenAssignment(taskId: number): Observable<Assignment> {
    return this._httpClient.get<Assignment>(`${this._configuration.socialApiBaseUrl}/assignments/${taskId}`);
  }

  public saveAssignment(assignment: Assignment): Observable<Assignment> {
    return this._httpClient.post<Assignment>(`${this._configuration.socialApiBaseUrl}/assignments/save`, assignment);
  }

  public completeAssignment(assignment: CompleteAssignmentRequest): Observable<void> {
    return this._httpClient.put<void>(`${this._configuration.socialApiBaseUrl}/assignments/complete`, assignment);
  }

  public delete(assignmentId: number) {
    const url = `${this._configuration.socialApiBaseUrl}/assignments/${assignmentId}`;
    return this._httpClient.delete(url);
  }

  public isUserAdministratorOfAssignment(assignmentId: number) {
    return this._httpClient.get<boolean>(`${this._configuration.socialApiBaseUrl}/assignments/IsUserAdministratorOfAssignment/${assignmentId}`);
  }
}
