
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { EntityIdListQueryApiModel } from '../models/entityIdListQueryApiModel';
import { EnumApiModel } from '../models/enumApiModel';
import { Observable } from 'rxjs';
import { GrowRAllIndicatorsQueryApiModel } from 'src/app/growR/models/growRAllIndicatorsQueryApiModel';
import { GrowRAllIndicatorsResultApiModel } from 'src/app/growR/models/growRAllIndicatorsResultApiModel';
import { PeriodInterval } from '../models/periodInterval';
import { EntityIdListPeriodQueryApiModel } from '../models/entityIdListPeriodQueryApiModel';
import { CropCycleInformationApiModel } from 'src/app/core/models/cropCycleInformationApiModel';
import { GrowrCropCycleResourceIndicatorApiDetailsModel } from 'src/app/growR/models/growrCropCycleResourceIndicatorDetailsApiModel';
import { GrowRCropCycleResourceIndicatorQueryApiModel } from 'src/app/growR/models/growRCropCycleResourceIndicatorQueryApiModel';
import { CropCycleThemeQuickOverviewApiModel } from 'src/app/performance-indicator/models/cropCycleThemeQuickOverviewApiModel';
import { CropCycleThemesOverviewPagerResultApiModel } from 'src/app/performance-indicator/models/cropCycleThemesOverviewApiModel';
import { GrowREntityPeriodSettingsQueryApiModel } from 'src/app/growR/models/growREntityPeriodSettingsQueryApiModel';
import { OverviewPeriodSettingsQueryApiModel } from 'src/app/core/models/query/overviewPeriodSettingsQueryApiModel';
import { EntityPeriodSettingsQueryDto } from '../models/entityPeriodSettingsQueryDto';
import { OrganizationLicenseModuleType } from '../enums/organizationLicenseModuleType';
import { CropType } from '../enums/cropType';
import { CropCycle } from '../models/cropCycles/cropCycle';
import { CropCycleIntervalApiModel } from '../models/cropCycles/cropCycleIntervalApiModel';
import { CropCycleApiModel } from '../models/cropCycles/cropCycleApiModel';
import { CropCycleDetailedApiModel } from '../models/cropCycles/cropCycleDetailedApiModel';
import { CropCyclesOverviewApiModel } from '../models/cropCycles/cropCyclesOverviewApiModel';
import { CropCycleQueryDto } from '../models/cropCycles/cropCycleQueryDto';
import { CropCycleDto } from '../models/cropCycles/cropCycleDto';
import { YieldOverviewSettingsQueryApiModel } from 'src/app/yield-predictions/models/yieldOverviewSettingsQueryApiModel';
import { CropCycleForecastOverviewPagerResultApiModel } from 'src/app/yield-predictions/models/cropCycleForecastOverviewPagerResultApiApiModel';
import { OverviewEntitySettingsQueryApiModel } from 'src/app/yield-predictions/models/overviewEntitySettingsQueryApiModel';
import { CropCycleOverviewPagerResultApiModel } from '../models/cropCycleOverview/cropCycleOverviewPagerResultApiModel';
import { YieldResourceIndicatorDetailsQueryApiModel } from 'src/app/yield-predictions/models/YieldResourceIndicatorDetailsQueryApiModel';
import { CropCycleGraphIndicatorDetailsApiModel } from '../models/cropCycleOverview/CropCycleGraphIndicatorDetailsApiModel';
import { CropTypeSettingsQueryApiModel } from '../models/query/cropTypeSettingsQueryApiModel';
import { CropCyclesSettingsQueryApiModel } from '../models/query/cropCyclesSettingsQueryApiModel';
import { CropPerformanceOverviewApiModel } from 'src/app/yield-predictions/models/cropPerformanceOverviewApiModel';

@Injectable()
export class CropCycleService {

  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  GetCropCyclesIntervals(ids: number[]) {
    const query = new EntityIdListQueryApiModel();
    query.ids = ids;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleIntervalApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetCropCyclesIntervals?cropCycleIds=${encodedQuery}`
    );
  }

  getCropCycle(id: number) {
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetCropCycle/${id}`;
    return this.httpClient.get<CropCycleApiModel>(url);
  }

  getCropCyclesBySiteIds(siteIds: number[]) {
    const query = new EntityIdListQueryApiModel();
    query.ids = siteIds;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetCropCyclesBySiteIds?siteIds=${encodedQuery}`
    );
  }

  getManualInputCropCyclesBySiteIds(siteIds: number[]) {
    const query = new EntityIdListQueryApiModel();
    query.ids = siteIds;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetManualInputCropCyclesBySiteIds?siteIds=${encodedQuery}`
    );
  }

  getProjectionsActiveCropCyclesBySiteIds(siteIds: number[], periodInterval: PeriodInterval) {
    const query = new EntityIdListPeriodQueryApiModel();
    query.ids = siteIds;
    query.period = periodInterval;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetActiveProjectionsCropCyclesBySiteIds?query=${encodedQuery}`
    );
  }

  getGrowerActiveCropCyclesBySiteIds(siteIds: number[], periodInterval: PeriodInterval) {
    const query = new EntityIdListPeriodQueryApiModel();
    query.ids = siteIds;
    query.period = periodInterval;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetGrowerActiveCropCyclesBySiteIds?query=${encodedQuery}`
    );
  }

  getGrowerActiveCropCycles(query: CropCyclesSettingsQueryApiModel) {
    let encodedQuery = encodeURI(JSON.stringify(query));
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetGrowerActiveCropCycles?query=${encodedQuery}`
    );
  }

  getAggregationPeriods(): Observable<EnumApiModel[]> {
    const url = `${this.configuration.resourceApiBaseUrl}/cropCycles/GetAggregationPeriods`;
    return this.httpClient.get<EnumApiModel[]>(url);
  }

  getThemesByCropCycleIds(cropCycleIds: number[]): Observable<EnumApiModel[]> {
    const query = new EntityIdListQueryApiModel();
    query.ids = cropCycleIds;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropCycles/GetThemesByCropCycleIds?cropCycleIds=${encodedQuery}`;
    return this.httpClient.get<EnumApiModel[]>(url);
  }

  getAllGrowRIndicators(query: GrowRAllIndicatorsQueryApiModel): Observable<GrowRAllIndicatorsResultApiModel> {
    let encodedQuery = encodeURI(JSON.stringify(query));
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropCycles/GetAllGrowRIndicators?query=${encodedQuery}`;
    return this.httpClient.get<GrowRAllIndicatorsResultApiModel>(url);
  }

  // tslint:disable-next-line: max-line-length
  getGrowRResourceQuickOverview(overviewSettings: GrowREntityPeriodSettingsQueryApiModel): Observable<CropCycleOverviewPagerResultApiModel> {
    const settings = JSON.stringify(overviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetGrowREntityResourceQuickOverview?settings=${encodedQuery}`;
    return this.httpClient.get<CropCycleOverviewPagerResultApiModel>(url);
  }

  getGrowRCropCycleThemesOverview(overviewSettings: EntityPeriodSettingsQueryDto): Observable<CropCycleThemesOverviewPagerResultApiModel> {
    const settings = JSON.stringify(overviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetGrowRCropCycleThemesOverview?settings=${encodedQuery}`;
    return this.httpClient.get<CropCycleThemesOverviewPagerResultApiModel>(url);
  }

  // tslint:disable-next-line: max-line-length
  getGrowRCropCycleThemeQuickOverview(overviewSettings: EntityPeriodSettingsQueryDto): Observable<CropCycleThemeQuickOverviewApiModel> {
    const settings = JSON.stringify(overviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetGrowRCropCycleThemeQuickOverview?settings=${encodedQuery}`;
    return this.httpClient.get<CropCycleThemeQuickOverviewApiModel>(url);
  }

  getYieldCropCycleThemeQuickOverview(overviewSettings: OverviewEntitySettingsQueryApiModel): Observable<CropCycleThemeQuickOverviewApiModel> {
    const settings = JSON.stringify(overviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.yieldPredictionsApiBaseUrl}/crop-cycle-overview/GetYieldCropCycleThemeQuickOverview?settings=${encodedQuery}`;
    return this.httpClient.get<CropCycleThemeQuickOverviewApiModel>(url);
  }

  
  getCropPerformanceOverviewAsync(overviewSettings: OverviewEntitySettingsQueryApiModel): Observable<CropPerformanceOverviewApiModel> {
    const settings = JSON.stringify(overviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.yieldPredictionsApiBaseUrl}/crop-cycle-overview/GetCropPerformanceOverviewAsync?settings=${encodedQuery}`;
    return this.httpClient.get<CropPerformanceOverviewApiModel>(url);
  }

  getCropCycleResourceIndicatorDetails(detailsSettings: YieldResourceIndicatorDetailsQueryApiModel): Observable<CropCycleGraphIndicatorDetailsApiModel> {
    const settings = JSON.stringify(detailsSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.yieldPredictionsApiBaseUrl}/crop-cycle-overview/GetCropCycleResourceIndicatorDetails?settings=${encodedQuery}`;
    return this.httpClient.get<CropCycleGraphIndicatorDetailsApiModel>(url);
  }

  // tslint:disable-next-line: max-line-length
  getGrowRQuickOverview(overviewSettings: OverviewPeriodSettingsQueryApiModel): Observable<CropCycleOverviewPagerResultApiModel> {
    const settings = JSON.stringify(overviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetGrowRQuickOverview?settings=${encodedQuery}`;
    return this.httpClient.get<CropCycleOverviewPagerResultApiModel>(url);
  }
  getYieldQuickOverview(filter: YieldOverviewSettingsQueryApiModel): Observable<CropCycleForecastOverviewPagerResultApiModel> {
    const settings = JSON.stringify(filter);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.yieldPredictionsApiBaseUrl}/crop-cycle-overview/GetYieldQuickOverview?settings=${encodedQuery}`;
    return this.httpClient.get<CropCycleForecastOverviewPagerResultApiModel>(url);
  }

  getGrowRCropCycleInformation(id: number): Observable<CropCycleInformationApiModel> {
    const url = `${this.configuration.resourceApiBaseUrl}/cropCycles/GetGrowRCropCycleInformation/${id}`;
    return this.httpClient.get<CropCycleInformationApiModel>(url);
  }

  getOrganizationCropTypes(filter: CropTypeSettingsQueryApiModel): Observable<EnumApiModel[]> {
    const cropTypeQueryParams = JSON.stringify(filter);
    let encodedQuery = encodeURI(cropTypeQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/getOrganizationCropTypes?settings=${encodedQuery}`;
    return this.httpClient.get<EnumApiModel[]>(url);
  }

  getGrowRCropCycleResourceIndicatorDetails(querySettings: GrowRCropCycleResourceIndicatorQueryApiModel) {
    const settings = JSON.stringify(querySettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    const url = `${this.configuration.resourceApiBaseUrl}/cropCycles/GetGrowRCropCycleCropCycleResourceIndicatorDetails?settings=${encodedQuery}`;
    return this.httpClient.get<GrowrCropCycleResourceIndicatorApiDetailsModel>(url);
  }

  getAllOrganizationCropCycles(organizationId: number, includeSiteName = false): Observable<CropCycleApiModel[]> {
    const url = `${this.configuration.resourceApiBaseUrl}/cropCycles/getAllOrganizationCropCycles/${organizationId}/${includeSiteName}`;
    return this.httpClient.get<CropCycleApiModel[]>(url);
  }

  getAllOrganizationDetailedCropCycles(organizationId: number): Observable<CropCycleDetailedApiModel[]> {
    const url = `${this.configuration.resourceApiBaseUrl}/cropCycles/getAllOrganizationCropCycles/${organizationId}/detailed`;
    return this.httpClient.get<CropCycleDetailedApiModel[]>(url);
  }

  getUserGrowRActiveCropCyclesOverview(organizationId: number): Observable<CropCyclesOverviewApiModel[]> {
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetUserGrowRActiveCropCyclesOverview/${organizationId}`;
    return this.httpClient.get<CropCyclesOverviewApiModel[]>(url);
  }

  getUserManagerActiveCropCyclesOverview(organizationId: number): Observable<CropCyclesOverviewApiModel[]> {
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetUserManagerActiveCropCyclesOverview/${organizationId}`;
    return this.httpClient.get<CropCyclesOverviewApiModel[]>(url);
  }

   // tslint:disable-next-line: max-line-length
   getActiveCropCycles(
    periodStartDate: any,
    periodEndDate: any,
    organizationId: number,
    module = OrganizationLicenseModuleType.Manager,
    siteId: number = null,
    crop: CropType = CropType.Unknown,
    searchText: string = null
  ): Observable<CropCycle[]> {
    const queryParams = new CropCycleQueryDto();
    queryParams.crop = crop;
    queryParams.organizationId = organizationId;
    queryParams.siteId = siteId;
    queryParams.periodStartDate = periodStartDate;
    queryParams.periodEndDate = periodEndDate;
    queryParams.searchText = searchText;
    queryParams.module = module;

    const cropCycleQueryParams = JSON.stringify(queryParams);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get<CropCycle[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetUserActiveCropCycles?cropCycleQueryParams=${encodedQuery}`
    );
  }

  GetActiveCropCyclesByOrganizationForBudgets(OverviewSettings: EntityPeriodSettingsQueryDto): Observable<CropCycleDto[]> {
    const settings = JSON.stringify(OverviewSettings);
    let encodedQuery = encodeURI(settings);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');

    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetActiveCropCyclesByOrganizationForBudgets?cropCycleSettings=${encodedQuery}`;
    return this.httpClient.get<CropCycleDto[]>(url);
  }

  getCultivarCropCyclesBySiteIds(siteIds: number[]) {
    const query = new EntityIdListQueryApiModel();
    query.ids = siteIds;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetCultivarCropCyclesBySiteIds?siteIds=${encodedQuery}`
    );
  }

  getCO2OptimizerActiveCropCyclesBySiteIds(siteIds: number[]) {
    const query = new EntityIdListQueryApiModel();
    query.ids = siteIds;
    const cropCycleQueryParams = JSON.stringify(query);
    let encodedQuery = encodeURI(cropCycleQueryParams);
    encodedQuery = encodedQuery.replace(/\+/g, '%2B');
    return this.httpClient.get<CropCycleApiModel[]>(
      `${this.configuration.resourceApiBaseUrl}/cropcycles/GetCO2OptimizerActiveCropCyclesBySiteIds?siteIds=${encodedQuery}`
    );
  }


}
