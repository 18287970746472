import { IsoLanguage } from '../models/isoLanguage';

export const LANGUAGES: IsoLanguage[] = [
  {
    code: 'zh-Hans',
    name: '漢語',
    id: 0
  },
  {
    code: 'en-US',
    name: 'English',
    id: 1
  },
  {
    code: 'nl-NL',
    name: 'Nederlands',
    id: 2
  },
  {
    code: 'fr-FR',
    name: 'Français',
    id: 3
  },
  {
    code: 'es-MX',
    name: 'Mexicano',
    id: 4
  },
  {
    code: 'es-ES',
    name: 'Español',
    id: 5
  },
  {
    code: 'ru-RU',
    name: 'Русский',
    id: 6
  }
];
