import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loadingStateChanged$ = new Subject<{ loading: boolean }>();
  loadingState: boolean;

  public spinningStateChanged$ = new Subject<{ spinning: boolean }>();
  spinningState: boolean;
  private loadingRequestsCounter = 0;

  isLoading() {
    return this.loadingRequestsCounter > 0;
  }

  resetLoading() {
    this.loadingRequestsCounter = 0;
  }

  setLoading(value: boolean) {
    if (value) {
      this.loadingRequestsCounter++;
    } else if (this.loadingRequestsCounter > 0) {
      this.loadingRequestsCounter--;
    }

    if (this.loadingState !== value || !value) {
      this.loadingState = value;
      this.loadingStateChanged$.next({ loading: this.loadingState });
    }
  }

  setSpinning(value: boolean) {
    if (this.spinningState !== value) {
      this.spinningState = value;
      this.spinningStateChanged$.next({ spinning: this.spinningState });
    }
  }
}
