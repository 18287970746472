import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { UserOrganizationRole } from 'src/app/auth/models/UserOrganizationRole';
import { map } from 'rxjs/internal/operators/map';
import { UsersService } from 'src/app/auth/services/users.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class OrganizationsAdminGuard implements CanActivate {
  constructor(private readonly userService: UsersService, private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const organizationId = route.params.organizationId;
    return this.userService.hasServiceUserAccessToOrganization(organizationId)
      .pipe(
        switchMap(result => {
          return this.userService.getUserOrganizationsDetails(true)
            .pipe(
              map(userOrganizations => {
                if (result) {
                  return true;
                }
                if (userOrganizations) {
                  const organization = userOrganizations.organizations.find(o => o.organizationId == organizationId);
                  if (organization && organization.userRole === UserOrganizationRole.Administrator) {
                    return true;
                  }
                  sessionStorage.setItem('navigationFailed','admin');
                  this.router.navigateByUrl('/');
                  return false;
                }
                sessionStorage.setItem('navigationFailed','admin');
                this.router.navigateByUrl('/');
                return false;
              }))
        }));
   }
}