import { UserOrganizationDetails } from './../models/userOrganizationDetails';

import { OnboardingStatusService } from 'src/app/onboarding/services/onboarding-status.service';
import { Injectable } from '@angular/core';
import * as JwtDecode from 'jwt-decode';
import { Subject, forkJoin, ReplaySubject } from 'rxjs';
import { Constants } from 'src/app/core/constants';
import { UsersService } from './users.service';
import { ConfigurationService } from '../../configuration/services/configuration.service';
import { PlatformAdministrationService } from 'src/app/platform-administration/services/platform-administration.service';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { SessionStorageManagementHelper } from 'src/app/core/helpers/sessionStorageManagement.helper';

@Injectable()
export class AuthService {
  public loggedInUserChanged$ = new Subject<{ name: string }>();
  public loginInProgress$ = new ReplaySubject<boolean>();

  private readonly B2CTodoAccessTokenKey = Constants.ACCESS_TOKEN_KEY;
  private readonly RidderEmailAddressDomain = Constants.RIDDER_EMAIL_ADDRESS_DOMAIN;

  private defaultUserConfig: any;
  private ridderUserConfig: any;
  private msalConfiguration: any;
  private msalService: PublicClientApplication;

  allowedEmailAddressesForNonRidderUsers = [
    'ridder-cristi@netrom.ro',
    'ridder-gabriel@netrom.ro',
    'ridder-mirela@netrom.ro',
    'ridder-cosmin@netrom.ro'
  ];

  private loggedInUserDetails: {
    isPlatformAdmin: boolean,
    isServiceUser: boolean,
    isConsultant: boolean,
    organizationsDetails: UserOrganizationDetails[],
    needsToNavigateToUserOnboardingPage: boolean
  };

  public loggedInUserDetails$ = new ReplaySubject<{
    isPlatformAdmin: boolean,
    isServiceUser: boolean,
    isConsultant: boolean,
    organizationsDetails: UserOrganizationDetails[],
    needsToNavigateToUserOnboardingPage: boolean
  }>(1);

  public get authToken(): string {
    return localStorage.getItem(Constants.ACCESS_TOKEN_KEY);
  }

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly usersService: UsersService,
    private readonly platformAdminService: PlatformAdministrationService,
    private readonly onboardingStatusService: OnboardingStatusService
    ) {
    this.setupAuthConfigurations();
  }

  setupAuthConfigurations() {
    const configuration = this.configurationService.configuration;

    this.msalConfiguration = this.setupMsalConfigurations(configuration);
    this.defaultUserConfig = this.getDefaultUserConfigurations(configuration);
    this.ridderUserConfig = this.getRidderUserConfigurations(configuration);

    this.setupClientApplication();

    if (this.isLoggedIn()) {
      if (this.isTokenExpired()) {
        this.refreshToken().then(() => {
          this.loadLoggedInUserDetails();
        });
      } else {
        this.loadLoggedInUserDetails();
      }
    }
  }

  setupMsalConfigurations(configuration: ClientConfiguration) {
    return {
      tenant: configuration.msalConfig.authTenant,
      clientID: configuration.msalConfig.clientId,
      redirectUri: `${window.location.protocol}//${window.location.host}/login`,
      b2cScopes: configuration.msalConfig.scopes,
      validateAuthority: configuration.msalConfig.validateAuthority,
      navigateToLoginRequestUrl: configuration.msalConfig.navigateToLoginRequestUrl,
    };
  }

  isRidderUser(userEmailAddress: string) {
    return userEmailAddress.toLocaleLowerCase().includes(`@${this.RidderEmailAddressDomain}.`)
      || this.allowedEmailAddressesForNonRidderUsers.indexOf(userEmailAddress.toLocaleLowerCase()) >= 0;
  }

  getUserClientApplicationSettingsBasedOnEmailAddress(userEmailAddress: string) {
    if (this.isRidderUser(userEmailAddress)) {
      return this.ridderUserConfig;
    }

    return this.defaultUserConfig;
  }

  getRidderUserConfigurations(configuration: ClientConfiguration) {
    return {
      signInPolicy: configuration.msalConfig.ridderUserAuthSettings.signinPolicy,
      loginUrl: configuration.msalConfig.ridderUserAuthSettings.loginUrl,
      extraQueryParameters: configuration.msalConfig.ridderUserAuthSettings.extraQueryParameters
    };
  }

  getDefaultUserConfigurations(configuration: ClientConfiguration) {
    return {
      signInPolicy: configuration.msalConfig.defaultUserAuthSettings.signinPolicy,
      signUpPolicy: configuration.msalConfig.defaultUserAuthSettings.signupPolicy,
      resetPasswordPolicy: configuration.msalConfig.defaultUserAuthSettings.resetPasswordPolicy,
      loginUrl: configuration.msalConfig.defaultUserAuthSettings.loginUrl,
      extraQueryParameters: null
    };
  }

  setupClientApplication() {
    this.initializeMsalService(this.defaultUserConfig.loginUrl, this.defaultUserConfig.signInPolicy);
    const interactionInProgress = sessionStorage.getItem('msal.interaction.status') === this.msalConfiguration.clientID;
    this.loginInProgress$.next(interactionInProgress);
    this.msalService.handleRedirectPromise()
    .then(response => {
      if (response !== null) {
        this.saveAccessTokenToCache(response.accessToken);
        this.createLoggedinUser();
      } else {
        this.loginInProgress$.next(false);
      }
    })
    .catch(error => {
      this.loginInProgress$.next(false);
      if (error.errorMessage.indexOf(Constants.B2C_RESET_PASSWORD_ERROR) > -1) {
        this.resetPassword();
      }
    });
  }

  private initializeMsalService(loginUrl, policy) {
    this.msalService = new PublicClientApplication({
      auth: {
        clientId: this.configurationService.configuration.msalConfig.clientId,
        authority: loginUrl + this.configurationService.configuration.msalConfig.authTenant + '/' + policy,
        redirectUri: '/login',
        postLogoutRedirectUri: '/login',
        knownAuthorities: this.getKnownAuthorities()
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false
      },
      system: {
        iframeHashTimeout: 20000
      }
    });
  }

  getKnownAuthorities() {
    return [
      this.defaultUserConfig.loginUrl + this.configurationService.configuration.msalConfig.authTenant + '/' + this.defaultUserConfig.signInPolicy,
      this.defaultUserConfig.loginUrl + this.configurationService.configuration.msalConfig.authTenant + '/' + this.defaultUserConfig.signUpPolicy,
      this.defaultUserConfig.loginUrl + this.configurationService.configuration.msalConfig.authTenant + '/' + this.defaultUserConfig.resetPasswordPolicy,
      this.ridderUserConfig.loginUrl + this.configurationService.configuration.msalConfig.authTenant + '/' + this.ridderUserConfig.signInPolicy
    ];
  }

  public login(userEmailAddress: string): void {
    const tenantConfig = this.getUserClientApplicationSettingsBasedOnEmailAddress(userEmailAddress);
    this.initializeMsalService(tenantConfig.loginUrl, tenantConfig.signInPolicy);
    this.msalService.loginRedirect({
      scopes: this.msalConfiguration.b2cScopes,
      extraQueryParameters: tenantConfig.extraQueryParameters,
      loginHint: userEmailAddress
    });
  }

  public register(): void {
    this.initializeMsalService(this.defaultUserConfig.loginUrl, this.defaultUserConfig.signUpPolicy);
    this.msalService.loginRedirect({ scopes: this.msalConfiguration.b2cScopes });
  }

  public resetPassword(): void {
    this.initializeMsalService(this.defaultUserConfig.loginUrl, this.defaultUserConfig.resetPasswordPolicy);
    this.msalService.loginRedirect({ scopes: this.msalConfiguration.b2cScopes });
  }

  saveAccessTokenToCache(accessToken): void {
    localStorage.setItem(this.B2CTodoAccessTokenKey, accessToken);
  }

  logout(): void {
    localStorage.removeItem('pageLayoutSettingsMenuType');
    localStorage.removeItem('lastVisitedModule');
    SessionStorageManagementHelper.resetComparisonToolSettings();
    this.msalService.logoutRedirect();
  }

  isLoggedIn(): boolean {
    return this.msalService.getAllAccounts().length > 0
  }

  getLoggedInUser() {
    return this.msalService.getAllAccounts()[0];
  }

  getLoggedInUserToken() {
    return localStorage.getItem(Constants.ACCESS_TOKEN_KEY);
  }

  getLoggedInUserTokenDecoded() {
    const token = this.getLoggedInUserToken();
    return JwtDecode(token);
  }

  getLoggedInUserIdentityId() {
    const token = this.getLoggedInUserTokenDecoded();
    return token.sub;
  }

  createLoggedinUser() {
    this.usersService.createLoggedinUser().subscribe(() => {
      this.loadLoggedInUserDetails();
      this.loggedInUserChanged$.next({
        name: this.getLoggedInUser().name
      });
    });
  }

  refreshToken() {
    return new Promise<void>(resolve => {
      this.initializeMsalService(this.defaultUserConfig.loginUrl, this.defaultUserConfig.signInPolicy);
      if (this.isLoggedIn()) {
        const lastAuthenticatedUserEmailAddress = this.getLoggedInUser()?.idTokenClaims['emails'][0];
        if (lastAuthenticatedUserEmailAddress) {
          const tenantConfig = this.getUserClientApplicationSettingsBasedOnEmailAddress(lastAuthenticatedUserEmailAddress);
          this.initializeMsalService(tenantConfig.loginUrl, tenantConfig.signInPolicy);

          this.msalService.acquireTokenSilent({
            scopes: this.msalConfiguration.b2cScopes,
            account: this.getLoggedInUser(),
            extraQueryParameters: tenantConfig.extraQueryParameters,
          }).then(
            (response) => {
              this.saveAccessTokenToCache(response.accessToken);
              resolve();
            },
            (errorSilent: any) => {
              console.log('acquireRefreshTokenSilent failed', errorSilent);
              sessionStorage.setItem('navigateByUrl', 'false');

              this.msalService.acquireTokenRedirect({
                scopes: this.msalConfiguration.b2cScopes,
                extraQueryParameters: tenantConfig.extraQueryParameters,
                loginHint: lastAuthenticatedUserEmailAddress
              });
            }
          );
        }
        else {
          this.msalService.loginRedirect({ scopes: this.msalConfiguration.b2cScopes });
        }
      }
      else {
        this.msalService.loginRedirect({ scopes: this.msalConfiguration.b2cScopes });
      }
    });
  }

  isTokenExpired(): boolean {
    if (this.isLoggedIn()) {
      const token = this.getLoggedInUserTokenDecoded();
      return new Date() > new Date(token.exp * 1000);
    }
    return true;
  }

  addAutorizationToken(ajaxSettings) {
    const token = localStorage.getItem(Constants.ACCESS_TOKEN_KEY);
    if (token) {
      ajaxSettings.headers = { Authorization: `Bearer ${token}` };
    }
  }

  loadLoggedInUserDetails(isDataReloadRequired : boolean = false) {

    forkJoin([
      this.platformAdminService.isUserPlatformAdministrator(),
      this.usersService.isServiceUser(),
      this.usersService.isConsultant(),
      this.usersService.getUserOrganizationsDetails(isDataReloadRequired),
      this.onboardingStatusService.getUserOnboardingStatus()
    ]
    ).subscribe(result => {
      this.loggedInUserDetails = {
        isPlatformAdmin: result[0],
        isServiceUser: result[1],
        isConsultant: result[2],
        organizationsDetails: result[3].organizations,
        needsToNavigateToUserOnboardingPage: !result[4],
      };
      this.loggedInUserDetails$.next(this.loggedInUserDetails);
    });
  }

  getLoggedInUserDetails() {
    return this.loggedInUserDetails$.asObservable();
  }
}
