import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Injectable } from "@angular/core";

import { map } from "rxjs/internal/operators/map";
import { AuthService } from "src/app/auth/services/auth.service";
import { UsersService } from "src/app/auth/services/users.service";
import { switchMap } from "rxjs/operators";
import { OrganizationsOnboardedGuard } from "./organizations-onboarded-guard.service";
import { OrganizationLicenseGuard } from "src/app/core/services/organization-license-guard.service";

@Injectable()
export class OrganizationsMemberGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly userService: UsersService,
    private readonly organizationLicenseGuard: OrganizationLicenseGuard,
    private readonly organizationsOnboardedGuard: OrganizationsOnboardedGuard
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const organizationId = +route.params.organizationId;
    return this.organizationsOnboardedGuard.canActivate(route, state).pipe(
      switchMap((isOrganizationOnboarded) => {
        if (isOrganizationOnboarded) {
          return this.organizationLicenseGuard.canActivate(route).pipe(
            switchMap((hasLicense) => {
              if (hasLicense) {
                return this.authService.getLoggedInUserDetails().pipe(
                  switchMap((userDetails) => {
                    return this.userService
                      .hasServiceUserAccessToOrganization(organizationId, true)
                      .pipe(
                        map((hasServiceUserAccess) => {
                          if (
                            userDetails.organizationsDetails.find(
                              (o) => o.organizationId === organizationId
                            ) !== undefined ||
                            hasServiceUserAccess
                          ) {
                            return true;
                          }
                          sessionStorage.setItem("navigationFailed", "member");
                          this.router.navigateByUrl("/");
                          return false;
                        })
                      );
                  })
                );
              }
            })
          );
        }
      })
    );
  }
}
