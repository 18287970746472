import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Injectable } from "@angular/core";

import { map } from "rxjs/internal/operators/map";
import { AuthService } from "src/app/auth/services/auth.service";

@Injectable()
export class OrganizationsConsultantGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const organizationId = +route.params.organizationId;
    return this.authService.getLoggedInUserDetails().pipe(
      map((userDetails) => {
        if (userDetails.isConsultant || userDetails.organizationsDetails.find((o) => o.organizationId === organizationId) !== undefined) {
          return true;
        }
        this.router.navigateByUrl("/");
        return false;
      })
    );
  }
}
