export class CacheService {
  private cache = {};

  addToCache(key: string, value: any) {
    this.cache[key] = value;
  }

  getFromCache(key: string): any {
    return this.cache[key];
  }

  isInCache(cacheKey: string) {
    return this.cache[cacheKey] !== undefined;
  }

  removeFromCache(cacheKey: string) {
    return this.cache[cacheKey] = undefined;
  }

  clearCache() {
    this.cache = {};
  }
}
