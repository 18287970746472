export enum SettingType {
  UnitSystem = 1,
  Currency = 2,
  Distance = 3,
  Temperature = 4,
  Area = 5,
  Volume = 6,
  Mass = 7,
  CurrencyPerArea = 8,
  CurrencyPerMass = 9,
  CurrencyPerVolume = 10,
  MassPerArea = 11,
  MassPerMass = 12,
  DateFormat = 13,
  DecimalSeparator = 14,
  ThousandsSeparator = 15,
  EmailNotifications = 16,
}
