import * as moment from 'moment';
import { SavedViewTimeFrame } from '../enums/savedViewTimeFrame';
import { ComparisonToolGraphTimestampFormattingType } from '../enums/comparisonToolGraphTimestampFormattingType';
export enum SortOrder {
  Ascending,
  Descending
}

export class SortingRule {
  property: string;
  sortOrder: SortOrder;
}

export class SortingHelper {

  public static sortBy(property: string, sortOrder: SortOrder) {

    return (element1: any, element2: any): number => {

      if (element1[property] > element2[property]) {
        return sortOrder === SortOrder.Ascending ? 1 : -1;
      }

      if (element1[property] < element2[property]) {
        return sortOrder === SortOrder.Ascending ? -1 : 1;
      }

      return 0;
    }
  }

  public static sortByInnerProperty(property: string,innerProperty: string, sortOrder: SortOrder) {

    return (element1: any, element2: any): number => {

      if (element1[property[innerProperty]] > element2[property[innerProperty]]) {
        return sortOrder === SortOrder.Ascending ? 1 : -1;
      }

      if (element1[property[innerProperty]] < element2[property[innerProperty]]) {
        return sortOrder === SortOrder.Ascending ? -1 : 1;
      }

      return 0;
    }
  }

  public static sortByMultipleProperties(sortingRules: SortingRule[]) {

    return (element1: any, element2: any): number => {
      if (sortingRules === undefined || sortingRules === null || sortingRules.length === 0) {
        return 0;
      }

      let index = 0

      while (index < sortingRules.length) {

        if (element1[sortingRules[index].property] > element2[sortingRules[index].property]) {
          return sortingRules[index].sortOrder === SortOrder.Ascending ? 1 : -1;
        }

        if (element1[sortingRules[index].property] < element2[sortingRules[index].property]) {
          return sortingRules[index].sortOrder === SortOrder.Ascending ? -1 : 1;
        }

        index++;
      }

      return 0;
    }
  }

  public static sortPeriodInComparisonTool(p1, p2,timeGranularityFactorToDay,savedViewTimeFrame:SavedViewTimeFrame,comparisonToolGraphTimestampFormattingType: ComparisonToolGraphTimestampFormattingType) {
    const isAbsolute = savedViewTimeFrame === SavedViewTimeFrame.Absolute;
    if(isAbsolute){
     return this.sortAbsolutePeriod(p1,p2,timeGranularityFactorToDay,comparisonToolGraphTimestampFormattingType);
    }
    return this.sortRelativeCustomPeriod(p1, p2,timeGranularityFactorToDay);
  }

  private static sortAbsolutePeriod(p1, p2,timeGranularityFactorToDay,comparisonToolGraphTimestampFormattingType: ComparisonToolGraphTimestampFormattingType) {
    if(comparisonToolGraphTimestampFormattingType === ComparisonToolGraphTimestampFormattingType.Custom){
      //format "Week x YYYY "
      
      const p1Year = +p1.split(' ')[2];
      const p2Year = +p2.split(' ')[2];
      if (p1Year !== p2Year) {
        return p1Year - p2Year;
      }

      const p1Week = +p1.split(' ')[1];
      const p2Week = +p2.split(' ')[1];
      return p1Week - p2Week;

    } else {
      // format "YYYY/MM/DD" or "YYYY/MM/DD hh:mm"
      let dateOfP1Moment = timeGranularityFactorToDay == null?  moment(p1, "YYYY/MM/DD") : moment(p1, "YYYY/MM/DD hh:mm"); 
      let dateOfP1 = dateOfP1Moment.toDate(); 
      let dateOfP2Moment = timeGranularityFactorToDay == null?  moment(p2, "YYYY/MM/DD") : moment(p2, "YYYY/MM/DD hh:mm"); 
      let dateOfP2 = dateOfP2Moment.toDate();

      return Math.abs(dateOfP1.getTime() - dateOfP2.getTime());
    }
     
  }

  private static sortRelativeCustomPeriod(p1, p2,timeGranularityFactorToDay){
    //format "Day x hh:mm" or "Day x"
    debugger;
    if (timeGranularityFactorToDay !== null) {
      return this.sortCustomPeriodWithHours(p1, p2);
    } else {
      const p1Day = +p1.split(' ')[1];
      const p2Day = +p2.split(' ')[1];
      
      return p1Day - p2Day;
    }
  }

  private static sortCustomPeriodWithHours(p1, p2){
    //format "Day x hh:mm" 
    const p1Day = +p1.split(' ')[1];
    const p2Day = +p2.split(' ')[1];

    if (p1Day !== p2Day) {
      return p1Day - p2Day;
    }

    const p1Hour = +p1.split(' ')[2].split(":")[0];
    const p2Hour = +p2.split(' ')[2].split(":")[0];
    
    if (p1Hour !== p2Hour) {
      return p1Hour - p2Hour;
    }

    const p1Minute = +p1.split(' ')[2].split(":")[1];
    const p2Minute = +p2.split(' ')[2].split(":")[1];

    return p1Minute - p2Minute;
  }


}
