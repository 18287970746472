import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { OrganizationLicenseModuleType } from '../enums/organizationLicenseModuleType';
import { OrganizationLicenseService } from './organization-license.service';
import { of } from 'rxjs';
import { LandingPageService } from 'src/app/shell/services/landing-page.service';

@Injectable()
export class OrganizationLicenseGuard implements CanActivate {
    constructor(private readonly organizationLicenseService: OrganizationLicenseService,
                private readonly landingService: LandingPageService) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot) {
        const organizationModules = activatedRoute.data.organizationModule as OrganizationLicenseModuleType[];

        if (organizationModules === undefined || organizationModules === null || organizationModules.length === 0) {
            return of(true);
        }

        let organizationId = +activatedRoute.params.organizationId;

        if (isNaN(organizationId)) {
            organizationId = +activatedRoute.parent.parent.params.organizationId;
        }
        return this.organizationLicenseService.hasLicenseForOrganizationModules(organizationId, organizationModules).pipe(
            map(hasLicense => {
                if (hasLicense) {
                    return true;
                }
                this.landingService.navigateToTheFirstAvailableLicensedModule(organizationId);
                return false;
            })
        );
    }
}
