import { NotificationTypeEnum } from 'src/app/core/enums/notificationTypeEnum';

export class NotificationModel {
  id: string;
  title: string;
  message: string;
  type: NotificationTypeEnum;
  requiresTranslation: boolean;
  translationParams: any;
  translationKey: any;
}
