export enum CropType {
   // DON'T FORGET TO KEEP THIS FILE UP-TO-DATE WITH THE C# VERSION.

   Unknown = 0,  // Unknown: not used
   Other = 1,  // A generic type for crops we don't support yet
   TomatoOnVine = 101,  // Start of tomato
   TomatoBeef = 102,
   TomatoCherry = 103,
   TomatoCocktail = 104,
   TomatoSnack = 105,
   TomatoLoose = 106,
   TomatoPlum = 107,
   SweetPepper = 200,  // Start of pepper
   SweetPepperRed = 201,
   SweetPepperYellow = 202,
   SweetPepperGreen = 203,
   SweetPepperOrange = 204,
   Lettuce = 400,  // Start of lettuce
   LettuceButterhead = 401,
   Strawberry = 500,  // Start of strawberry
   Cucumber = 600,
   CucumberLongEnglish = 601,  // Start of cucumber
   CucumberSnack = 602,
   Aubergine = 700,  // Start of aubergine
   PointedPepper = 800,  // Start of pointed pepper
   PointedPepperRed = 801,
   PointedPepperYellow = 802,
   PointedPepperGreen = 803,
   PointedPepperOrange = 804,
   Radish = 900,  // Start of radish
   Endive = 1000, // Start of endive
   Zucchini = 1100, // Start of zucchini
   ZucchiniGreen = 1101,
   Cannabis = 1200  // Start of cannabis
}
