import { OrganizationLicenseModuleType } from 'src/app/core/enums/organizationLicenseModuleType';
import { CropType } from '../../enums/cropType';

export class CropCycleQueryDto {
  organizationId: number;
  siteId: number;
  crop: CropType;
  periodStartDate: Date;
  periodEndDate: Date;
  searchText: string | null;
  module: OrganizationLicenseModuleType
}
