import { CanDeactivateGuard } from './core/services/can-deactivate-guard.service';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { HttpTokenInterceptor } from './auth/interceptors/http-token.interceptor';
import { AuthService } from './auth/services/auth.service';
import { UsersService } from './auth/services/users.service';
import { GlobalErrorHandler } from './core/services/global-error-handler';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { AuthGuard } from './auth/services/auth-guard.service';
import { PlatformAdminAuthGuard } from './auth/services/platform-admin-auth-guard.service';
import { OrganizationsAdminGuard } from './organizations-settings/services/organizations-admin-guard.service';
import { LanguageService } from './core/services/language.service';
import { CoreModule } from 'src/app/core/core.module';
import { TranslationsLoader } from './translations/classes/translationsloader';
import { ConfigurationService } from './configuration/services/configuration.service';
import { PlatformAdministrationService } from './platform-administration/services/platform-administration.service';
import { OrganizationsMemberGuard } from './organizations-settings/services/organizations-member-guard.service';
import { OrganizationsOnboardedGuard } from './organizations-settings/services/organizations-onboarded-guard.service';
import { OrganizationsConsultantGuard } from './organizations-settings/services/organizations-consultant-guard.service';
import { formatDate } from '@angular/common';

export function appFactory(translateService: TranslateService) {
  return () => {
    translateService.setDefaultLang('en-US');
    return translateService.use('en-US').toPromise();
  };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationsLoader,
        deps: [HttpClient, ConfigurationService],
            },
      isolate: false,
        }),
        CoreModule.forRoot(),
        AppRoutingModule,
    ConfigurationModule,
    ],
    providers: [
        AuthService,
        AuthGuard,
        PlatformAdminAuthGuard,
        OrganizationsAdminGuard,
        OrganizationsMemberGuard,
        OrganizationsOnboardedGuard,
        OrganizationsConsultantGuard,
        CanDeactivateGuard,
        UsersService,
        PlatformAdministrationService,
        LanguageService,
        { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['en-US'] } },
        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: APP_INITIALIZER, useFactory: appFactory, deps: [TranslateService], multi: true },
    ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    this.overrideDate();
  }

  overrideDate() {
    Date.prototype.toJSON = function (key: Date) {
      return formatDate(this, 'M/d/yyyy, hh:mm:ss a', 'en_US');
    };
  }
}
