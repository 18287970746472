import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IsoLanguage } from 'src/app/user-settings/models/isoLanguage';
import { LANGUAGES } from 'src/app/user-settings/data/languageData';

@Injectable()
export class LanguageService {
  constructor(private readonly translationService: TranslateService) {}

  getLanguages(): IsoLanguage[] {
    return LANGUAGES;
  }

  getSwapColorsLanguageCodes(): string[] {
    return ['zh-Hans'];
  }

  addSupportedLanguagesToTranslateService() {
    this.translationService.addLangs(LANGUAGES.map((language) => language.code));
  }

  isLanguageAvailable(lang: string): boolean {
    return this.translationService.getLangs().includes(lang);
  }

  setUserLanguage(language: string) {
    if (this.isLanguageAvailable(language)) {
        this.translationService.use(language);
      } else {
      this.translationService.use('en-US');
    }
  }
}
