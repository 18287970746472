import { Constants } from 'src/app/core/constants';
import { PeriodHelper } from './../helpers/period.helper';
import { ThousandsSeparator } from './../../user-settings/enums/thousandsSeparator';
import { DecimalsSeparator } from './../../user-settings/enums/decimalsSeparator';
import { CropCyclesPeriodSelectorEnum } from 'src/app/core/enums/cropCyclePeriodSelectorEnum';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PeriodFilter } from '../models/periodFilter';
import { PeriodSelectorEnum } from 'src/app/core/enums/periodSelectorEnum';
import { TranslateService } from '@ngx-translate/core';
import * as cloneDeep from 'lodash/cloneDeep';
import { UserSettingsService } from 'src/app/user-settings/services/user-settings.service';
import { DateFormat } from 'src/app/user-settings/enums/dateFormat';
import { SettingType } from 'src/app/core/enums/settingType';
import { CropCyclesPeriodFilter } from '../models/cropCycles/cropCyclesPeriodFilter';
import { ResourceClassificationApiModel } from '../models/resourceClassificationApiModel';
import { ValueConsolidationType } from '../enums/valueConsolidationType';
import { CurrencyHelper } from '../helpers/currency.helper';
const MAX_PRECISION = Constants.NUMBER_OF_DECIMALS_IN_MANUAL_INPUT;

@Injectable({
  providedIn: 'root',
})
export class FormattingService {
  DECIMAL_SEPARATOR: string;
  THOUSANDS_SEPARATOR: string;
  private readonly dateFormats = new Map()
    .set(DateFormat.DayMonthYear, 'dd-MM-yyyy')
    .set(DateFormat.MonthDayYear, 'MM-dd-yyyy')
    .set(DateFormat.YearMonthDay, 'yyyy-MM-dd');
  private selectedDateFormat = DateFormat.DayMonthYear;
  selectedDateFormatChanged$ = new BehaviorSubject<string>(
    this.dateFormats.get(DateFormat.DayMonthYear)
  );
  selectedTimestampFormatChanged$ = new BehaviorSubject<string>(
    this.dateFormats.get(DateFormat.DayMonthYear)
  );

  private readonly decimalSeparators = new Map()
    .set(DecimalsSeparator.Comma, ',')
    .set(DecimalsSeparator.Period, '.');
  selectedDecimalSeparatorChanged$ = new BehaviorSubject<string>(
    this.decimalSeparators.get(DecimalsSeparator.Comma)
  );

  private readonly thousandsSeparators = new Map()
    .set(ThousandsSeparator.Period, '.')
    .set(ThousandsSeparator.Comma, ',')
    .set(ThousandsSeparator.Space, ' ');
  selectedThousandsSeparatorChanged$ = new BehaviorSubject<string>(
    this.thousandsSeparators.get(ThousandsSeparator.Period)
  );

  constructor(
    private readonly datePipe: DatePipe,
    private readonly translationService: TranslateService,
    private readonly userSettingsService: UserSettingsService
  ) {
    userSettingsService.getSettings().subscribe((settings) => {
      const userDateFormatSetting = settings.settings.find(
        (s) => s.settingType === SettingType.DateFormat
      );
      if (userDateFormatSetting) {
        this.setSelectedDateFormat(
          DateFormat[userDateFormatSetting.settingValue]
        );
      } else {
        this.setSelectedDateFormat(DateFormat.DayMonthYear);
      }

      this.setDecimalSeparator(settings);
      this.setThousandSeparator(settings);
    });
  }

  private setDecimalSeparator(settings) {
    const userDecimalSeparatorSetting = settings.settings.find(
      (s) => s.settingType === SettingType.DecimalSeparator
    );
    if (userDecimalSeparatorSetting) {
      switch (userDecimalSeparatorSetting.settingValue) {
        case 'Period': {
          this.DECIMAL_SEPARATOR = '.';
          break;
        }
        case 'Comma': {
          this.DECIMAL_SEPARATOR = ',';
          break;
        }
      }
    } else {
      this.DECIMAL_SEPARATOR = ',';
    }
  }

  private setThousandSeparator(settings) {
    const userThousandsSeparatorSetting = settings.settings.find(
      (s) => s.settingType === SettingType.ThousandsSeparator
    );
    if (userThousandsSeparatorSetting) {
      switch (userThousandsSeparatorSetting.settingValue) {
        case 'Period': {
          this.THOUSANDS_SEPARATOR = '.';
          break;
        }
        case 'Comma': {
          this.THOUSANDS_SEPARATOR = ',';
          break;
        }
        case 'Space': {
          this.THOUSANDS_SEPARATOR = ' ';
          break;
        }
      }
    } else {
      this.THOUSANDS_SEPARATOR = ' ';
    }
  }

  // todo: will be replaced by getFormattedCalendarPeriodFilter
  getFormatedPeriod(periodFilter: PeriodFilter): string {
    let formatedPeriod = '';
    const selectedPeriodFilter = cloneDeep(periodFilter);
    selectedPeriodFilter.startDate.setHours(0, 0, 0, 0);
    selectedPeriodFilter.startDate.setDate(
      selectedPeriodFilter.startDate.getDate()
    );
    switch (periodFilter.periodSelectorType) {
      case PeriodSelectorEnum.Week.valueOf(): {
        formatedPeriod = this.getWeekFormatedPeriod(
          selectedPeriodFilter.startDate
        );
        break;
      }
      case PeriodSelectorEnum.Month.valueOf(): {
        const year = this.datePipe.transform(
          selectedPeriodFilter.endDate,
          'yyyy'
        );
        const month = this.datePipe.transform(
          selectedPeriodFilter.endDate,
          'MMMM'
        );
        formatedPeriod = this.translationService.instant(
          'Shell.dates.formattedMonth',
          {
            monthName: this.translationService.instant(`Shell.dates.Month.${month}`),
            yearNumber: year,
          }
        );
        break;
      }
      case PeriodSelectorEnum.Quarter.valueOf(): {
        const m = Math.floor(selectedPeriodFilter.startDate.getMonth() / 3) + 1;
        const quarter = m > 4 ? m - 4 : m;
        const year = this.datePipe.transform(
          selectedPeriodFilter.startDate,
          'yyyy'
        );
        formatedPeriod = this.translationService.instant(
          'Shell.dates.formattedQuarter',
          {
            quarterNumber: quarter,
            yearNumber: year,
          }
        );
        break;
      }
      case PeriodSelectorEnum.Year.valueOf(): {
        formatedPeriod = this.datePipe.transform(
          selectedPeriodFilter.endDate,
          'yyyy'
        );
        break;
      }
      case PeriodSelectorEnum.Custom.valueOf(): {
        const startDateFormated = this.getFormattedDate(selectedPeriodFilter.startDate);
        const endDateFormated = this.getFormattedDate(selectedPeriodFilter.endDate);
        formatedPeriod = this.translationService.instant(
          'Shell.dates.formattedCustomDate',
          {
            startDate: startDateFormated,
            endDate: endDateFormated,
          }
        );
        break;
      }
      case PeriodSelectorEnum.FourWeek.valueOf(): {
        formatedPeriod = this.getFourWeekFormatedPeriod(
          selectedPeriodFilter.startDate
        );
        break;
      }
    }

    return formatedPeriod;
  }

  getFormattedDate(date: Date) {
    return this.datePipe.transform(
      date,
      this.dateFormats.get(this.selectedDateFormat)
    );
  }

  getCustomFormattedDate(date: Date) {
    return this.datePipe.transform(date, 'MMM d');
  }

  getFormattedTime(date: Date) {
    return this.datePipe.transform(
      date,
      `${this.dateFormats.get(this.selectedDateFormat)}  HH:mm:ss`
    );
  }

  getFormattedTimeLikeBackend(date: Date) {
    return this.datePipe.transform(
      date,
      `yyyy-MM-dd  HH:mm:ss`
    );
  }

  getFormattedTimestamp(date: Date) {
    return this.datePipe.transform(
      date,
      `${this.dateFormats.get(this.selectedDateFormat)}  HH:mm`
    );
  }

  setSelectedDateFormat(selectedDateFormat: number) {
    this.selectedDateFormat = selectedDateFormat;

    this.selectedDateFormatChanged$.next(
      this.dateFormats.get(selectedDateFormat)
    );
    this.selectedTimestampFormatChanged$.next(
      `${this.dateFormats.get(selectedDateFormat)}  HH:mm:ss`
    );
  }

  setSelectedDecimalSeparator(selected: number) {
    this.DECIMAL_SEPARATOR = this.decimalSeparators.get(selected);
    this.selectedDecimalSeparatorChanged$.next(
      this.decimalSeparators.get(selected)
    );
  }

  setSelectedThousandsSeparator(selected: number) {
    this.THOUSANDS_SEPARATOR = this.thousandsSeparators.get(selected);
    this.selectedThousandsSeparatorChanged$.next(
      this.thousandsSeparators.get(selected)
    );
  }

  getWeekFormatedPeriod(dt: Date) {
    const weekNumberAndYear = PeriodHelper.getWeekNumberAndYear(dt);
    const week = this.translationService.instant('Shell.dates.formattedWeek', {
      weekNumber: weekNumberAndYear.weekNumber,
      yearNumber: weekNumberAndYear.yearNumber,
    });
    return week;
  }

  getFourWeekFormatedPeriod(dt: Date) {
    const weekNumberAndYear = PeriodHelper.getWeekNumberAndYear(dt);
    let finalFourWeekPeriodWeekNumber;
    if (weekNumberAndYear.weekNumber === Constants.LAST_4WEEKS_PERIOD_OF_THE_YEAR_START_WEEK) {
      const lastWeekOfYear = PeriodHelper.getLastWeekNumberOfYear(weekNumberAndYear.yearNumber);
      finalFourWeekPeriodWeekNumber = lastWeekOfYear.weekNumber;
    } else {
      finalFourWeekPeriodWeekNumber = weekNumberAndYear.weekNumber + 3;
    }
    const week = this.translationService.instant('Shell.dates.formattedFourWeek', {
      firstWeekNumber: weekNumberAndYear.weekNumber,
      lastWeekNumber: finalFourWeekPeriodWeekNumber
    });
    return week;
  }

  // todo: will be replaced by getFormattedCropCyclePeriodFilter
  getCropCyclesFormatedPeriod(periodFilter: CropCyclesPeriodFilter) {
    let formatedPeriod = '';
    switch (periodFilter.periodSelectorType) {
      case CropCyclesPeriodSelectorEnum.CropWeek.valueOf(): {
        formatedPeriod = this.translationService.instant(
          'Shell.dates.formattedCropWeek',
          {
            weekNumber: periodFilter.weekNumber,
          }
        );
        break;
      }
      case CropCyclesPeriodSelectorEnum.ToDate.valueOf(): {
        formatedPeriod = this.translationService.instant(
          periodFilter.weekNumber === 1 ? 'Shell.dates.formattedToDateCropCycleWeek' : 'Shell.dates.formattedToDateCropCycleWeeks',
          {
            weekNumber: periodFilter.weekNumber,
          }
        );
        break;
      }
      case CropCyclesPeriodSelectorEnum.Full.valueOf(): {
        formatedPeriod = this.translationService.instant(
          'Shell.dates.formattedFullCropCycle'
        );
        break;
      }
    }
    return formatedPeriod;
  }

  getStatisticsFormattedValue(value: any, resourceClassification: ResourceClassificationApiModel) {
    return resourceClassification.valueConsolidationType !== ValueConsolidationType.MaxDuration ? 
     this.getDisplayFormattedNumber(
      value,
      CurrencyHelper.isCurrency(resourceClassification.characteristicType)
    ) : value;
  }

  getFormattedValue(
    value: number,
    classification: ResourceClassificationApiModel,
    enumDictionary: any
  ) {
    if(classification.valueConsolidationType === ValueConsolidationType.MaxDuration && enumDictionary !== undefined){
      const enumValue = this.getDisplayFormattedEnumValueInternal(
        value,
        classification.resourceClassificationIdentifier,
        enumDictionary
      );
      return enumValue === undefined?'': this.translationService.instant(`Shell.valuesEnum.${enumValue}`);
     
    } 
    return `${this.getDisplayFormattedNumber(
      value,
      CurrencyHelper.isCurrency(classification.characteristicType)
    )}`;
  }

  getDisplayFormattedNumber(value, isCurrency: boolean): any {
    this.setSeparators();
    return this.getDisplayFormattedNumberInternal(value, isCurrency);
  }

  
  getDisplayFormattedNumberWithFixedNumberOfDecimals(value, numberOfDecimals: number): any {
    this.setSeparators();
    return this.getDisplayFormattedNumberWithFixedNumberOfDecimalsInternal(value, numberOfDecimals);
  }

  private getDisplayFormattedNumberWithFixedNumberOfDecimalsInternal(value, numberOfDecimals: number): any {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    if (typeof value !== 'number') {
      value = this.getNumber(value);
    }

    try {
      let valueWithDecimals: string;
      if (value.toString().startsWith('0.00') || value.toString().startsWith('-0.00')) {
        valueWithDecimals = value.toFixed(numberOfDecimals);
      }
      else {
        valueWithDecimals = parseFloat(value.toFixed(numberOfDecimals)).toString();
      }
       
      return this.addDecimalAndThousandSeparators(valueWithDecimals);
    }
    catch (error) {
      return '';
    }
  }


  private getDisplayFormattedEnumValueInternal(value:any,identifier:string, enumDictionary: any): any {
    this.setSeparators();
    if (value === null || value === undefined || value === '') {
      return '';
    }
    if (typeof value !== 'number') {
      value = this.getNumber(value);
    }
    return enumDictionary[identifier][value];
  }

  getDisplayFormattedInputNumber(value): any {
    this.setSeparators();
    return this.getDisplayFormattedInputNumberInternal(value);
  }

  getDisplayFormattedInputNumberWithAllDecimals(value): any {
    this.setSeparators();
    return this.getDisplayFormattedInputNumberInternalWithAllDecimals(value);
  }

  private setSeparators() {
    if (this.DECIMAL_SEPARATOR === undefined ||
      this.thousandsSeparators === undefined) {
      this.userSettingsService.getSettings().subscribe((settings) => {
        this.setDecimalSeparator(settings);
        this.setThousandSeparator(settings);
      });
    }
  }

  private getDisplayFormattedInputNumberInternal(value): any {
    return this.getDisplayFormattedNumberInternal(value, false, true);
  }

  removeThousandSeparators(value: any) {
    if (value != null && !this.isScientificNotationNumber(value)) {
      value = value.toString();
      value = value.split(this.THOUSANDS_SEPARATOR).join('');
    }
    return value;
  }

  getNumber(value: any) {
    if (value !== null && value !== undefined) {
      value = value.toString().split(',').join('.');
      value = value.toString().split(' ').join('');
      if (value.split('.').length - 1 > 1) {
        value = value.toString().split('.').join('');
      }
      if (value !== '') {
        let number = Number(value);
        if (number !== null && number !== undefined) {
          value = this.removeScientificNotation(number);
        }
      }
    }
    return value !== '' ? value : null;
  }

  isScientificNotationNumber(number) {
      return number.toString().indexOf('e') > 0;
  }

  removeScientificNotation(number) {
    return Number(number);
  }

  private getDisplayFormattedNumberInternal(value, isCurrency: boolean, isInput = false): any {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    if (typeof value !== 'number') {
      value = this.getNumber(value);
    }

    if (value.toString().startsWith('0.00') || value.toString().startsWith('-0.00')) {
       return this.getSienctificNotationFormattedNumber(value);
    }

    if (this.isScientificNotationNumber(value)) {
      value = value.toString().replace('.', this.DECIMAL_SEPARATOR);
      value = value.toString().replace(',', this.DECIMAL_SEPARATOR);
      return value;
    }

    let numberOfDecimals = this.getNumberOfDecimals(value, isCurrency, isInput);

    try {
      const valueWithDecimals = parseFloat(value.toFixed(numberOfDecimals)).toString();
      return this.addDecimalAndThousandSeparators(valueWithDecimals);
    }
    catch (error) {
      return '';
    }
  }

  private getDisplayFormattedInputNumberInternalWithAllDecimals(value): any {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    if (typeof value !== 'number') {
      value = this.getNumber(value);
    }
    try {
      return this.addDecimalAndThousandSeparators(value.toString());
    }
    catch (error) {
      return '';
    }
  }

  getNumberOfDecimals(value: any, isCurrency: boolean, isInput: boolean) {
    let decimalPlaces = 0;
    if (Math.abs(value) >= 10 && Math.abs(value) < 100) {
      decimalPlaces = 1;
    }
    if (Math.abs(value) < 10 || isCurrency) {
      decimalPlaces = 2;
    }

    if (isCurrency && Math.abs(value) >= 100) {
      decimalPlaces = 0;
    }
    if (isInput) {
      decimalPlaces = MAX_PRECISION;
    }
    return decimalPlaces;
  }

   addDecimalAndThousandSeparators(value) {
    let formatted =  value.replace('.', this.DECIMAL_SEPARATOR);
    let intPart = formatted.split(this.DECIMAL_SEPARATOR)[0];
    if (Math.abs(value) >= 10000) {
       let intPartFormattedWithThousandsSeparators = intPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${this.THOUSANDS_SEPARATOR}`);
       formatted = formatted.replace(intPart, intPartFormattedWithThousandsSeparators);
    }
    return formatted;
  }

  getSienctificNotationFormattedNumber(value) {
    return parseFloat(Number(value).toFixed(MAX_PRECISION)).toExponential();
  }


  public getWeekDescription(w: Date) {
    w = new Date(w);
    let weekNumberAndYear = PeriodHelper.getWeekNumberAndYear(w);
    return `${weekNumberAndYear.yearNumber}/${
      weekNumberAndYear.weekNumber
    } - ${this.getCustomFormattedDate(w)}`;
  }


  public getWeekDescriptionWithTimestamp(w: Date) {
    w = new Date(w);
    let weekNumberAndYear = PeriodHelper.getWeekNumberAndYear(w);
    return `${weekNumberAndYear.yearNumber}/${
      weekNumberAndYear.weekNumber
    } - ${this.getFormattedTimestamp(w)}`;
  }

  getFormattedWeek(date: Date) {
    date = new Date(date);
    const weekNumberAndYear = PeriodHelper.getWeekNumberAndYear(date);
    return `${this.translationService.instant('Shell.generic.weekNumber', {weekNumber: weekNumberAndYear.weekNumber})}`;
  }

  getFormattedDateWithoutYear(date: Date) {
    switch(this.selectedDateFormat) {
      case DateFormat.DayMonthYear: return this.datePipe.transform(date, 'dd-MM');
      case DateFormat.MonthDayYear: return this.datePipe.transform(date, 'MM-dd');
      case DateFormat.YearMonthDay: return this.datePipe.transform(date, 'MM-dd');
    }
  }

  public getFormattedDateForChart(currentValueYear: number, nextValueYear: number, nextValueTimeStamp: Date, currentValueTimeStamp: any, yearsAlreadyDisplayed: any[]) {
    let formattedDate = '';

    if (currentValueYear !== nextValueYear) {
      formattedDate = this.getFormattedDateWithoutYear(nextValueTimeStamp);
    }

    if (!yearsAlreadyDisplayed.includes(currentValueYear)) {
      formattedDate = this.getFormattedDate(currentValueTimeStamp);
    } else {
      formattedDate = this.getFormattedDateWithoutYear(currentValueTimeStamp);
    }
    return formattedDate;
  }

}
