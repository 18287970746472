import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityType } from '../enums/entityType';

@Injectable()
export class EntityService {
    constructor(private readonly translationsService: TranslateService) { }

    getEntityTypeDescription(entityType: EntityType) {
        let entityTypeTranslation = '';
        switch (entityType) {
            case EntityType.Organization: {
                entityTypeTranslation = this.translationsService.instant('Shell.generic.organization');
                break;
            }
            case EntityType.Site: {
                entityTypeTranslation = this.translationsService.instant('Shell.generic.site');
                break;
            }
            case EntityType.CropCycle: {
                entityTypeTranslation = this.translationsService.instant('Shell.generic.cropCycle');
                break;
            }
            case EntityType.Zone: {
                entityTypeTranslation = this.translationsService.instant('Shell.generic.zone');
                break;
            }
        }
        return entityTypeTranslation;
    }
}