import { WeekNumberAndYear } from '../models/weekNumberAndYear';
import { NewCalendarPeriodFilter } from '../models/period-selector/newCalendarPeriodFilter';
import * as moment from 'moment';
import { NewPeriodSelectorInterval } from '../enums/newPeriodSelectorInterval';
import { PeriodInterval } from '../models/periodInterval';
import { NewPeriodFilter } from '../models/period-selector/newPeriodFilter';
import { NewPeriodSelectorIntervalType } from '../enums/newPeriodSelectorIntervalType';
import { NewCropCyclePeriodFilter } from '../models/period-selector/newCropCyclePeriodFilter';
import { PeriodSelectorEnum } from '../enums/periodSelectorEnum';
import { CropCyclesPeriodSelectorEnum } from '../enums/cropCyclePeriodSelectorEnum';

export class PeriodHelper {

  public static getWeekNumberAndYear(dt: Date): WeekNumberAndYear {
    const totalWeekMiliseconds = 604800000;
    const tdt = new Date(dt.valueOf());
    const dayNumber = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayNumber + 3);
    const yearOfFirstThursday = tdt.getFullYear();
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }
    const iSO8601WeekNumber =
      1 + Math.ceil((firstThursday - tdt.getTime()) / totalWeekMiliseconds);
    const result: WeekNumberAndYear = {
      weekNumber: iSO8601WeekNumber,
      yearNumber: yearOfFirstThursday,
    };
    return result;
  }

  public static getDateOfISOWeek(week, year) {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  public static getLastWeekNumberOfYear(year: number): WeekNumberAndYear {
    const lastDayOfTheYear = new Date(year, 11, 31);
    let referenceDay = lastDayOfTheYear;
    let lastWeekOfTheYear = PeriodHelper.getWeekNumberAndYear(
      referenceDay
    );
    while (lastWeekOfTheYear.yearNumber > year) {
      referenceDay = new Date(referenceDay.getTime() - (1000 * 60 * 60 * 24));
      lastWeekOfTheYear = PeriodHelper.getWeekNumberAndYear(
        referenceDay
      );
    }
    const result: WeekNumberAndYear = {
      weekNumber: lastWeekOfTheYear.weekNumber,
      yearNumber: year,
    };
    return result;
  }

  public static IsLastWeekOfTheYear(weekNumber) {
    return weekNumber === 52 || weekNumber === 53
  }

  public static getPreviousQuarterDates(selectedPeriodFilter: NewCalendarPeriodFilter) {
    let newStartDate = new Date();
    let newEndDate = new Date();

    let firstMonthOfTheCurrentQuarter = selectedPeriodFilter.startDate.getMonth();

    if (firstMonthOfTheCurrentQuarter === 12) {
      firstMonthOfTheCurrentQuarter = 0;
    }

    switch (firstMonthOfTheCurrentQuarter) {
      case 0: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear() - 1,
          9,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear() - 1,
          11,
          31,
          23,
          59,
          59
        );
        break;
      }
      case 3: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          0,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          2,
          31,
          23,
          59,
          59
        );
        break;
      }
      case 6: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          3,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          5,
          30,
          23,
          59,
          59
        );
        break;
      }
      case 9: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          6,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          8,
          30,
          23,
          59,
          59
        );
        break;
      }
      default:
        break;
    }

    return { newStartDate, newEndDate };
  }

  public static getPreviousCustomPeriodDates(selectedPeriodFilter: NewCalendarPeriodFilter) {
    let newEndDate;
    let newStartDate;
    const previousStart = moment(selectedPeriodFilter.startDate);
    const previousEnd = moment(selectedPeriodFilter.endDate);
    const dayDiff = previousEnd.diff(previousStart, 'days');
    newEndDate = selectedPeriodFilter.startDate;
    newEndDate = moment(newEndDate).add(-1, 'day').endOf('day').toDate();

    newStartDate = moment(newEndDate)
      .add(-dayDiff, 'day')
      .startOf('day')
      .toDate();


    const periodFilter = new NewCalendarPeriodFilter();
    periodFilter.startDate = newStartDate;
    periodFilter.endDate = newEndDate;
    periodFilter.periodInterval = NewPeriodSelectorInterval.Custom;

    return periodFilter;
  }

  public static getNextQuarterDates(selectedPeriodFilter: NewCalendarPeriodFilter) {
    let newStartDate = new Date();
    let newEndDate = new Date();
    let firstMonthOfTheCurrentQuarter = selectedPeriodFilter.startDate.getMonth();

    if (firstMonthOfTheCurrentQuarter === 12) {
      firstMonthOfTheCurrentQuarter = 0;
    }
    switch (firstMonthOfTheCurrentQuarter) {
      case 0: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          3,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          5,
          30,
          23,
          59,
          59
        );
        break;
      }
      case 3: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          6,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          8,
          30,
          23,
          59,
          59
        );
        break;
      }
      case 6: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          9,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear(),
          11,
          31,
          23,
          59,
          59
        );
        break;
      }
      case 9: {
        newStartDate = new Date(
          selectedPeriodFilter.startDate.getFullYear() + 1,
          0,
          1,
          0
        );
        newEndDate = new Date(
          selectedPeriodFilter.startDate.getFullYear() + 1,
          2,
          31,
          23,
          59,
          59
        );
        break;
      }
      default:
        break;
    }

    return { newStartDate, newEndDate };
  }

  public static getNextCustomPeriodDates(selectedPeriodFilter: NewCalendarPeriodFilter) {
    let newStartDate;
    let newEndDate;
    const previousStart = moment(selectedPeriodFilter.startDate);
    const previousEnd = moment(selectedPeriodFilter.endDate);
    const dayDiff = previousEnd.diff(previousStart, 'days');
    newStartDate = moment(selectedPeriodFilter.endDate).add(1, 'day').startOf('day').toDate();

    newEndDate = moment(newStartDate)
      .add(dayDiff, 'day')
      .startOf('day')
      .toDate();

    if (moment(newEndDate).endOf('day') > moment().endOf('day')) {
      newEndDate = moment().endOf('day').toDate();
    }

    const periodFilter = new NewCalendarPeriodFilter();
    periodFilter.startDate = newStartDate;
    periodFilter.endDate = newEndDate;
    periodFilter.periodInterval = NewPeriodSelectorInterval.Custom;

    return periodFilter;
  }

  public static getCropCycleFilterCalendarDates(
    cropCycleStartDate: Date,
    cropCycleEndDate: Date,
    interval: NewPeriodSelectorInterval,
    intervalLength: number): PeriodInterval {
    switch (interval) {
      case NewPeriodSelectorInterval.CropToDate: {
        const today = moment().endOf('day').toDate();
        const endDate = today > cropCycleEndDate
          ? cropCycleEndDate
          : today
        return { startDate: cropCycleStartDate, endDate };
      }
      case NewPeriodSelectorInterval.CropWeek: {
        const startDate = moment(cropCycleStartDate).startOf('day').add(7 * (intervalLength - 1), 'days').toDate()
        const endDate = moment(cropCycleStartDate).endOf('day').add(7 * intervalLength, 'days').toDate()
        return { startDate, endDate };
      }
      case NewPeriodSelectorInterval.ToDate: {
        const startDate = moment(cropCycleStartDate).startOf('day').toDate();
        const endDate = moment(cropCycleStartDate).endOf('day').add(7 * intervalLength, 'days').toDate()
        return { startDate, endDate };
      }
      case NewPeriodSelectorInterval.Full: {
        return { startDate: cropCycleStartDate, endDate: cropCycleEndDate };
      }
    }
    return null;
  }

  public static asCalendarFilter(periodFilter: NewPeriodFilter): NewCalendarPeriodFilter {
    if (periodFilter?.periodIntervalType === NewPeriodSelectorIntervalType.Calendar) {
      return periodFilter as any as NewCalendarPeriodFilter;
    }
    return null;
  }

  public static asCropCycleFilter(periodFilter: NewPeriodFilter): NewCropCyclePeriodFilter {
    if (periodFilter?.periodIntervalType === NewPeriodSelectorIntervalType.Calendar) {
      return null;
    }
    return periodFilter as any as NewCropCyclePeriodFilter;
  }

  public static getPeriodIntervalFromPeriodType(queryPeriodSelectorType: string, isCalendarFilterUrl): string {
    // todo: remove this when all pages use the new period selector

    // Week = 1,
    // Month = 2,
    // Quarter = 3,
    // Year = 4,
    // Custom = 5,
    // FourWeek = 6

    // CropWeek = 1,
    // Full = 2,
    // ToDate = 3
    switch (queryPeriodSelectorType) {
      case '1': return isCalendarFilterUrl ? NewPeriodSelectorInterval.Week.toString() : NewPeriodSelectorInterval.CropWeek.toString();
      case '2': return isCalendarFilterUrl ? NewPeriodSelectorInterval.Month.toString() : NewPeriodSelectorInterval.Full.toString();
      case '3': return isCalendarFilterUrl ? NewPeriodSelectorInterval.Quarter.toString() : NewPeriodSelectorInterval.ToDate.toString();
      case '4': return NewPeriodSelectorInterval.Year.toString();
      case '5': return NewPeriodSelectorInterval.Custom.toString();
      case '6': return NewPeriodSelectorInterval.FourWeek.toString();
    }
  }


  public static getCropCycleDetailsPeriodIntervalFromOldPeriodSelectorPeriodType(queryPeriodSelectorType: string): string {
    // todo: remove this when all pages use the new period selector
    switch (queryPeriodSelectorType) {
      case PeriodSelectorEnum.Week.toString(): return  NewPeriodSelectorInterval.Week.toString() ;
      case PeriodSelectorEnum.Month.toString(): return NewPeriodSelectorInterval.Month.toString() ;
      case PeriodSelectorEnum.Quarter.toString(): return  NewPeriodSelectorInterval.Custom.toString() ;
      case PeriodSelectorEnum.Year.toString(): return NewPeriodSelectorInterval.Custom.toString() ;
      case PeriodSelectorEnum.Custom.toString(): return NewPeriodSelectorInterval.Custom.toString();
      case PeriodSelectorEnum.FourWeek.toString(): return  NewPeriodSelectorInterval.FourWeek.toString();
    }
  }

  public static GetOldPeriodSelectionTypeBasedOnNewPeriodInterval(newPeriodSelectorInterval: NewPeriodSelectorInterval): number{
    switch(newPeriodSelectorInterval){
      case NewPeriodSelectorInterval.FourWeek: return PeriodSelectorEnum.FourWeek;
      case NewPeriodSelectorInterval.Month: return PeriodSelectorEnum.Month;
      case NewPeriodSelectorInterval.Quarter: return PeriodSelectorEnum.Quarter;
      case NewPeriodSelectorInterval.Week: return PeriodSelectorEnum.Week;
      case NewPeriodSelectorInterval.Year: return PeriodSelectorEnum.Year;
      case NewPeriodSelectorInterval.Custom: return PeriodSelectorEnum.Custom;
      default : return PeriodSelectorEnum.Custom;
    }
  }

  public static GetCropCyclePeriodSelectionTypeBasedOnNewPeriodInterval(newPeriodSelectorInterval: NewPeriodSelectorInterval): number{
    switch(newPeriodSelectorInterval){
      case NewPeriodSelectorInterval.CropWeek: return CropCyclesPeriodSelectorEnum.CropWeek;
      case NewPeriodSelectorInterval.ToDate: return CropCyclesPeriodSelectorEnum.ToDate;
      case NewPeriodSelectorInterval.Full: return CropCyclesPeriodSelectorEnum.Full;
    }
  }

  public static getCurrentDate(){
    const currentFullDate = new Date();
    const currentDate = new Date(currentFullDate.getFullYear(), currentFullDate.getMonth(), currentFullDate.getDate());
    return currentDate;
  }

  public static isInTheFuture(prop: any) {
    const date = moment(prop);
    const endOfDay = moment(this.getCurrentDate()).endOf('day');
    return date.isAfter(endOfDay);
  }

  public static getDifferenceInWeeks(prop: any) {
    const date = moment(prop);
    const currentMonday = moment(this.getCurrentDate()).startOf('isoWeek');
    return date.diff(currentMonday,'week');
  }

  public static isExpired(date: Date){
    const currentDate = this.getCurrentDate();
    return currentDate > date;
  }

  public static isDate(input) {
    return Object.prototype.toString.call(input) === "[object Date]";
  }

  public static getDaysBetweenDates(startDate: Date, endDate: Date) {
    let newEndDate;
    let newStartDate;

    if (endDate > startDate) {
      newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
      newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    } else {
      newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0);
      newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59, 999);
    }
    const timeDiff = newStartDate > newEndDate ? Math.abs(newStartDate.getTime() - newEndDate.getTime())
                                               : Math.abs(newEndDate.getTime() - newStartDate.getTime());

    const diffDays = Math.round(timeDiff / (1000 * 3600 * 24));

    return diffDays;
  }

  public static dateEquals(a:Date,b:Date){
    if(this.isDate(a) && this.isDate(b)){
      return (a.toDateString() === b.toDateString());
    }
    return (a === b);
  }

  public static getEndOfToday(){
    return moment(new Date())
    .endOf('day')
    .toDate();
  }

  public static getWeekNumber(dt: Date) {
    const weekNumberAndYear = PeriodHelper.getWeekNumberAndYear(dt);
    return weekNumberAndYear.weekNumber;
  }

  public static getYearShortFormatedPeriod(dt: Date) {
   return dt.getFullYear();
  }
}
