import { Injectable } from '@angular/core';
import { NewPeriodSelectorInterval } from '../enums/newPeriodSelectorInterval';
import { NewPeriodSelectorConfiguration } from '../models/period-selector/newPeriodSelectorConfiguration';
import { ReplaySubject } from 'rxjs';
import { NewCalendarPeriodFilter } from '../models/period-selector/newCalendarPeriodFilter';
import { NewCropCyclePeriodFilter } from '../models/period-selector/newCropCyclePeriodFilter';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NewPeriodFilter } from '../models/period-selector/newPeriodFilter';
import { NewPeriodFilterSelection } from '../models/period-selector/newPeriodFilterSelection';
import { PeriodHelper } from '../helpers/period.helper';
import { NewPeriodSelectorConfigurationType } from '../enums/newPeriodSelectorConfigurationType';
import * as cloneDeep from 'lodash/cloneDeep';
import { CropCycleIntervalApiModel } from '../models/cropCycles/cropCycleIntervalApiModel';

@Injectable({
  providedIn: 'root'
})
export class NewPeriodSelectorService {
  private selectedConfigurationType: NewPeriodSelectorConfigurationType;
  private selectedPeriodFilter: NewPeriodFilter;
  private selectedPeriodFilterPreviousPeriod: NewPeriodFilter;
  private periodSelectorConfiguration$ = new ReplaySubject<NewPeriodSelectorConfiguration>(1);
  private calendarPeriodFilter$ = new ReplaySubject<NewCalendarPeriodFilter>(1);
  private cropCyclePeriodFilter$ = new ReplaySubject<NewCropCyclePeriodFilter>(1);
  private configurations = [
    {
      type: NewPeriodSelectorConfigurationType.OldPeriodSelector, config: {
        visibleItems: [
          NewPeriodSelectorInterval.Week,
          NewPeriodSelectorInterval.FourWeek,
          NewPeriodSelectorInterval.Month,
          NewPeriodSelectorInterval.Quarter,
          NewPeriodSelectorInterval.Year,
          NewPeriodSelectorInterval.Custom],
        defaultItem: NewPeriodSelectorInterval.Month,
      }
    },
    {
      type: NewPeriodSelectorConfigurationType.CropCycleDetails, config: {
        visibleItems: [
          NewPeriodSelectorInterval.Week,
          NewPeriodSelectorInterval.FourWeek,
          NewPeriodSelectorInterval.Month,
          NewPeriodSelectorInterval.CropToDate,
          NewPeriodSelectorInterval.Custom],
        defaultItem: NewPeriodSelectorInterval.CropToDate,
      }
    },
    {
      type: NewPeriodSelectorConfigurationType.CompareCropCycle, config: {
        visibleItems: [
          NewPeriodSelectorInterval.CropWeek,
          NewPeriodSelectorInterval.ToDate,
          NewPeriodSelectorInterval.Full],
        defaultItem: NewPeriodSelectorInterval.CropWeek,
      }
    },
    {
      type: NewPeriodSelectorConfigurationType.Grower, config: {
        visibleItems: [
          NewPeriodSelectorInterval.Day,
          NewPeriodSelectorInterval.Week,
          NewPeriodSelectorInterval.FourWeek,
          NewPeriodSelectorInterval.Month,
          NewPeriodSelectorInterval.Year,
          NewPeriodSelectorInterval.Custom]
      }
    },
    {
      type: NewPeriodSelectorConfigurationType.Empty, config: {
        visibleItems: []
      }
    }
  ]

  constructor(private readonly router: Router, private readonly datePipe: DatePipe) { }

  getPeriodSelectorConfiguration() {
    return this.periodSelectorConfiguration$.asObservable();
  }

  configurePeriodSelector(periodSelectorConfigurationType: NewPeriodSelectorConfigurationType) {
    this.selectedConfigurationType = periodSelectorConfigurationType;
    this.periodSelectorConfiguration$.next(this.configurations.find(c => c.type === periodSelectorConfigurationType)?.config);
  }

  configurePeriodSelectorAndSelectPeriod(periodSelectorConfigurationType: NewPeriodSelectorConfigurationType, filter: NewPeriodFilter, cropCycles?: CropCycleIntervalApiModel[]) {
    this.selectedConfigurationType = periodSelectorConfigurationType;
    const configuration = cloneDeep(this.configurations.find(c => c.type === periodSelectorConfigurationType)?.config);
    configuration.selectedFilter = filter;
    configuration.cropCycles = cropCycles;
    this.periodSelectorConfiguration$.next(configuration);
  }

  setPeriodFilter(selection: NewPeriodFilterSelection) {
    this.selectedPeriodFilter = selection.currentPeriod;
    this.selectedPeriodFilterPreviousPeriod = selection.previousPeriod ?? null;
    this.updatePeriodSelectorUrl();
    if (PeriodHelper.asCalendarFilter(this.selectedPeriodFilter)) {
      this.calendarPeriodFilter$.next(PeriodHelper.asCalendarFilter(this.selectedPeriodFilter));
    } else {
      this.cropCyclePeriodFilter$.next(PeriodHelper.asCropCycleFilter(this.selectedPeriodFilter));
    }
  }

  getCalendarPeriodFilter() {
    return this.calendarPeriodFilter$.asObservable();
  }

  getCropCyclePeriodFilter() {
    return this.cropCyclePeriodFilter$.asObservable();
  }

  getCalendarPeriodFilterPreviousInterval() {
    return PeriodHelper.asCalendarFilter(this.selectedPeriodFilterPreviousPeriod);
  }

  getCropCyclePeriodFilterPreviousInterval() {
    return PeriodHelper.asCropCycleFilter(this.selectedPeriodFilterPreviousPeriod);
  }

  updatePeriodSelectorUrl() {
    const queryParams = {
      periodSelectorConfiguration: this.selectedConfigurationType,
      periodSelectorInterval: this.selectedPeriodFilter.periodInterval,
      periodSelectorStartDate: null,
      periodSelectorEndDate: null,
      periodSelectorIntervalLength: null,
      // todo: remove these when all pages use the new period selector
      periodSelectorType: null,
      startDate: null,
      endDate: null,
      weekNumber: null
    }
    const calendarFilter = PeriodHelper.asCalendarFilter(this.selectedPeriodFilter);
    if (calendarFilter) {
      queryParams.periodSelectorStartDate = this.datePipe.transform(calendarFilter.startDate, 'shortDate');
      queryParams.periodSelectorEndDate = this.datePipe.transform(calendarFilter.endDate, 'shortDate');
    } else {
      const intervalLength = PeriodHelper.asCropCycleFilter(this.selectedPeriodFilter).intervalLength;
      queryParams.periodSelectorIntervalLength = isNaN(intervalLength) ? null : intervalLength;
    }

    const urlTree = this.router.createUrlTree([], {
      queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
    this.router.navigateByUrl(urlTree.toString(), {
      replaceUrl: true
    });
  }

  resetPeriodSelector() {
    this.periodSelectorConfiguration$ = new ReplaySubject<NewPeriodSelectorConfiguration>(1);
    this.calendarPeriodFilter$ = new ReplaySubject<NewCalendarPeriodFilter>(1);
    this.cropCyclePeriodFilter$ = new ReplaySubject<NewCropCyclePeriodFilter>(1);
  }
}
