import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GraphStatistics } from "src/app/core/enums/graphStatistics";
import { ResourceSourceTypeEnum } from "src/app/core/enums/resourceSourceTypeEnum";
import { SortingHelper, SortOrder } from "src/app/core/helpers/sorting.helper";
import { ComparisonResourceApiModel } from "src/app/core/models/comparisonResourceApiModel";
import { SerieDescriptionDto } from "src/app/core/models/serieDescriptionDto";
import { EntityType } from "../enums/entityType";
import { ComparisonToolViewType } from "../enums/viewType";
import { SavedViewScatterplotSerieConfigurationApiModel } from "../models/savedViewScatterplotSerieConfigurationApiModel";
import { EntityService } from "../services/entity.service";
import { ResourceClassificationSourceType } from "../enums/resourceClassificationSourceType";

@Injectable()
export class ComparisonLabelHelper {
  constructor(
    private readonly translationService: TranslateService,
    private readonly entityService: EntityService
  ) { }

  getResourceDisplayName(apiModel: ComparisonResourceApiModel): string {
    const label = this.getDisplayLabel(apiModel.resourceClassificationSourceType, apiModel.resourceSourceType, apiModel.resourceClassificationIdentifier);
    if (apiModel.unitSymbol !== undefined && apiModel.unitSymbol !== '') {
      return `${label} (${apiModel.unitSymbol})`;
    }
    return label;
  }

  getDisplayLabel(resourceClassificationSourceType: ResourceClassificationSourceType, resourceSourceType: ResourceSourceTypeEnum, resourceClassificationIdentifier: string) {
    return this.translationService.instant(`ResourceLabel.${this.getPrefix(resourceClassificationSourceType, resourceSourceType)}${resourceClassificationIdentifier}`);
  }

  getPrefix(resourceClassificationSourceType: ResourceClassificationSourceType, resourceSourceType: ResourceSourceTypeEnum) {
    switch (resourceClassificationSourceType) {
      case ResourceClassificationSourceType.ResourceInstances:
        return '';
      case ResourceClassificationSourceType.Forecasts:
        {
          if (resourceSourceType === ResourceSourceTypeEnum.Manual) {
            return 'forecast.manual.';
          }
          if (resourceSourceType === ResourceSourceTypeEnum.Calculation) {
            return 'forecast.calculated.';
          }
        }
        break;
      case ResourceClassificationSourceType.CropRegistrationResourceInstances:
        return 'cropRegistration.';
      case ResourceClassificationSourceType.ResourcePlans:
        return 'resourcePlan.';
    }
  }

  public getGraphTitle(series: SerieDescriptionDto[], selectedViewType: ComparisonToolViewType): string {
    if (selectedViewType == ComparisonToolViewType.MultipleGraphs) {
      return this.getResourceDisplayName(
        {
          resourceClassificationIdentifier: series[0].resourceClassification.resourceClassificationIdentifier,
          label: '',
          resourceClassificationSourceType: series[0].resourceClassificationSourceType,
          unitSymbol: series[0].unitSymbol,
          resourceSourceType: series[0].resourceSourceType,
          graphStatistics: GraphStatistics.Legend
        }
      );
    } else {
      const classifications = [];
      const labels = [];
      series.forEach(element => {
        if (classifications.find(r =>
          r.resourceClassificationIdentifier === element.resourceClassification.resourceClassificationIdentifier &&
          r.resourceClassificationSourceType === element.resourceClassificationSourceType &&
          r.resourceSourceType === element.resourceSourceType
        ) === undefined) {
          const c = {
            resourceClassificationIdentifier: element.resourceClassification.resourceClassificationIdentifier,
            label: '',
            resourceClassificationSourceType: element.resourceClassificationSourceType,
            unitSymbol: element.unitSymbol,
            resourceSourceType: element.resourceSourceType,
            graphStatistics: GraphStatistics.Legend
          }

          classifications.push(c);
          labels.push(this.getResourceDisplayName(c));
        }
      });
      return labels.sort().join(", ");
    }
  }

  public getDescription(series: SerieDescriptionDto[], selectedViewType: ComparisonToolViewType, entityTypeDescription: string): string {
    if (selectedViewType == ComparisonToolViewType.MultipleGraphs) {

      return this.translationService.instant(
        `ResourceDescription.${this.getPrefix(
          series[0].resourceClassificationSourceType,
          series[0].resourceSourceType
        )}${series[0].resourceClassification.resourceClassificationIdentifier}`,
        {
          UnitSymbol: series[0].unitSymbol,
          DenominatorUnitSymbol: series[0].denominatorUnitSymbol,
          EntityType: !!entityTypeDescription ? entityTypeDescription : this.getEntityDescription(series[0]),
        }
      );
    } else {
      let classifications = [];
      series.forEach(serie => {
        if (classifications.find(r =>
          r.resourceClassificationIdentifier === serie.resourceClassification.resourceClassificationIdentifier &&
          r.resourceClassificationSourceType === serie.resourceClassificationSourceType &&
          r.resourceSourceType === serie.resourceSourceType
        ) === undefined) {
          classifications.push({
            resourceClassificationIdentifier: serie.resourceClassification.resourceClassificationIdentifier,
            resourceClassificationSourceType: serie.resourceClassificationSourceType,
            resourceSourceType: serie.resourceSourceType,
            label: serie.translatedName,
            description: this.translationService.instant(
              `ResourceDescription.${this.getPrefix(serie.resourceClassificationSourceType,
                serie.resourceSourceType
              )}${serie.resourceClassification.resourceClassificationIdentifier}`,
              {
                UnitSymbol: serie.unitSymbol,
                DenominatorUnitSymbol: serie.denominatorUnitSymbol,
                EntityType: !!entityTypeDescription ? entityTypeDescription : this.getEntityDescription(serie),
              }
            ),
          });
        }
      });

      classifications = classifications.sort(SortingHelper.sortBy('label', SortOrder.Ascending));

      return classifications.map(a => a.description).filter(this.onlyUnique).join("<br/>");// usage example:
    }
  }

  public getEntityDescription(seriesOrSavedConfiguration: any): string {
    let entityType: EntityType;
    switch (!!seriesOrSavedConfiguration) {
      case !!seriesOrSavedConfiguration.siteId:
        entityType = EntityType.Site;
        break;
      case !!seriesOrSavedConfiguration.zoneId:
        entityType = EntityType.Zone;
        break;
      case !!seriesOrSavedConfiguration.cropCycleId:
        entityType = EntityType.CropCycle;
        break;
      default: return '';
    }
    return this.entityService.getEntityTypeDescription(entityType);
  }

  public getScatterPlotDescription(config: SavedViewScatterplotSerieConfigurationApiModel): string {
    const nominatorClassification = config.comparisonScatterPlot.comparisonResourceX;
    const denominatorClassification = config.comparisonScatterPlot.comparisonResourceY;

    const resourceXDescription: string = this.translationService.instant(
      `ResourceDescription.${this.getPrefix(nominatorClassification.resourceClassificationSourceType, nominatorClassification.resourceSourceType)}${nominatorClassification.resourceClassificationIdentifier}`,
      {
        UnitSymbol: nominatorClassification.unitSymbol,
        EntityType: this.getEntityDescription(config)
      }
    );
    const resourceYDescription: string = this.translationService.instant(
      `ResourceDescription.${this.getPrefix(denominatorClassification.resourceClassificationSourceType, denominatorClassification.resourceSourceType)}${denominatorClassification.resourceClassificationIdentifier}`,
      {
        UnitSymbol: denominatorClassification.unitSymbol,
        EntityType: this.getEntityDescription(config)
      }
    );

    return resourceXDescription === resourceYDescription ? resourceXDescription : resourceXDescription + "<br/>" + resourceYDescription;
  }

  private onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
}

