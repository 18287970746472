import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { User } from '../models/user';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { OnboardingUsersApiModel } from 'src/app/onboarding/models/onboardingUsersApiModel';
import { OnboardingUserApiModel } from 'src/app/onboarding/models/onboardingUserApiModel';
import { shareReplay } from 'rxjs/operators';
import { CacheService } from 'src/app/core/services/cache.service';
import { UserOrganizationsDetails } from '../models/userOrganizationsDetails';

@Injectable()
export class UsersService {
  private readonly configuration: ClientConfiguration;
  private cacheService: CacheService = new CacheService();

  public userRoleChanged$ = new Subject<{ changed: boolean }>();

  setUserRoleHasChanged(value: boolean) {
    this.userRoleChanged$.next({ changed: value });
  }

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  createLoggedinUser() {
    this.cacheService.clearCache();
    const url = `${this.configuration.organizationsApiBaseUrl}/users/CreateLoggedinUser?language=${navigator.language}`;
    return this.httpClient.post(url, null);
  }

  getUserOrganizationsDetails(isDataReloadRequired : boolean = false): Observable<UserOrganizationsDetails> {
    if (isDataReloadRequired) {
      this.cacheService.removeFromCache('getUserOrganizationsDetails');
    }

    if (!this.cacheService.isInCache('getUserOrganizationsDetails')) {
      const url = `${this.configuration.organizationsApiBaseUrl}/users/GetUserOrganizationsDetails`;
      this.cacheService.addToCache('getUserOrganizationsDetails', this.httpClient.get<boolean>(url).pipe(shareReplay(1)));
    }
    return this.cacheService.getFromCache('getUserOrganizationsDetails');
  }

  getUser(id: number): Observable<User> {
    const url = `${this.configuration.organizationsApiBaseUrl}/users/getById/${id}`;
    return this.httpClient.get<User>(url);
  }

  updateUser(user) {
    const url = `${this.configuration.organizationsApiBaseUrl}/users/updateUser`;
    return this.httpClient.put(url, user, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }

  isServiceUser(): Observable<boolean> {
    if (!this.cacheService.isInCache('isServiceUser')) {
      const url = `${this.configuration.platformAdministrationApiBaseUrl}/PlatformAdministration/isUserServiceUser`;
      this.cacheService.addToCache('isServiceUser', this.httpClient.get<boolean>(url).pipe(shareReplay(1)));
    }
    return this.cacheService.getFromCache('isServiceUser');
  }

  isConsultant(): Observable<boolean> {
    if (!this.cacheService.isInCache('isConsultantUser')) {
      const url = `${this.configuration.platformAdministrationApiBaseUrl}/PlatformAdministration/isUserConsultant`;
      this.cacheService.addToCache('isConsultantUser', this.httpClient.get<boolean>(url).pipe(shareReplay(1)));
    }
    return this.cacheService.getFromCache('isConsultantUser');
  }

  hasServiceUserAccessToOrganization(organizationId: number, isDataReloadRequired: boolean = false): Observable<boolean> {
    if (isDataReloadRequired) {
      this.cacheService.removeFromCache(`hasServiceUserAccessToOrganization${organizationId}`);
    }
    if (!this.cacheService.isInCache(`hasServiceUserAccessToOrganization${organizationId}`)) {
      const url = `${this.configuration.platformAdministrationApiBaseUrl}/PlatformAdministration/HasServiceUserAccessToOrganization/${organizationId}`;
      this.cacheService.addToCache(
        `hasServiceUserAccessToOrganization${organizationId}`,
        this.httpClient.get<boolean>(url).pipe(shareReplay(1))
      );
    }
    return this.cacheService.getFromCache(`hasServiceUserAccessToOrganization${organizationId}`);
  }

  registerUsers(users: OnboardingUsersApiModel) {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/registerUsers`;
    return this.httpClient.post(url, users);
  }

  getOnboardingUsers(organizationId: number) {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizations/getOnboardingUsers/${organizationId}`;
    return this.httpClient.get<OnboardingUserApiModel[]>(url);
  }
}
