import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalFormattingPipe implements PipeTransform {
  static DECIMAL_PLACES = 2;
  constructor() {
  }

  transform(value: any, ...args: any[]): any {
    return Number(value).toFixed(DecimalFormattingPipe.DECIMAL_PLACES);
  }

}
