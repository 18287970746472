// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: true,
   /*
   This is only used and configured for local development in oder to bypass the ASP Core shell.
   This must be kept in sync while the asp core configuration evolves.
   */
   clientConfiguration: {
      msalConfig: {
         authTenant: 'ridderb2c.onmicrosoft.com',
         clientId: '07aab67f-eb87-4bed-9fd7-ac5be4317939',
         validateAuthority: false,
         navigateToLoginRequestUrl: false,
         scopes: ['https://ridderb2c.onmicrosoft.com/RidderConnectAPI/Read'],
         defaultUserAuthSettings: {
            signinPolicy: 'B2C_1_Signin',
            signupPolicy: 'B2C_1_Signup',
            resetPasswordPolicy: 'B2C_1_PasswordReset',
            loginUrl: 'https://ridderb2c.b2clogin.com/',
         },
         ridderUserAuthSettings: {
            signinPolicy: 'B2C_1A_signup_signin',
            loginUrl: 'https://ridderb2c.b2clogin.com/',
            extraQueryParameters: { ui_locales: 'nl', domain_hint: 'Ridder' }
         }
      },
      environment: '',
      googleAnalyticsMeasurementId: '',
      handsonLicenseKey: '90b54-410a6-04a43-9492c-0eb2d',
      devextremeLicenseKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjM2ZDE0YWQ1LTcyZDgtNDk5MC1hYTczLWU0NzYwNmEzMWI1MiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjMyCn0=.YlaLFjav+0GnGzB+xTwwaxNSyI+8RnNK5BCnCCRoy4I28YVI/YmbepOt+MLFrCk7MnYUfNlfoRl8NYSP8ij1SzOJuoqEzGRPjQnvG0gDGmsWjjaujmf0JS+aHCjWR/csYCavzg==',

      // LOCAL:
      organizationsApiBaseUrl: 'https://localhost:44336/api',
      resourceApiBaseUrl: 'https://localhost:44311/api',
      translationsApiBaseUrl: 'https://localhost:44335/api',
      socialApiBaseUrl: 'https://localhost:44373/api',
      socialApiSignalRBaseUrl: "https://localhost:44373",
      platformAdministrationApiBaseUrl: "https://localhost:44337/api",
      yieldPredictionsApiBaseUrl: "https://localhost:44340/api",
      comparisonToolApiBaseUrl: "https://localhost:44314/api",
      co2OptimizerApiBaseUrl: "https://localhost:44329/api"

      // DEV:
      //organizationsApiBaseUrl: 'https://dev.hortos.ridder.com/internal/api/organization',
      //resourceApiBaseUrl: 'https://dev.hortos.ridder.com/internal/api/resources',
      //socialApiBaseUrl: 'https://dev.hortos.ridder.com/internal/api/social',
      //socialApiSignalRBaseUrl: "https://dev.hortos.ridder.com/public/api/social",
      //translationsApiBaseUrl: 'https://dev.hortos.ridder.com/internal/api/translations',
      //platformAdministrationApiBaseUrl: "https://dev.hortos.ridder.com/internal/api/platformadmin",
      //yieldPredictionsApiBaseUrl: "https://dev.hortos.ridder.com/internal/api/yieldpredictions",
      //comparisonToolApiBaseUrl: "https://dev.hortos.ridder.com/internal/api/comparisontool",
      //co2OptimizerApiBaseUrl: "https://dev.hortos.ridder.com/internal/api/co2optimizer"

      // TEST:
      //organizationsApiBaseUrl: 'https://test.hortos.ridder.com/internal/api/organization',
      //resourceApiBaseUrl: 'https://test.hortos.ridder.com/internal/api/resources',
      //socialApiBaseUrl: 'https://test.hortos.ridder.com/internal/api/social',
      //socialApiSignalRBaseUrl: "https://test.hortos.ridder.com/public/api/social",
      //translationsApiBaseUrl: 'https://test.hortos.ridder.com/internal/api/translations',
      //platformAdministrationApiBaseUrl: "https://test.hortos.ridder.com/internal/api/platformadmin",
      //yieldPredictionsApiBaseUrl: "https://test.hortos.ridder.com/internal/api/yieldpredictions",
      //comparisonToolApiBaseUrl: "https://test.hortos.ridder.com/internal/api/comparisontool",
      //co2OptimizerApiBaseUrl: "https://test.hortos.ridder.com/internal/api/co2optimizer"

      // PERF:
      //organizationsApiBaseUrl: 'https://perf.hortos.ridder.com/internal/api/organization',
      //resourceApiBaseUrl: 'https://perf.hortos.ridder.com/internal/api/resources',
      //socialApiBaseUrl: 'https://perf.hortos.ridder.com/internal/api/social',
      //socialApiSignalRBaseUrl: "https://perf.hortos.ridder.com/public/api/social",
      //translationsApiBaseUrl: 'https://perf.hortos.ridder.com/internal/api/translations',
      //platformAdministrationApiBaseUrl: "https://perf.hortos.ridder.com/internal/api/platformadmin",
      //yieldPredictionsApiBaseUrl: "https://perf.hortos.ridder.com/internal/api/yieldpredictions",
      //comparisonToolApiBaseUrl: "https://perf.hortos.ridder.com/internal/api/comparisontool",
      //co2OptimizerApiBaseUrl: "https://perf.hortos.ridder.com/internal/api/co2optimizer"



      // PROD:
      //organizationsApiBaseUrl: 'https://hortos.ridder.com/internal/api/organization',
      //resourceApiBaseUrl: 'https://hortos.ridder.com/internal/api/resources',
      //socialApiBaseUrl: 'https://hortos.ridder.com/internal/api/social',
      //socialApiSignalRBaseUrl: "https://hortos.ridder.com/public/api/social",
      //translationsApiBaseUrl: 'https://hortos.ridder.com/internal/api/translations',
      //platformAdministrationApiBaseUrl: "https://hortos.ridder.com/internal/api/platformadmin",
      //yieldPredictionsApiBaseUrl: "https://hortos.ridder.com/internal/api/yieldpredictions",
      //comparisonToolApiBaseUrl: "https://hortos.ridder.com/internal/api/comparisontool",
      //co2OptimizerApiBaseUrl: "https://hortos.ridder.com/internal/api/co2optimizer"
   }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
