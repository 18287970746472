import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClientConfiguration } from "src/app/configuration/models/client-configuration.model";
import { ConfigurationService } from "src/app/configuration/services/configuration.service";

@Injectable()
export class CultivarsService {
  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  getCultivars(): Observable<string[]> {
    const url = `${this.configuration.yieldPredictionsApiBaseUrl}/cultivars/GetCultivars`;
    return this.httpClient.get<string[]>(url);
  }
}