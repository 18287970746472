import * as moment from 'moment';
import { Constants } from '../constants';
import { CropCycleIntervalApiModel } from '../models/cropCycles/cropCycleIntervalApiModel';

export class CropCyclesPeriodHelper {
  public static getCropCyclesMinStartDateAndMaxEndDate(cropCycles: CropCycleIntervalApiModel[]) {
    let minCropCyclesStartDate;
    let maxCropCyclesEndDate;
    cropCycles.forEach((cropCycle) => {
      if (
        !minCropCyclesStartDate ||
        moment(cropCycle.startDate) < minCropCyclesStartDate
      ) {
        minCropCyclesStartDate = moment(cropCycle.startDate).startOf('day');
      }
      if (
        !maxCropCyclesEndDate ||
        moment(cropCycle.endDate) > maxCropCyclesEndDate
      ) {
        maxCropCyclesEndDate = moment(cropCycle.endDate).endOf('day');
      }
    });
    if (!minCropCyclesStartDate) {
      minCropCyclesStartDate = moment();
    }
    if (!maxCropCyclesEndDate) {
      maxCropCyclesEndDate = moment();
    }
    return { minCropCyclesStartDate, maxCropCyclesEndDate };
  }

  public static getMaxWeekCountForCropCycles(cropCycles: CropCycleIntervalApiModel[]) {
    const activeCropCyclesWeeks = cropCycles
      .filter((x) => moment(x.endDate) >= moment())
      .map((o) =>
        Math.ceil(
          moment()
            .endOf('day')
            .diff(moment(o.startDate).startOf('day'), 'hours') /
            Constants.NUMBER_OF_HOURS_IN_A_WEEK
        )
      );
    const inactiveCropCyclesWeeks = cropCycles
      .filter((x) => moment(x.endDate) < moment())
      .map((o) =>
        Math.ceil(
          moment(o.endDate)
            .endOf('day')
            .diff(moment(o.startDate).startOf('day'), 'hours') /
            Constants.NUMBER_OF_HOURS_IN_A_WEEK
        )
      );
    const toDateActiveCropCycleMinWeeks = Math.min.apply(
      Math,
      activeCropCyclesWeeks
    );
    const toDateInactiveCropCycleMinWeeks = Math.min.apply(
      Math,
      inactiveCropCyclesWeeks
    );

    if (
      toDateActiveCropCycleMinWeeks === 0 ||
      toDateInactiveCropCycleMinWeeks === 0
    ) {
      return Math.max(
        toDateActiveCropCycleMinWeeks,
        toDateInactiveCropCycleMinWeeks
      );
    } else {
      return Math.min(
        toDateActiveCropCycleMinWeeks,
        toDateInactiveCropCycleMinWeeks
      );
    }
  }
}
