import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OnboardingStatus } from '../models/onboardingStatus';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { Site } from 'src/app/core/models/site';
import { OrganizationOnboardingFlow } from '../models/organizationOnboardingFlow';
import { CropCycleApiModel } from 'src/app/core/models/cropCycles/cropCycleApiModel';

@Injectable({
  providedIn: 'root',
})
export class OnboardingStatusService {
  private readonly configuration: ClientConfiguration;

  // tslint:disable-next-line: max-line-length
  public onboardingStatusChanged$ = new BehaviorSubject<OnboardingStatus>({
    isUserAdministratorOfOrganization: false,
    progress: 0,
    rules: [],
    isOrganizationOnboardingCompleted: false
  });

  constructor(
    private readonly httpClient: HttpClient,
    configurationService: ConfigurationService
  ) {
    this.configuration = configurationService.configuration;
  }

  loadOrganizationOnboardingStatus(organizationId) {
    const url = `${this.configuration.organizationsApiBaseUrl}/users/GetOnboardingStatus/${organizationId}`;
    this.httpClient.get<OnboardingStatus>(url).subscribe((status) => {
      this.onboardingStatusChanged$.next(status);
    });
  }

  getUserOnboardingStatus(): Observable<boolean> {
    const url = `${this.configuration.organizationsApiBaseUrl}/userSettings/getUserOnboardingStatus`;
    return this.httpClient.get<boolean>(url);
  }

  getOnboardingRules(organizationId: number): Observable<OnboardingStatus> {
    const url = `${this.configuration.organizationsApiBaseUrl}/users/GetOnboardingStatus/${organizationId}`;
    return this.httpClient.get<OnboardingStatus>(url);
  }

  getSite(siteId: number): Observable<Site> {
    const url = `${this.configuration.organizationsApiBaseUrl}/sites/${siteId}`;
    return this.httpClient.get<Site>(url);
  }

  getCropCycle(id: number) {
    const url = `${this.configuration.resourceApiBaseUrl}/cropcycles/GetCropCycle/${id}`;
    return this.httpClient.get<CropCycleApiModel>(url);
  }

  isOrganizationOnboardingCompleted(organizationId: number) {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizationonboarding/isOrganizationOnboardingCompleted/${organizationId}`;
    return this.httpClient.get<boolean>(url);
  }

  getOrganizationOnboardingFlows(
    organizationId: number
  ): Observable<OrganizationOnboardingFlow[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizationonboarding/GetOrganizationOnboardingFlows/${organizationId}`;
    return this.httpClient.get<OrganizationOnboardingFlow[]>(url);
  }

  completeOrganizationOnboarding(organizationId: number) {
    const url = `${this.configuration.organizationsApiBaseUrl}/organizationonboarding/CompleteOrganizationOnboarding/${organizationId}`;
    return this.httpClient.put(url, null);
  }
}
