import { Injectable } from '@angular/core';
import { UserOrganizationRole } from 'src/app/auth/models/UserOrganizationRole';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';
import { OrganizationLicenseService } from 'src/app/core/services/organization-license.service';
import { OrganizationLicenseModule } from 'src/app/core/models/organizationLicenseModule';
import { OrganizationLicenseModuleType } from 'src/app/core/enums/organizationLicenseModuleType';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingStatusService } from 'src/app/onboarding/services/onboarding-status.service';
import { Constants } from 'src/app/core/constants';
import { UserOrganizationDetails } from 'src/app/auth/models/userOrganizationDetails';


@Injectable()
export class LandingPageService {

  urlHandlers: any[];
  loggedInUserDetails: { isPlatformAdmin: boolean; isServiceUser: boolean; organizationsDetails: UserOrganizationDetails[], needsToNavigateToUserOnboardingPage: boolean; };
  organizationId: number;
  private readonly ORGANIZATIONS_PATH = 'organizations/';
  constructor(
    private readonly appSettingsService: AppSettingsService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly notificationsService: NotificationService,
    private readonly organizationLicenseService: OrganizationLicenseService,
    private readonly onboardingService: OnboardingStatusService,
    private readonly translationsService: TranslateService) {
    this.urlHandlers = [
      {
        name: 'devicesOnboardingUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/onboarding/devices') === 0;
        },
        canNavigateToUrl: () => {
          return true;
        }
      },
      {
        name: 'onboardingUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf(`/organizations/${this.organizationId}/onboarding`) === 0;
        },
        canNavigateToUrl: () => {
          return true;
        }
      },
      {
        name: 'handleInvitationUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/organizations-settings/handleinvitation') === 0;
        },
        canNavigateToUrl: () => {
          return true;
        }
      },
      {
        name: 'userProfileUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/profile-settings') === 0;
        },
        canNavigateToUrl: () => {
          return true;
        }
      },
      {
        name: 'organizationSelectionUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/select-organization') === 0;
        },
        canNavigateToUrl: () => {
          return (this.loggedInUserDetails.organizationsDetails.length > 1) ||
            this.loggedInUserDetails.isServiceUser;
        }
      },
      {
        name: 'platformAdminUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/platform-administration') === 0;
        },
        canNavigateToUrl: () => {
          return this.loggedInUserDetails.isPlatformAdmin;
        }
      },
      {
        name: 'organizationAdminUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/organizations-settings') === 0;
        },
        canNavigateToUrl: () => {
          return (this.loggedInUserDetails.organizationsDetails
            .find(o => o.organizationId === this.organizationId && o.userRole === UserOrganizationRole.Administrator) !== undefined) ||
            this.loggedInUserDetails.isServiceUser;
        }
      },
      {
        name: 'organizationMemberUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf(this.ORGANIZATIONS_PATH) >= 0;
        },
        canNavigateToUrl: () => {
          const organizationDetails = this.loggedInUserDetails.organizationsDetails.find(org => org.organizationId === this.organizationId);
          if (this.loggedInUserDetails.isServiceUser) {
              return true;
          } else if(organizationDetails && organizationDetails.organizationUserGroupsExists) {
              if(!organizationDetails.userPartOfAnyUserGroup) {
                this.router.navigateByUrl(`organizations/${this.organizationId}/user-without-group`);
                return false;
              }
              return true;
            }
             else {
            return true;
          }
        }
       },
      {
        name: 'privacyStatementUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/privacy-statement') === 0;
        },
        canNavigateToUrl: () => { return true; }
      },
      {
        name: 'releaseNotesUrlHandler',
        urlMatcher: () => {
          return this.router.url.indexOf('/release-notes') === 0;
        },
        canNavigateToUrl: () => { return true; }
      },

    ]
  }

  navigateToLandingPage(activatedRoute: ActivatedRoute) {
    if (this.authService.isLoggedIn()) {
      this.authService.getLoggedInUserDetails().subscribe(details => {
        this.loggedInUserDetails = details;
        if(this.loggedInUserDetails.needsToNavigateToUserOnboardingPage === true && sessionStorage.getItem('isOnboardingUserVisited') !== 'true'){
          this.router.navigateByUrl('profile-settings/onboarding');
        } else {
          this.organizationId = +this.tryGetSelectedOrganization(activatedRoute);
          if (this.organizationId !== undefined && this.organizationId !== 0) {
            if (details.isServiceUser || details.organizationsDetails.find(o => o.organizationId === this.organizationId)) {
              this.appSettingsService.setOrganizationFilter(this.organizationId);
            }
            this.handleUrl();
          } else {
            this.handleUrl();
          }
        }
      });
    }
  }

  handleUrl() {
    const urlHandler = this.urlHandlers.find(handler => handler.urlMatcher.bind(this)());
    if (!urlHandler || !urlHandler.canNavigateToUrl.bind(this)()) {
      this.navigateToNoOrganizationSelectedPage();
    }
  }

  tryGetSelectedOrganization(activatedRoute) {
    let organizationId = this.tryGetSelectedOrganizationFromRoute(activatedRoute);
    if (organizationId === undefined) {
      organizationId = +localStorage.getItem('userOrganizationId');
    }
    return organizationId;
  }

  tryGetSelectedOrganizationFromRoute(activatedRoute) {
    let route = activatedRoute.snapshot;
    let organizationId;
    while (route !== null && organizationId === undefined) {
      organizationId = route.params.organizationId;
      route = route.firstChild;
    }
    return organizationId;
  }

  navigateToNoOrganizationSelectedPage() {
    const navigationError = sessionStorage.getItem('navigationFailed');
    if (navigationError) {
      sessionStorage.removeItem('navigationFailed');
      this.notificationsService.showError(this.translationsService.instant('Shell.navigation.restrictedUrl'));
    }
    const organization = this.loggedInUserDetails.organizationsDetails.find(o => o.organizationId === this.organizationId);
    if (this.organizationId !== undefined && this.organizationId !== 0)
    {
      if (this.loggedInUserDetails.isServiceUser) {
          this.navigateToTheFirstAvailableLicensedModule(this.organizationId);
      } else if(organization) {
          if (organization.organizationUserGroupsExists) {
          this.navigateToTheFirstAvailableLicensedModule(this.organizationId, organization.userPartOfAnyUserGroup);
        } else {
          this.navigateToTheFirstAvailableLicensedModule(this.organizationId);
        }
      }
    } else {
      if (this.loggedInUserDetails.organizationsDetails.length < 1 && !this.loggedInUserDetails.isServiceUser) {
        this.router.navigateByUrl('user-without-organization');
      } else if (this.loggedInUserDetails.organizationsDetails.length === 1 && !this.loggedInUserDetails.isServiceUser) {
        const userOrganizationId = this.loggedInUserDetails.organizationsDetails[0].organizationId;
        this.appSettingsService.setOrganizationFilter(userOrganizationId);
          if (organization.organizationUserGroupsExists) {
        if (this.router.url.indexOf(this.ORGANIZATIONS_PATH) === 0) {
           if (organization.userPartOfAnyUserGroup) {
            this.router.navigateByUrl(`/`);
          } else {
            this.router.navigateByUrl(`${this.ORGANIZATIONS_PATH}${userOrganizationId}/user-without-group`);
          }
        }
        this.navigateToTheFirstAvailableLicensedModule(userOrganizationId, organization.userPartOfAnyUserGroup);
       } else {
        this.navigateToTheFirstAvailableLicensedModule(userOrganizationId);
       }
      } else if (this.loggedInUserDetails.organizationsDetails.length > 1 || this.loggedInUserDetails.isServiceUser) {
        this.router.navigateByUrl('select-organization');
      }
    }
  }

  navigateToTheFirstAvailableLicensedModule(organizationId: number, isPartOfAnyGroup? : boolean) {
    this.onboardingService.isOrganizationOnboardingCompleted(organizationId).subscribe(isOnboarded => {
    if (!isOnboarded) {
      this.router.navigateByUrl(`organizations/${organizationId}/onboarding/organization-onboarding-overview`);
    } else {
    this.organizationLicenseService.getOrganizationActiveLicensedModules(organizationId).subscribe(licenseModules => {
      const firstAvailableLicensedModule = this.getFirstAvailableLicensedModule(licenseModules)
      if (this.isFirstAvailableModuleNotAvailable(firstAvailableLicensedModule)) {
        this.router.navigateByUrl(`${this.ORGANIZATIONS_PATH}${organizationId}/organization-unlicensed-module`);
      } else if (isPartOfAnyGroup === false) {
        this.router.navigateByUrl(`${this.ORGANIZATIONS_PATH}${organizationId}/user-without-group`);
      }
      else {
        this.navigateToOrganizationModule(firstAvailableLicensedModule,organizationId);
      }
    });
  }});
  }

  isFirstAvailableModuleNotAvailable(firstAvailableLicensedModule){
    return firstAvailableLicensedModule?.organizationModule !== OrganizationLicenseModuleType.Manager &&
    firstAvailableLicensedModule?.organizationModule !== OrganizationLicenseModuleType.Grower  &&
    firstAvailableLicensedModule?.organizationModule !== OrganizationLicenseModuleType.YieldPredictions &&
    firstAvailableLicensedModule?.organizationModule !== OrganizationLicenseModuleType.ControlApi &&
    firstAvailableLicensedModule?.organizationModule !== OrganizationLicenseModuleType.CO2Optimizer &&
    firstAvailableLicensedModule?.organizationModule !== OrganizationLicenseModuleType.MCA;
  }

  navigateToOrganizationModule(firstAvailableLicensedModule,organizationId){
    switch (firstAvailableLicensedModule.organizationModule) {
      case OrganizationLicenseModuleType.Manager:
        this.router.navigateByUrl(`manager/organizations/${organizationId}/home`);
        break;
      case OrganizationLicenseModuleType.Grower:
        this.router.navigateByUrl(`grower/organizations/${organizationId}/home`);
        break;
      case OrganizationLicenseModuleType.YieldPredictions:
        this.router.navigateByUrl(`yield/organizations/${organizationId}/home`);
        break;
      case OrganizationLicenseModuleType.CO2Optimizer:
        this.router.navigateByUrl(`CO2-optimizer/organizations/${organizationId}/home`);
        break;
      case OrganizationLicenseModuleType.ControlApi:
        this.router.navigateByUrl(`${Constants.ORGANIZATIONS_SETTINGS}/${organizationId}/apiKeys`);
        break;
      case OrganizationLicenseModuleType.MCA:
        this.router.navigateByUrl(`${Constants.ORGANIZATIONS_SETTINGS}/${organizationId}/details`);
        break;
      default: if (this.router.url.indexOf(this.ORGANIZATIONS_PATH) === 0) {
        this.router.navigateByUrl(`/`);
      }
    }
  }

  getFirstAvailableLicensedModule(licenseModules: OrganizationLicenseModule[]) {
    return licenseModules.map((licenseModule => ({
      ...licenseModule,
      priority: this.getLicenseModulePriority(licenseModule.organizationModule)
    }))).sort((a, b) => (a.priority > b.priority) ? 1 : -1)[0];
  }

  getLicenseModulePriority(licenseModuleType: OrganizationLicenseModuleType) {
    switch (licenseModuleType) {
      case OrganizationLicenseModuleType.Manager:
        return 1;
      case OrganizationLicenseModuleType.Grower:
        return 2;
      case OrganizationLicenseModuleType.YieldPredictions:
        return 3;
      case OrganizationLicenseModuleType.CO2Optimizer:
        return 4;
      case OrganizationLicenseModuleType.ControlApi:
        return 5;
    }

    return 9999;
  }

}
