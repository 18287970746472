
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { Observable } from 'rxjs';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { AuditEventApiModel } from '../models/auditEventApiModel';

@Injectable()
export class AuditEventService {
  private readonly configuration: ClientConfiguration;

  constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
    this.configuration = configurationService.configuration;
  }

  getAuditEventsByOrganizationId(organizationId: number): Observable<AuditEventApiModel[]> {
    const url = `${this.configuration.organizationsApiBaseUrl}/AuditEvents/GetAuditEvents/${organizationId}`;
    return this.httpClient.get<AuditEventApiModel[]>(url);
  }
}
